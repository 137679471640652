import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { PolicyStats } from 'Types/types';
import { useDebounce, useTenant } from 'Hooks/Hooks';
import { matchSorter } from 'match-sorter';
import { useEffect, useMemo, useState } from 'react';
import { FilterProps, Row, IdType, FilterValue } from 'react-table';
import { compareNodesByDisplayName, getDisplayName, providerNameLookup } from 'Utilities/utils';
import { Tooltip } from './Tooltip';
import { format } from 'date-fns';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useLazyQuery, useQuery } from '@apollo/client';
import { LIST_ALERT_DEFINITIONS, SEARCH_ATTRIBUTES } from 'Graph/queries';

export function DefaultColumnFilter<T extends object>({ column: { filterValue, setFilter } }: FilterProps<T>) {
    const [value, setValue] = useState<string | undefined>(undefined);
    const debouncedValue = useDebounce<string | undefined>(value, 75);

    useEffect(() => {
        if (filterValue != debouncedValue) {
            setFilter(debouncedValue);
        }
    }, [debouncedValue, filterValue, setFilter]);

    return (
        <div className="relative inline-block">
            <input
                type="text"
                value={value || ''}
                className="input-gray text-xs rounded-none bg-gray-700 pr-5 pl-1.5 py-1 mb-2 relative -left-1.5"
                placeholder={`Search...`}
                onChange={(e) => {
                    setValue(e.target.value || undefined);
                }}
            />
            {filterValue == null ? (
                <MagnifyingGlassIcon className="h-3.5 w-3.5 text-gray-400 absolute top-1.5 right-3" />
            ) : (
                <XMarkIcon
                    className="h-3.5 w-3.5 text-gray-400 absolute top-1.5 right-3 cursor-pointer"
                    onClick={() => {
                        setValue(undefined);
                        setFilter(undefined);
                    }}
                />
            )}
        </div>
    );
}

export function SliderColumnFilter({ column: { filterValue, setFilter } }: FilterProps<object>) {
    const [value, setValue] = useState<number | undefined>(undefined);
    const debouncedValue = useDebounce<number | undefined>(value, 75);

    useEffect(() => {
        if (filterValue != debouncedValue) {
            setFilter(debouncedValue);
        }
    }, [debouncedValue, filterValue, setFilter]);

    return (
        <div className="relative flex h-full">
            <Tooltip label={value ? `${value}%` : 'Filter Off'} placement="right">
                <input
                    className="-left-1 relative top-1 accent-blue-600 bg-gray-700 hover:accent-blue-600 active:accent-blue-600"
                    type="range"
                    min={0}
                    max={100}
                    value={value || 0}
                    onChange={(e) => {
                        setValue(parseInt(e.target.value, 10));
                    }}
                />
            </Tooltip>
        </div>
    );
}

export function GreaterThanColumnFilter({ column: { filterValue, setFilter } }: FilterProps<object>) {
    const [value, setValue] = useState<number | undefined>(undefined);
    const debouncedValue = useDebounce<number | undefined>(value, 75);

    useEffect(() => {
        if (filterValue != debouncedValue) {
            setFilter(debouncedValue);
        }
    }, [debouncedValue, filterValue, setFilter]);

    return (
        <div className="relative flex h-full pb-2">
            <input
                className="-left-1 relative top-0 accent-blue-600 bg-gray-700 hover:accent-blue-600 active:accent-blue-600 text-xs w-32 p-1"
                type="number"
                min={0}
                placeholder="Greater than..."
                value={value || ''}
                onChange={(e) => {
                    setValue(parseInt(e.target.value, 10) || undefined);
                }}
            />
        </div>
    );
}

export function SelectOutcomeFilter({ column: { filterValue, setFilter } }: FilterProps<object>) {
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            className="input-gray text-xs rounded-none bg-gray-700 pr-7 pl-1.5 py-1 mb-2 relative -left-1.5"
        >
            <option value="">All</option>
            <option value="OUTCOME_SUCCESS">Success</option>
            <option value="OUTCOME_CHALLENGE">Challenge</option>
            <option value="OUTCOME_FAILURE">Failure</option>
            <option value="OUTCOME_OTHER">Other</option>
        </select>
    );
}

export const SelectAlertStatusFilter = ({ column: { filterValue, setFilter } }: FilterProps<object>) => {
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            className="input-gray text-xs rounded-none bg-gray-700 pr-7 pl-1.5 py-1 mb-2 relative -left-1.5"
        >
            <option value="">All</option>
            <option value="ALERT_WARNING">Warning</option>
            <option value="ALERT_ALERT">Alert</option>
        </select>
    );
};

export const SelectMultiNodesAsyncFilter = ({ column: { setFilter } }: FilterProps<object>) => {
    const tenantId = useTenant();

    const [searchAttributes, { error: errorSearchingAttributes }] = useLazyQuery(SEARCH_ATTRIBUTES);

    const [selectedNodes, setSelectedNodes] = useState<readonly { value: string; label: string }[]>([]);

    const handleNodeSelectorChange = (options: readonly { value: string; label: string }[]) => {
        console.log(options);
        setSelectedNodes(options);
        setFilter(options.map((option) => option.value));
    };

    const loadOptions = async (inputValue: string) => {
        const now = new Date();
        const nowMinus30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        const resultsActors = await searchAttributes({
            variables: {
                tenantId: tenantId,
                startDate: +nowMinus30Days,
                endDate: +now,
                entity: 'STATS_ENTITY_TYPE_ACTOR',
                sortField: 'displayName',
                search: inputValue,
            },
        });
        const resultsTargets = await searchAttributes({
            variables: {
                tenantId: tenantId,
                startDate: +nowMinus30Days,
                endDate: +now,
                entity: 'STATS_ENTITY_TYPE_TARGET',
                sortField: 'displayName',
                search: inputValue,
            },
        });
        const items = []
            .concat(resultsActors.data?.searchAttributes?.items || [])
            .concat(resultsTargets.data?.searchAttributes?.items || []);

        return items.map((attribute: { id: string; displayName: string; email: string }) => {
            return {
                value: attribute.id,
                label: attribute.displayName || attribute.email || attribute.id,
            };
        });
    };

    return (
        <AsyncSelect
            isMulti
            required
            classNamePrefix="multiselect-sm"
            className="w-full pb-2"
            placeholder="Select nodes(s)"
            menuPlacement="bottom"
            onChange={handleNodeSelectorChange}
            value={selectedNodes}
            noOptionsMessage={({ inputValue }) => {
                if (errorSearchingAttributes) {
                    return 'Error searching for nodes';
                }
                return inputValue ? 'No matching nodes' : 'Start typing to search for nodes';
            }}
            loadOptions={loadOptions}
        />
    );
};

export const SelectNodeAsyncFilter = ({ column: { setFilter } }: FilterProps<object>) => {
    const tenantId = useTenant();

    const [searchAttributes, { error: errorSearchingAttributes }] = useLazyQuery(SEARCH_ATTRIBUTES);

    const [selectedNodes, setSelectedNodes] = useState<{ value: string; label: string }>();

    const handleNodeSelectorChange = (newValue: { value: string; label: string }) => {
        console.log(newValue);
        setSelectedNodes(newValue);
        if (newValue) {
            setFilter(newValue.value);
        } else {
            setFilter(undefined);
        }
    };

    const loadOptions = async (inputValue: string) => {
        const now = new Date();
        const nowMinus30Days = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        const resultsActors = await searchAttributes({
            variables: {
                tenantId: tenantId,
                startDate: +nowMinus30Days,
                endDate: +now,
                entity: 'STATS_ENTITY_TYPE_ACTOR',
                sortField: 'displayName',
                search: inputValue,
            },
        });
        const resultsTargets = await searchAttributes({
            variables: {
                tenantId: tenantId,
                startDate: +nowMinus30Days,
                endDate: +now,
                entity: 'STATS_ENTITY_TYPE_TARGET',
                sortField: 'displayName',
                search: inputValue,
            },
        });
        const items = []
            .concat(resultsActors.data?.searchAttributes?.items || [])
            .concat(resultsTargets.data?.searchAttributes?.items || []);

        return items.map((attribute: { id: string; displayName: string; email: string }) => {
            return {
                value: attribute.id,
                label: attribute.displayName || attribute.email || attribute.id,
            };
        });
    };

    return (
        <AsyncSelect
            isClearable={true}
            escapeClearsValue={true}
            classNamePrefix="multiselect-sm"
            className="w-full pb-2"
            placeholder="Select node"
            menuPlacement="bottom"
            onChange={handleNodeSelectorChange}
            value={selectedNodes}
            noOptionsMessage={({ inputValue }) => {
                if (errorSearchingAttributes) {
                    return 'Error searching for nodes';
                }
                return inputValue ? 'No matching nodes' : 'Start typing to search for a node';
            }}
            loadOptions={loadOptions}
        />
    );
};

export const SelectAlertDefinitionFilter = ({ column: { setFilter } }: FilterProps<object>) => {
    const tenantId = useTenant();

    const { data: dataDefinitions } = useQuery(LIST_ALERT_DEFINITIONS, {
        variables: { tenantId },
        fetchPolicy: 'cache-and-network',
        pollInterval: 300000,
    });

    const [selectedDefinition, setSelectedDefinition] = useState<{ value: string; label: string }>();
    const [options, setOptions] = useState<{ value: string; label: string }[]>([]);

    useEffect(() => {
        if (dataDefinitions?.listAlertDefinitions?.alertDefinitions) {
            const definitions = dataDefinitions?.listAlertDefinitions?.alertDefinitions.map(
                (definition: { id: string; name: string }) => ({
                    value: definition.id,
                    label: definition.name,
                }),
            );

            setOptions(definitions);
        }
    }, [dataDefinitions]);

    const handleSelectorChange = (newValue: { value: string; label: string }) => {
        console.log(newValue);

        setSelectedDefinition(newValue);

        if (newValue) {
            setFilter(newValue.value);
        } else {
            setFilter(undefined);
        }
    };

    return (
        <Select
            isClearable={true}
            escapeClearsValue={true}
            classNamePrefix="multiselect-sm"
            className="w-full pb-2"
            placeholder="Select alert name"
            menuPlacement="bottom"
            onChange={handleSelectorChange}
            value={selectedDefinition}
            options={options}
        />
    );
};

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, id },
    data,
}: FilterProps<object> & { data: any[] }) => {
    // Get unique values for the column
    const options = useMemo(() => {
        const optionsSet = new Set();
        data.forEach((row) => {
            optionsSet.add(row[id]);
        });
        return [...optionsSet.values()].map((value) => ({
            value,
            label: value,
        }));
    }, [data, id]);

    // Handle filter value change
    const handleChange = (
        selectedOptions: {
            value: unknown;
            label: unknown;
        }[],
    ) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setFilter(selectedValues.length ? selectedValues : undefined);
    };

    return (
        <Select
            isMulti
            options={options}
            value={options.filter((option) => filterValue?.includes(option.value))}
            onChange={handleChange}
            classNamePrefix="multiselect-sm"
            className="mb-2 relative -left-1.5 !bg-gray-700 font-normal"
        />
    );
};

export function fuzzyTextFilterFn<Fields extends object>(
    rows: Array<Row<Fields>>,
    columnIds: Array<IdType<Fields>>,
    filterValue: FilterValue,
) {
    return matchSorter<Row<Fields>>(rows, filterValue, {
        keys: [(row: Row<Fields>) => row.values[columnIds[0]]],
    });
}

export function fuzzyNodeDisplayNameFilterFn<Fields extends object>(
    rows: Array<Row<Fields>>,
    columnIds: Array<IdType<Fields>>,
    filterValue: FilterValue,
) {
    return matchSorter<Row<Fields>>(rows, filterValue, {
        keys: [(row: Row<Fields>) => getDisplayName(row.values[columnIds[0]])],
    });
}

export function providerDisplayNameFilterFn<Fields extends object>(
    rows: Array<Row<Fields>>,
    columnIds: Array<IdType<Fields>>,
    filterValue: FilterValue,
) {
    return matchSorter<Row<Fields>>(rows, filterValue, {
        keys: [(row: Row<Fields>) => providerNameLookup(row.values[columnIds[0]])],
    });
}

export function fuzzyDateFilterFn<ActorFields extends object>(
    rows: Array<Row<ActorFields>>,
    columnIds: Array<IdType<ActorFields>>,
    filterValue: FilterValue,
) {
    return matchSorter<Row<ActorFields>>(rows, filterValue, {
        keys: [(row: Row<ActorFields>) => format(new Date(row.values[columnIds[0]]), 'EEE MMM do HH:mm')],
    });
}
export function filterGreaterThan<Fields extends object>(
    rows: Array<Row<Fields>>,
    columnIds: Array<IdType<Fields>>,
    filterValue: FilterValue,
) {
    return rows.filter((row) => {
        const rowValue = row.values[columnIds[0]];
        return rowValue >= filterValue;
    });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: unknown) => !val;
fuzzyNodeDisplayNameFilterFn.autoRemove = (val: unknown) => !val;
fuzzyDateFilterFn.autoRemove = (val: unknown) => !val;
filterGreaterThan.autoRemove = (val: unknown) => {
    return isNaN(val as number) || typeof val !== 'number';
};
providerDisplayNameFilterFn.autoRemove = (val: unknown) => !val;

export const sortTableNodes = <Fields extends object>(rowA: Row<Fields>, rowB: Row<Fields>, columnId: string) => {
    const a = rowA.values[columnId];
    const b = rowB.values[columnId];

    return compareNodesByDisplayName(a, b);
};

export const sortPolicyStats = <Fields extends object>(rowA: Row<Fields>, rowB: Row<Fields>, columnId: string) => {
    const a = rowA.values[columnId] as PolicyStats;
    const b = rowB.values[columnId] as PolicyStats;

    if (a == null && b == null) {
        return 0;
    } else if (a == null) {
        return 1;
    } else if (b == null) {
        return -1;
    }

    if (a.success > b.success) {
        return -1;
    } else if (a.success < b.success) {
        return 1;
    }

    if (a.warning > b.warning) {
        return -1;
    } else if (a.warning < b.warning) {
        return 1;
    }

    if (a.critical > b.critical) {
        return -1;
    } else if (a.critical < b.critical) {
        return 1;
    }

    return 0;
};

export const sortGreaterThan = <Fields extends object>(rowA: Row<Fields>, rowB: Row<Fields>, columnId: string) => {
    const a = rowA.values[columnId] as number;
    const b = rowB.values[columnId] as number;

    if (a > b) {
        return -1;
    } else if (a < b) {
        return 1;
    }

    return 0;
};
