import { useLazyQuery } from '@apollo/client';
import { GET_TENANT } from 'Graph/queries';
import { useLocalStorage, useTenant } from 'Hooks/Hooks';
import { useEffect, useState } from 'react';

enum TenantStatusCode {
    TENANT_STATUS_UNKNOWN = 'TENANT_STATUS_UNKNOWN',
    TENANT_STATUS_PENDING = 'TENANT_STATUS_PENDING',
    TENANT_STATUS_APPROVED = 'TENANT_STATUS_APPROVED',
    TENANT_STATUS_PROVISIONED = 'TENANT_STATUS_PROVISIONED',
    TENANT_STATUS_DISABLED = 'TENANT_STATUS_DISABLED',
    TENANT_STATUS_CREATION_IN_PROGRESS = 'TENANT_STATUS_CREATION_IN_PROGRESS',
    TENANT_STATUS_CREATION_ERROR = 'TENANT_STATUS_CREATION_ERROR',
}

const TenantStatusDescription: Record<TenantStatusCode, string> = {
    TENANT_STATUS_UNKNOWN: 'Tenant is in an unknown state, please contact support',
    TENANT_STATUS_PENDING: 'Tenant is pending approval, please wait, this may take a few minutes',
    TENANT_STATUS_APPROVED: 'Tenant is approved, provisioning will begin shortly',
    TENANT_STATUS_PROVISIONED: 'Tenant is provisioned',
    TENANT_STATUS_DISABLED: 'Tenant is disabled, please contact support',
    TENANT_STATUS_CREATION_IN_PROGRESS: 'Tenant is being provisioned, please wait, this may take a few minutes',
    TENANT_STATUS_CREATION_ERROR: 'Tenant creation failed, please contact support',
};

type TenantStatusProps = {
    children: JSX.Element;
};

export const TenantStatus = ({ children }: TenantStatusProps): JSX.Element => {
    const timeoutInMs = 2000;
    const tenantId = useTenant();
    const { getScopedStorageKey } = useLocalStorage();
    const key = getScopedStorageKey(`tenantStatus`);
    const [status, setStatus] = useState<TenantStatusCode | undefined>(undefined);
    const [timeoutOccurred, setTimeoutOccurred] = useState(false);

    const [getTenant, { data }] = useLazyQuery(GET_TENANT, {
        variables: { tenantId },
        pollInterval: status !== TenantStatusCode.TENANT_STATUS_PROVISIONED ? 10000 : 0,
    });

    // Process the tenant status when we receive that from the API
    // This may happen after the timeout and the UI has been unblocked
    useEffect(() => {
        if (data) {
            const tenant = data.getTenant;
            const status = tenant.status as TenantStatusCode;

            // Update the state and cache the status
            setStatus(status);
            setTimeoutOccurred(false);

            if (key) {
                localStorage.setItem(key, status);
            }

            console.log('Tenant status:', status);
        }
    }, [data, key]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        let isTimedOut = false;

        // In this function we will start the API request and the timer
        // If the API request returns before the timer, we will clear the timer
        // If the timer expires before the API request returns, we will set a flag
        // indicating that the timer expired and the UI should be unblocked.
        //
        // The reason we do this is so that the UI is not blocked indefinitely or for a long time
        // incase there is an issue with the getTenant API call.
        //
        // If the timeout occurs we will either (a) use the cached status or (b) unblock the UI and assume the tenant is provisioned
        // Obviously, the cached tenant status preferred, but they may not be available if the user has never logged in before
        async function fetchTenantStatus() {
            // Start the API request
            getTenant().then(() => {
                // If the API request returns before the timeout, clear the timeout
                if (!isTimedOut) {
                    clearTimeout(timeoutId);
                    console.debug('Tenant status returned before timeout');
                }
                // If the API request returns after the timeout, log a warning
                else {
                    console.warn('Tenant status was returned after timeout');
                }
            });

            // Start the timer
            timeoutId = setTimeout(() => {
                // If we reach this point, the timer has expired before the API request returned
                isTimedOut = true;
                setTimeoutOccurred(true);

                // If we have a cached status, use it
                if (key) {
                    const cachedStatus = localStorage.getItem(key);
                    if (cachedStatus) {
                        setStatus(cachedStatus as TenantStatusCode);
                        console.warn('Timeout occurred while waiting for tenant status, using cached status');
                        return;
                    }
                }

                // If we don't have a cached status, unblock the UI and assume the tenant is provisioned
                console.warn('Timeout occurred while waiting for tenant status, unblocking UI in provisioned mode');
            }, timeoutInMs);
        }

        fetchTenantStatus();

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component is unmounted
        };
    }, [key, getTenant]);

    // If a timeout occurred or we are in a provisioned state, render the app
    if (timeoutOccurred || (status && status === TenantStatusCode.TENANT_STATUS_PROVISIONED)) {
        return <>{children}</>;
    }

    // If we have a status, that is NOT provisioned, show a message to the user
    if (status) {
        return (
            <div className="flex h-screen">
                <div className="m-auto">
                    <p className="text-gray-500 text-xs" data-testid="tenant-status">
                        {TenantStatusDescription[status]}
                    </p>
                </div>
            </div>
        );
    }

    return <Loading />;
};
const Loading = () => (
    <div className="flex h-screen">
        <div className="m-auto">
            <p className="text-gray-500 text-xs" data-testid="auth-spinner">
                Retrieving Tenant Status...
            </p>
        </div>
    </div>
);
