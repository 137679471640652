// React Dependencies
import { createBrowserHistory } from 'history';
import { Route, Router, Switch } from 'react-router-dom';

// Page information and metadata
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Favicon from 'Utilities/Favicon';
import { FloatingPortalRoot } from 'Utilities/FloatingPortalRoot';

// Higher Order Components for services
import UtilitiesAndProviders from 'Utilities/UtilitiesAndProviders';
import AuthenticationProvider from 'Utilities/AuthenticationProvider';
import ProtectedRoute from 'Utilities/ProtectedRoute';

import PublicLaunchDarklyProvider from 'Utilities/PublicLaunchDarklyProvider';
import AuthenticatedLaunchDarklyProvider from 'Utilities/AuthenticatedLaunchDarklyProvider';

import PublicApolloProvider from 'Utilities/PublicApolloProvider';
import AuthorizedApolloProvider from './Utilities/AuthorizedApolloProvider';

// Double Zero public components
import SignUp from './Onboarding/SignUp/SignUp';
import Terms from 'Onboarding/Terms';
import Confirmation from 'Onboarding/SignUp/Confirmation';
import DocAuth from 'Onboarding/Docs/DocAuth';
import NoRoles from 'Onboarding/NoRoles';

// Double Zero app components
import Welcome from './Onboarding/Welcome/WelcomeFlow';
import IdentityMap from './Map/IdentityMap';

import { clearLocalCaches } from 'Utilities/utils';

// History initialization
export const history = createBrowserHistory();

// Clear local caches to reset any stale state
clearLocalCaches();

// Create and serve the Double Zero application
function App(): JSX.Element {
    return (
        <Router history={history}>
            <HelmetProvider>
                <Helmet titleTemplate="%s | Double Zero Security" defaultTitle="Double Zero Security" />
                <Favicon />
                <Switch>
                    <Route path="/public">
                        <PublicLaunchDarklyProvider>
                            <PublicApolloProvider>
                                <Switch>
                                    <Route path="/public/sign-up/confirmation" component={Confirmation} />
                                    <Route path="/public/sign-up" component={SignUp} />
                                    <Route path="/public/terms" component={Terms} />
                                </Switch>
                            </PublicApolloProvider>
                        </PublicLaunchDarklyProvider>
                    </Route>
                    <Route>
                        <AuthenticationProvider>
                            <AuthenticatedLaunchDarklyProvider>
                                <AuthorizedApolloProvider>
                                    <UtilitiesAndProviders>
                                        <Switch>
                                            <ProtectedRoute path="/account-activated" component={NoRoles} />
                                            <ProtectedRoute path="/welcome" component={Welcome} />
                                            <ProtectedRoute path="/identity-map" component={IdentityMap} />
                                            <ProtectedRoute path="/doc/auth" component={DocAuth} />
                                            <ProtectedRoute path="/" component={IdentityMap} />
                                            <FloatingPortalRoot />
                                        </Switch>
                                    </UtilitiesAndProviders>
                                </AuthorizedApolloProvider>
                            </AuthenticatedLaunchDarklyProvider>
                        </AuthenticationProvider>
                    </Route>
                </Switch>
            </HelmetProvider>
        </Router>
    );
}

export default App;
