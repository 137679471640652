import Select from 'react-select';
import { GET_ENTITIES_BY_TYPE_AS_NODES } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_ACTORS_TO_POLICY_PROFILE,
    LIST_POLICY_PROFILES,
    REMOVE_ACTORS_FROM_POLICY_PROFILE,
} from 'Graph/typedQueries';
import { BackendNode } from 'Map/Graph/Data';
import { useMemo } from 'react';
import { ListPolicyProfilesQuery } from 'GeneratedGQL/graphql';

const now = new Date().getTime();

type TrustProfile = ListPolicyProfilesQuery['listPolicyProfiles'][number];

export const TrustProfileMappings = (): JSX.Element => {
    const tenantId = useTenant();

    const {
        loading: loadingActors,
        data: dataActors,
        error: errorActors,
    } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_ACTOR',
            permissionsOnly: true,
            dateInMs: now,
        },
    });

    const {
        loading: loadingTrustProfiles,
        data: dataTrustProfiles,
        error: errorTrustProfiles,
    } = useQuery(LIST_POLICY_PROFILES, { variables: { tenantId: tenantId || '' } });

    const actors = dataActors?.getEntitiesByTypeAsNodes.nodes;
    const trustProfiles = dataTrustProfiles?.listPolicyProfiles;

    const trustProfileByActorId = useMemo(() => {
        const trustProfileByActorId: Record<string, TrustProfile[]> = {};

        trustProfiles?.map((profile) => {
            profile.actorIds?.map((actorId) => {
                if (!trustProfileByActorId[actorId]) {
                    trustProfileByActorId[actorId] = [];
                }
                trustProfileByActorId[actorId].push(profile);
            });
        });

        console.log(trustProfileByActorId);

        return trustProfileByActorId;
    }, [trustProfiles]);

    const loading = loadingActors || loadingTrustProfiles;
    const error = errorActors || errorTrustProfiles;

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <div className="flex items-center space-x-2">
                    <p className="uppercase tracking-wider font-bold text-xs text-gray-400">
                        Trust Profile Mapping - Actor to Profile
                    </p>
                    {loading && <span className="h-4 w-4 loader" />}
                </div>
            </div>
            <div className="w-full px-4 py-4 rounded-md bg-gray-700 mt-2 space-y-1 text-xs">
                {error && <div>There was an issue retrieving the configured Trust mappings</div>}
                {dataActors && !actors && (
                    <div>No Actors found - you must have at least one discovered actor to begin.</div>
                )}
                {trustProfiles && trustProfiles.length === 0 && (
                    <div>No Trust Profiles found - you must configure a profile to begin.</div>
                )}
                {actors &&
                    trustProfiles &&
                    trustProfiles.length > 0 &&
                    actors.map((actor: BackendNode) => (
                        <TrustProfileMappingSelector
                            key={actor.nodeId}
                            actor={actor}
                            availableTrustProfiles={trustProfiles}
                            attachedTrustProfiles={trustProfileByActorId[actor.nodeId]}
                        />
                    ))}
            </div>
        </div>
    );
};

type TrustProfileMappingSelectorProps = {
    actor: BackendNode;
    availableTrustProfiles: TrustProfile[];
    attachedTrustProfiles: TrustProfile[];
};

const TrustProfileMappingSelector = ({
    actor,
    availableTrustProfiles = [],
    attachedTrustProfiles = [],
}: TrustProfileMappingSelectorProps): JSX.Element => {
    const tenantId = useTenant();

    const [addActorsToPolicyProfile] = useMutation(ADD_ACTORS_TO_POLICY_PROFILE);
    const [removeActorsFromPolicyProfile] = useMutation(REMOVE_ACTORS_FROM_POLICY_PROFILE);

    const options = useMemo(() => {
        return availableTrustProfiles.map((profile) => {
            return {
                value: profile.profileId,
                label: profile.displayName,
            };
        });
    }, [availableTrustProfiles]);

    const defaultValue = useMemo(() => {
        return attachedTrustProfiles.map((profile) => {
            return {
                value: profile.profileId,
                label: profile.displayName,
            };
        });
    }, [attachedTrustProfiles]);

    const addActorToProfile = async (actorId: string, profileId?: string) => {
        if (profileId) {
            await addActorsToPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [actorId],
                },
                refetchQueries: ['listPolicyProfiles'],
            });
        }
    };

    const removeActorFromProfile = async (actorId: string, profileId?: string) => {
        if (profileId) {
            await removeActorsFromPolicyProfile({
                variables: {
                    tenantId: tenantId || '',
                    policyProfileId: profileId,
                    actorIds: [actorId],
                },
                refetchQueries: ['listPolicyProfiles'],
            });
        }
    };

    return (
        <div
            className="flex items-center justify-between text-gray-200 text-xs hover:bg-gray-800 rounded-md px-2 py-1"
            key={actor.nodeId}
        >
            <div className="text-nowrap truncate">{actor.props.displayName}</div>
            <div className="w-4/5">
                <Select
                    isMulti={true}
                    classNamePrefix="select-xs"
                    className="w-full"
                    isSearchable={false}
                    name="dataType"
                    options={options}
                    onChange={(incomingValues) => {
                        // Default Value is a list of Trust Profiles that are currently attached to the Actor
                        // incomingValues is a list of Trust Profiles that are currently selected in the dropdown
                        // We need to compare the two to determine which Trust Profiles need to be added or removed

                        // Because the values are objects, we need to compare the value (id) property
                        const previousIds = defaultValue?.map((profile) => {
                            return profile.value;
                        });

                        const incomingIds = incomingValues?.map((profile) => {
                            return profile.value;
                        });

                        const added = incomingIds?.filter((id) => {
                            return !previousIds.includes(id);
                        });

                        const removed = previousIds?.filter((previousId) => {
                            return !incomingIds.includes(previousId);
                        });

                        console.log('added:', added);
                        console.log('removed:', removed);

                        if (added.length > 0) {
                            added.map((profileId) => {
                                addActorToProfile(actor.nodeId, profileId);
                            });
                        }

                        if (removed.length > 0) {
                            removed.map((profileId) => {
                                removeActorFromProfile(actor.nodeId, profileId);
                            });
                        }
                    }}
                    defaultValue={defaultValue}
                />
            </div>
        </div>
    );
};
