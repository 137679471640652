import { PolicyRuleMock } from 'Types/types';
import { FactorCard } from './FactorCard';
import {
    ADD_POLICY_PROFILE_POLICY,
    CREATE_POLICY_PROFILE,
    DELETE_POLICY_PROFILE,
    GET_POLICY_PROFILE,
    LIST_POLICY_DOCUMENTS,
} from 'Graph/typedQueries';
import { useMutation, useQuery } from '@apollo/client';
import { useTenant } from 'Hooks/Hooks';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ListPolicyDocumentsQuery, PolicyWeight } from 'GeneratedGQL/graphql';
import { SelectedNavItem } from '../PolicyModalTypes';
import { Tooltip } from 'Library/Tooltip';
import { classNames } from 'Utilities/utils';

type TrustProfileProps = {
    trustProfileId?: string;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<SelectedNavItem | undefined>>;
};

type TrustProfileFormData = {
    name: string;
    description: string;
};

export const TrustProfile = ({ trustProfileId, setSelectedNavItem }: TrustProfileProps): JSX.Element => {
    const tenantId = useTenant();

    const { data, loading: loadingGetPolicyProfile } = useQuery(GET_POLICY_PROFILE, {
        variables: {
            tenantId: tenantId || '',
            profileId: trustProfileId || '',
        },
        skip: !tenantId || !trustProfileId,
        fetchPolicy: 'network-only',
    });

    // List Policy Documents
    const { data: policyDocumentsData, loading: loadingListPolicyDocuments } = useQuery(LIST_POLICY_DOCUMENTS, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId,
    });

    const policyDocumentsById = useMemo(() => {
        const pd: Record<string, ListPolicyDocumentsQuery['listPolicyDocuments'][number]> = {};

        policyDocumentsData?.listPolicyDocuments.map((policyDocument) => {
            pd[policyDocument?.policyDocumentId || ''] = policyDocument;
        });

        return pd;
    }, [policyDocumentsData]);

    const profile = data?.getPolicyProfile;

    const [createPolicyProfile, { loading: loadingCreatePolicyProfile }] = useMutation(CREATE_POLICY_PROFILE);
    const [deletePolicyProfile, { loading: loadingDeletePolicyProfile }] = useMutation(DELETE_POLICY_PROFILE);

    const loading =
        loadingGetPolicyProfile ||
        loadingListPolicyDocuments ||
        loadingCreatePolicyProfile ||
        loadingDeletePolicyProfile;

    const createNewPolicyProfile = async (data: TrustProfileFormData) => {
        const result = await createPolicyProfile({
            variables: {
                tenantId: tenantId || '',
                policyProfileInput: {
                    displayName: data.name,
                    description: data.description,
                    targets: [],
                    actorIds: [],
                    policies: [],
                    tenantId: tenantId || '',
                },
            },
            refetchQueries: ['listPolicyProfiles', 'getPolicyProfile'],
        });

        setSelectedNavItem({ type: 'profile', id: result.data?.createPolicyProfile?.profileId });
    };

    const onSubmit = (data: TrustProfileFormData) => {
        createNewPolicyProfile(data);
    };

    const {
        register,
        handleSubmit,
        formState: { isDirty },
    } = useForm<TrustProfileFormData>();

    const canDelete = useMemo(() => {
        const profile = data?.getPolicyProfile;
        if (profile) {
            if (profile.actorIds.length === 0 && profile.targets.length === 0) {
                return true;
            }
        }
        return false;
    }, [data?.getPolicyProfile]);

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <div className="flex items-center space-x-2">
                    <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Trust Profiles</p>
                    {loading && <span className="h-4 w-4 loader" />}
                </div>
                <div className="space-x-2 flex">
                    {trustProfileId && (
                        <Tooltip
                            label={
                                canDelete
                                    ? 'Delete the profile. Cannot be undone.'
                                    : 'Profiles can only be deleted when not associated with any Adaptive Trust Policies or Actors'
                            }
                        >
                            <button
                                className={classNames('btn text-xs rounded-md', canDelete ? '' : 'btn-disabled')}
                                type="button"
                                disabled={!canDelete}
                                onClick={async () => {
                                    if (trustProfileId) {
                                        await deletePolicyProfile({
                                            variables: {
                                                tenantId: tenantId || '',
                                                profileId: trustProfileId,
                                            },
                                            refetchQueries: ['listPolicyProfiles', 'getPolicyProfile'],
                                        });
                                        setSelectedNavItem(undefined);
                                    }
                                }}
                            >
                                Delete Profile
                            </button>
                        </Tooltip>
                    )}
                </div>
            </div>
            {trustProfileId ? (
                profile && (
                    <div>
                        <div className="pt-0 pb-4">
                            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                                <div className="ml-4 mt-4">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0"></div>
                                        <div className="ml-1">
                                            <h3 className="text-md leading-6 font-medium text-gray-200">
                                                {profile.displayName}
                                            </h3>
                                            {profile.description && (
                                                <p className="text-xs text-gray-500 mt-1">{profile.description}</p>
                                            )}
                                            <p className="text-xs text-gray-400 mt-1">
                                                {profile.policies.length > 0 ? (
                                                    <span>Contains {profile.policies.length} Trust Factor(s)</span>
                                                ) : (
                                                    <span>Contains no Trust Factors currently</span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            {profile.policies.map((policy) => {
                                const document = policyDocumentsById[policy?.policyId || ''];
                                return (
                                    <FactorCard
                                        profileId={profile?.profileId || ''}
                                        icon={<LockClosedIcon />}
                                        key={document?.policyDocumentId || ''}
                                        name={document?.displayName || ''}
                                        description={document?.description || ''}
                                        author="Double Zero Security"
                                        policyDocumentId={document?.policyDocumentId || ''}
                                        weight={policy?.weight || PolicyWeight.PolicyWeightUnknown}
                                    />
                                );
                            })}
                        </div>

                        <AddAnotherFactor trustProfileId={trustProfileId} setSelectedNavItem={setSelectedNavItem} />
                    </div>
                )
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="space-y-4">
                        <div>
                            <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Trust Profile Name</h4>
                            <input
                                type="text"
                                className="w-full input-gray text-xs rounded-none"
                                {...register('name')}
                                required
                            />
                        </div>
                        <div>
                            <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">
                                Trust Profile Description
                            </h4>
                            <input
                                type="text"
                                className="w-full input-gray text-xs rounded-none"
                                {...register('description')}
                            />
                        </div>
                        <div className="flex space-x-2">
                            <button
                                className={classNames(
                                    'btn text-xs rounded-md',
                                    isDirty ? 'btn-primary' : 'btn-disabled',
                                )}
                                disabled={!isDirty}
                                type="submit"
                            >
                                Create Profile
                            </button>
                            <button className="btn text-xs rounded-md " onClick={() => setSelectedNavItem(undefined)}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
};

type AddAnotherFactorData = {
    policyDocumentId: string;
    weight: PolicyWeight;
};

const AddAnotherFactor = ({ trustProfileId }: TrustProfileProps): JSX.Element => {
    const tenantId = useTenant();

    const [addingFactor, setAddingFactor] = useState(false);

    const [addPolicyToProfile, { loading, error }] = useMutation(ADD_POLICY_PROFILE_POLICY);

    // List Policy Documents (Trust Factors)
    const { data: policyDocumentsData } = useQuery(LIST_POLICY_DOCUMENTS, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId,
    });

    const policyDocuments = policyDocumentsData?.listPolicyDocuments || [];

    const onSubmit = async (data: AddAnotherFactorData) => {
        await addPolicyToProfile({
            variables: {
                tenantId: tenantId || '',
                policyProfileId: trustProfileId || '',
                policies: [{ policyId: data.policyDocumentId, weight: data.weight }],
            },
            refetchQueries: ['listPolicyProfiles', 'getPolicyProfile'],
            awaitRefetchQueries: true,
        });

        setAddingFactor(false);
        reset();
    };

    const {
        reset,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<AddAnotherFactorData>({
        defaultValues: { weight: PolicyWeight.PolicyWeightMedium },
    });

    return addingFactor ? (
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-2">
            <div className="p-4 rounded-md bg-gray-700 mt-4 text-gray-500 hover:text-gray-200 text-xs cursor-pointer flex space-x-4 items-center">
                <select
                    className="input-gray text-xs"
                    {...register('policyDocumentId', { required: 'Please select a Trust Factor' })}
                    required
                    disabled={loading}
                >
                    <option value="">Select a Trust Factor</option>
                    <hr />
                    {policyDocuments.map((policyDocument) => (
                        <option key={policyDocument?.policyDocumentId} value={policyDocument?.policyDocumentId}>
                            {policyDocument?.displayName}
                        </option>
                    ))}
                </select>
                {errors.policyDocumentId && (
                    <p className="text-xs text-red-600 py-2">{errors.policyDocumentId.message}</p>
                )}
                <select
                    className="input-gray text-xs"
                    {...register('weight', { required: 'Please select a Weight' })}
                    disabled={loading}
                    required
                >
                    <option value="">Select a Weight</option>
                    <hr />
                    <option value={PolicyWeight.PolicyWeightDisabled}>Disabled</option>
                    <hr />
                    <option value={PolicyWeight.PolicyWeightLow}>Low</option>
                    <option value={PolicyWeight.PolicyWeightMedium}>Medium</option>
                    <option value={PolicyWeight.PolicyWeightHigh}>High</option>
                    <option value={PolicyWeight.PolicyWeightCritical}>Critical</option>
                </select>
                {errors.weight && <p className="text-xs text-red-600 py-2">{errors.weight.message}</p>}
                {loading && (
                    <div className="h-4 w-4">
                        <span className="h-4 w-4 loader" />
                    </div>
                )}
                <button
                    className={classNames(
                        'btn btn-primary text-xs rounded-md w-52 hover:bg-blue-800/90',
                        loading ? 'btn-disabled' : '',
                    )}
                    type="submit"
                    disabled={loading}
                >
                    Add Factor
                </button>
                <button
                    className={classNames('btn bg-gray-600 text-xs rounded-md w-32', loading ? 'btn-disabled' : '')}
                    disabled={loading}
                    type="button"
                    onClick={() => setAddingFactor(false)}
                >
                    Cancel
                </button>
            </div>
            {error && <p className="text-red-600 text-xs p-2">Error adding Trust Factor</p>}
        </form>
    ) : (
        <div
            className="p-4 rounded-md bg-gray-700 mt-4 text-gray-500 hover:text-gray-200 text-xs cursor-pointer font-semibold"
            onClick={() => setAddingFactor(true)}
        >
            <p className="">Add Another Factor +</p>
        </div>
    );
};

export interface PolicyRuleCategory {
    name: string;
    defaultOpen: boolean;
    children: Array<PolicyRuleMock>;
}
