import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTenant } from 'Hooks/Hooks';
import { useClipboard } from 'use-clipboard-copy';

import { ApiKey } from 'Types/types';
import { DELETE_API_KEY, LIST_API_KEYS, CREATE_API_KEY, GET_USER } from 'Graph/queries';

import { Panel } from './Panel';

import { KeyIcon } from '@heroicons/react/24/outline';
import { CheckIcon, DocumentArrowDownIcon, PlusIcon } from '@heroicons/react/24/solid';
import { SubmitHandler, useForm } from 'react-hook-form';
import { classNames } from 'Utilities/utils';
import { useUserPermissions } from 'Utilities/UserPermissions';

interface DeleteKeyProps {
    tenantId: string;
    apiKeyId: string;
    name: string;
}

type FormProps = {
    name: string;
};

const ApiKeys = (): JSX.Element => {
    const { userCan } = useUserPermissions();

    const canCreateKeys = userCan('create', '/api');
    const canListKeys = userCan('list', '/api');

    return (
        <Panel title="API Keys" subtitle="Connect to the Double Zero API">
            {canCreateKeys && <CreateKey />}
            {canListKeys && <ListKeys />}
            {!canListKeys && (
                <p className="text-xs">
                    You do not have permissions to access API keys. If required, please contact your administrator.
                </p>
            )}
        </Panel>
    );
};

const CreateKey = (): JSX.Element => {
    const tenantId = useTenant();

    const [showForm, setShowForm] = useState(false);

    const [createKey, { loading, data, error }] = useMutation(CREATE_API_KEY, {
        refetchQueries: [{ query: LIST_API_KEYS, variables: { tenantId } }],
    });

    const { register, handleSubmit } = useForm<FormProps>({ mode: 'onChange' });

    const onSubmit: SubmitHandler<FormProps> = async (formData) => {
        console.log('Creating API key:', formData);

        createKey({
            variables: {
                tenantId,
                apiKeyName: formData.name,
            },
        });

        setShowForm(false);
    };

    let content = <></>;

    if (loading) {
        content = (
            <h4 className="text-xs text-gray-300 flex items-center">
                <div className="loader h-4 w-4 mr-2" />
                Creating API Key...
            </h4>
        );
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not create API key. Please retry later.</h4>;
    } else {
        content = (
            <>
                <button
                    onClick={() => setShowForm(true)}
                    disabled={showForm ? true : false}
                    className="btn btn-primary disabled:bg-gray-600 disabled:opacity-50 text-xs absolute top-6 right-6"
                >
                    <PlusIcon className="h-4 w-4 mr-2" />
                    New API Key
                </button>
                {showForm && (
                    <div className="col-span-1 bg-gray-700 rounded-lg shadow mb-4">
                        <form className="w-full flex justify-between p-4 space-x-4" onSubmit={handleSubmit(onSubmit)}>
                            <KeyIcon className="w-8 h-8 text-gray-400" />

                            <div className="flex-1 truncate">
                                <div className="w-full relative font-medium text-xs">
                                    <input
                                        {...register('name')}
                                        type="text"
                                        name="name"
                                        className="input-gray rounded-none text-xs flex-1"
                                        placeholder="Choose a name for this API key"
                                    />
                                </div>
                            </div>
                            <div className="flex items-start space-x-2">
                                <button type="submit" className="btn btn-primary hover:bg-gray-800 text-xs">
                                    <span>Create</span>
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowForm(false)}
                                    className="btn bg-gray-600 text-xs"
                                >
                                    <span>Cancel</span>
                                </button>
                            </div>
                        </form>
                    </div>
                )}
                {data && (
                    <ul>
                        <ListKeyItem
                            key={data.createApiKey.apiKeyId}
                            name={data.createApiKey.name}
                            createdByUserId={data.createApiKey.createdByUserId}
                            createdAt={data.createApiKey.createdAt}
                            apiKeyId={data.createApiKey.apiKeyId}
                            tenantId={data.createApiKey.tenantId}
                            clientKey={data.createApiKey.clientKey}
                            clientSecret={data.createApiKey.clientSecret}
                            updatedAt={data.createApiKey.updatedAt}
                        />
                    </ul>
                )}
            </>
        );
    }
    return content;
};

const ListKeys = (): JSX.Element => {
    const tenantId = useTenant();

    const { loading, error, data } = useQuery(LIST_API_KEYS, { variables: { tenantId } });

    let content = <></>;

    if (loading) {
        content = (
            <h4 className="text-xs text-gray-300 flex items-center">
                <div className="loader h-4 w-4 mr-2" />
                Loading API keys...
            </h4>
        );
    } else if (error) {
        content = <h4 className="text-xs text-red-500">Could not load API keys. Please retry later.</h4>;
    } else {
        content = (
            <>
                <ul role="list" className="space-y-4">
                    {/* List Keys */}
                    {data &&
                        data.listApiKeys.map((key: ApiKey) => (
                            <ListKeyItem
                                key={key.apiKeyId}
                                name={key.name}
                                createdByUserId={key.createdByUserId}
                                createdAt={key.createdAt}
                                apiKeyId={key.apiKeyId}
                                tenantId={key.tenantId}
                                clientKey={key.apiKeyId}
                                updatedAt={key.updatedAt}
                            />
                        ))}
                </ul>
            </>
        );
    }
    return content;
};

const ListKeyItem = (key: ApiKey): JSX.Element => {
    const { data } = useQuery(GET_USER, {
        variables: { tenantId: key.tenantId, userId: key.createdByUserId },
    });

    const [expanded, setExpanded] = useState(false);
    const clipboard = useClipboard({
        copiedTimeout: 800,
    });

    let content = <></>;

    if (data) {
        content = (
            <li className="col-span-1 bg-gray-700 rounded-lg shadow">
                {key.clientSecret && (
                    <div className="bg-green-600 text-white rounded-t-lg p-2.5 flex items-center text-xs">
                        <CheckIcon className="h-5 w-5 mr-2" />
                        <span>
                            API key created successfully.
                            <span className="font-bold ml-1">
                                Make sure to copy your Client API Secret below and store it securely.
                            </span>
                        </span>
                    </div>
                )}
                <div className="w-full flex justify-between p-4 space-x-4">
                    <KeyIcon className="w-8 h-8 text-green-400" />

                    <div className="flex-1 self-center truncate">
                        <div className="flex items-center justify-between space-x-3">
                            <div className="flex items-center space-x-2 flex-1">
                                <h3 className="text-white text-sm font-medium truncate">{key.name}</h3>
                                <p className="text-xs text-gray-400 mt-[1px]">
                                    by {data.getUser.name} ({data.getUser.email})
                                </p>
                            </div>
                        </div>
                        {/* Add datetime in the future
                        <p className="mt-1 text-gray-400 text-xs truncate">Created {key.createdAt}</p>
                        <p className="mt-1 text-green-500 text-xs truncate">Last used {key.updatedAt}</p> */}
                    </div>
                    <div className="flex items-start space-x-2">
                        {key.clientSecret ? (
                            <a
                                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                    JSON.stringify(key, ['name', 'clientKey', 'clientSecret'], '\t'),
                                )}`}
                                download={`doublezero-api-key-${key.name.replaceAll(' ', '-')}.json`}
                                className="btn btn-primary hover:bg-gray-600/50 text-xs"
                            >
                                <DocumentArrowDownIcon className="h-4 w-4 mr-2" />
                                Download Key
                            </a>
                        ) : expanded ? (
                            <>
                                <DeleteKey tenantId={key.tenantId} apiKeyId={key.apiKeyId} name={key.name} />
                                <button
                                    type="button"
                                    onClick={() => setExpanded(false)}
                                    className="btn bg-gray-600 hover:bg-gray-600/50 text-xs"
                                >
                                    Close
                                </button>
                            </>
                        ) : (
                            <button
                                type="button"
                                onClick={() => setExpanded(true)}
                                className="btn bg-gray-600 hover:bg-gray-600/50 text-xs"
                            >
                                Details
                            </button>
                        )}
                    </div>
                </div>
                {expanded && (
                    <div className="p-4 pt-0 ml-12 space-y-2">
                        <div className="w-full relative font-medium text-xs">
                            Client API Key
                            <div className="flex flex-start justify-start">
                                <input
                                    type="text"
                                    readOnly
                                    className="input-gray rounded-none text-xs flex-1 mt-2"
                                    value={key.clientKey}
                                />
                                <button
                                    type="button"
                                    data-text="Copied!"
                                    onClick={() => clipboard.copy(key.clientKey)}
                                    className={classNames(
                                        'btn bg-gray-600 hover:bg-gray-600/50 h-8.5 mt-2 ml-2',
                                        clipboard.copied ? 'tooltip-wrapper' : '',
                                    )}
                                >
                                    Copy
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {key.clientSecret && (
                    <div className="p-4 pt-0 ml-12 space-y-2">
                        <div className="w-full relative font-medium text-xs space-y-2">
                            <label className="block">
                                Client API Key
                                <div className="flex flex-start justify-start">
                                    <input
                                        type="text"
                                        readOnly
                                        className="input-gray rounded-none text-xs flex-1 mt-2"
                                        value={key.clientKey}
                                    />
                                    <button
                                        type="button"
                                        data-text="Copied!"
                                        onClick={() => clipboard.copy(key.clientKey)}
                                        className={classNames(
                                            'btn bg-gray-600 hover:bg-gray-600/50 h-8.5 mt-2 ml-2',
                                            clipboard.copied ? 'tooltip-wrapper' : '',
                                        )}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </label>
                            <label className="block">
                                Client API Secret
                                <div className="flex flex-start justify-start">
                                    <input
                                        type="text"
                                        readOnly
                                        className="input-gray rounded-none text-xs flex-1 mt-2"
                                        value={key.clientSecret}
                                    />
                                    <button
                                        type="button"
                                        data-text="Copied!"
                                        onClick={() => clipboard.copy(key.clientSecret)}
                                        className={classNames(
                                            'btn bg-gray-600 hover:bg-gray-600/50 h-8.5 mt-2 ml-2',
                                            clipboard.copied ? 'tooltip-wrapper' : '',
                                        )}
                                    >
                                        Copy
                                    </button>
                                </div>
                            </label>
                        </div>
                    </div>
                )}
            </li>
        );
    }
    return content;
};

const DeleteKey = ({ tenantId, apiKeyId }: DeleteKeyProps): JSX.Element => {
    const [deleteApiKey] = useMutation(DELETE_API_KEY, {
        refetchQueries: [{ query: LIST_API_KEYS, variables: { tenantId } }],
    });

    const [disableButton, setDisableButton] = useState(false);

    const deleteThisKey = async (tenantId: string, apiKeyId: string) => {
        console.log('Deleting key:', apiKeyId);

        setDisableButton(true);
        try {
            await deleteApiKey({ variables: { tenantId, apiKeyId } });
        } catch (e) {}
    };

    return (
        <button
            type="button"
            onClick={() => deleteThisKey(tenantId, apiKeyId)}
            className="btn bg-red-600 hover:bg-gray-800 text-xs"
            style={disableButton ? { opacity: '0.5', pointerEvents: 'none' } : {}}
            disabled={disableButton}
        >
            <span className="text-xs">{disableButton ? 'Deleting...' : 'Delete'}</span>
        </button>
    );
};

export default ApiKeys;
