import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChatBubbleLeftRightIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { useEffect, useRef, useState } from 'react';

const KEY = '';

type ResultsChatProps = {
    onClose: () => void;
};

export const ResultsChat = ({ onClose }: ResultsChatProps): JSX.Element => {
    const [messages, setMessages] = useState([
        {
            content:
                'You are a helpful assistant designed to help a user investigate a graph data structure for security purposes',
            role: 'system',
        },
        {
            content:
                'Hi! My name is Sean and I can assist you with your tasks in Double Zero. Some examples of things you can ask me are:',
            role: 'assistant',
        },
        {
            content: 'Can you tell me how many Actors are in the graph?',
            role: 'user',
        },
        {
            content: 'There are 3 Actors in the graph.',
            role: 'assistant',
        },
        {
            content: 'Which Actor has the most failures and to which targets?',
            role: 'user',
        },
        {
            content: 'Rob Tappenden had the most failures, with 2 failures to Microsoft Graph and 1 to Office 365',
            role: 'assistant',
        },
        {
            content: 'Which device was he using?',
            role: 'user',
        },
        {
            content: 'He was using a Mac OS device',
            role: 'assistant',
        },
    ]);

    const [newMessage, setNewMessage] = useState('');

    const handleSendMessage = async () => {
        if (newMessage.trim() !== '') {
            const updatedMessages = [...messages, { content: newMessage, role: 'user' }];
            setMessages(updatedMessages);
            setNewMessage('');

            try {
                const response = await fetch('https://api.openai.com/v1/chat/completions', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${KEY}`,
                    },
                    body: JSON.stringify({
                        model: 'gpt-3.5-turbo',
                        messages: messages,
                        max_tokens: 150,
                    }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }

                const data = await response.json();
                setMessages([...updatedMessages, { content: data.choices[0].text.trim(), role: 'assistant' }]);
            } catch (error) {
                console.error('There was an error!', error);
            }
        }
    };

    const messagesEndRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    return (
        <div
            id="CommandPalette"
            className="mx-auto w-full flex-shrink-0 flex-grow-0 transform rounded-lg bg-gray-800 shadow-2xl transition-all pointer-events-auto"
        >
            <div className="relative w-full text-gray-200">
                <div className="flex">
                    <ChatBubbleLeftRightIcon
                        className="pointer-events-none absolute top-2.5 left-4 h-5 w-5 text-gray-500"
                        aria-hidden="true"
                    />
                    <input
                        type="search"
                        name="search"
                        autoComplete="off"
                        data-test="command-palette-input"
                        placeholder={'Type your message...'}
                        className={classNames(
                            'h-10 w-full border-0 bg-transparent pl-11 pr-0 text-white placeholder-gray-500 focus:ring-0 text-sm',
                        )}
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        autoFocus={true}
                    />
                    <button
                        className={classNames(
                            'ml-3 p-2  hover:text-blue-600',
                            newMessage.length > 0 ? 'text-gray-200' : 'text-gray-500',
                        )}
                        onClick={handleSendMessage}
                    >
                        <PaperAirplaneIcon className="h-5 w-5" />
                    </button>
                </div>
                <div className="px-3 pb-3 overflow-y-auto max-h-[600px] space-y-3 text-xs">
                    <div ref={messagesEndRef} />
                    {messages
                        .slice(1)
                        .reverse()
                        .map((message, index) => (
                            <div
                                key={index}
                                className={`rounded-lg p-2 max-w-sm ${
                                    message.role === 'user' ? 'bg-blue-800 ml-auto' : 'bg-gray-700'
                                }`}
                            >
                                {message.content}
                            </div>
                        ))}
                </div>
                <button
                    onClick={onClose}
                    className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -right-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0 z -50"
                >
                    <XMarkIcon className="h-3.5 w-3.5 text-gray-200" />
                </button>
            </div>
        </div>
    );
};
