import logo from 'assets/logo-192x192.png';
import ExternalPage from './ExternalPage';

const Terms = (): JSX.Element => {
    return (
        <ExternalPage>
            <img className="h-12 w-auto" src={logo} alt="Double Zero Icon" />

            <h2 className="text-3xl font-extrabold text-gray-200">Double Zero Security</h2>
            <p className="text-sm text-gray-400 mt-2">Terms of Service</p>

            <div className="mt-6 space-y-8">
                <p className="text-xs text-gray-300 ">
                    DOUBLE ZERO SECURITY, INC.
                    <br />
                    TERMS OF SERVICE
                    <br />
                    <br />
                    YOU AGREE TO ACCESS AND USE THE SERVICES (AS DEFINED BELOW) MADE AVAILABLE TO YOU BY DOUBLE ZERO
                    SECURITY, INC. (“DOUBLE ZERO SECURITY”) SOLELY IN ACCORDANCE WITH THE TERMS AND CONDITIONS OF THESE
                    TERMS OF SERVICE (THIS “AGREEMENT”). YOUR ACCESS AND USE OF THE SERVICES IS EXPRESSLY CONDITIONED ON
                    YOUR ACCEPTANCE OF THIS AGREEMENT BY CLICKING TO ACKNOWLEDGE AND AGREE TO THIS AGREEMENT. IF YOU DO
                    NOT AGREE TO THE TERMS AND CONDITIONS OF THIS AGREEMENT YOU MAY NOT ACCESS OR USE THE SERVICES. IF
                    YOU ARE USING THE SERVICES ON BEHALF OF A COMPANY, ENTITY, OR ORGANIZATION (COLLECTIVELY, THE
                    “CUSTOMER”) THEN YOU REPRESENT AND WARRANT THAT YOU: (I) ARE AN AUTHORIZED REPRESENTATIVE OF THE
                    CUSTOMER WITH THE AUTHORITY TO BIND SUCH CUSTOMER TO THE TERMS AND CONDITIONS OF THIS AGREEMENT;
                    (II) HAVE READ THIS AGREEMENT; (III) UNDERSTAND THIS AGREEMENT, AND (IV) AGREE TO THIS AGREEMENT ON
                    BEHALF OF SUCH CUSTOMER AND THE CUSTOMER IS BOUND BY AND A PARTY TO THIS AGREEMENT. DOUBLE ZERO
                    SECURITY AND CUSTOMER MAY BE REFERRED TO HEREIN COLLECTIVELY AS THE &quot;PARTIES&quot; OR
                    INDIVIDUALLY AS A &quot;PARTY.&quot;
                    <br />
                    <br />
                    1. Definitions.
                    <br />
                    <br />
                    1.1 &quot;Double Zero Security IP&quot; means the Services, the Documentation, and any and all
                    intellectual property provided to Customer or any Authorized User in connection with the foregoing.
                    For the avoidance of doubt, Double Zero Security IP includes Usage Data and any information, data,
                    or other content derived from Double Zero Security&apos;s monitoring of Customer&apos;s access to or
                    use of the Services, but does not include Customer Data.
                    <br />
                    <br />
                    1.2 &quot;Authorized User&quot; means Customer&apos;s employees, consultants, contractors, and
                    agents: (i) who are authorized by Customer to access and use the Services under the rights granted
                    to Customer pursuant to this Agreement; and (ii) for whom access to the Services has been purchased
                    hereunder.
                    <br />
                    <br />
                    1.3 &quot;Customer Data&quot; means, other than Usage Data, information, data, and other content, in
                    any form or medium, that is submitted, posted, or otherwise transmitted by or on behalf of Customer
                    or an Authorized User through the Services.
                    <br />
                    <br />
                    1.4 &quot;Documentation&quot; means Double Zero Security&apos;s user manuals, handbooks, and guides
                    relating to the Services provided by Double Zero Security to Customer either electronically or in
                    hard copy form.
                    <br />
                    <br />
                    1.5 &quot;Services&quot; means the software-as-a-service offering described in an order form as
                    updated from time-to-time by Double Zero Security in its sole discretion.
                    <br />
                    <br />
                    1.6 &quot;Third-Party Products&quot; means any third-party products provided with or incorporated
                    into the Services.
                    <br />
                    <br />
                    1.7 &quot;Usage Data&quot; means data and information related to Customer&apos;s use of the Services
                    that is used by Double Zero Security in an aggregate and anonymized manner, including to compile
                    statistical and performance information related to the provision and operation of the Services.
                    <br />
                    <br />
                    2. Access and Use.
                    <br />
                    <br />
                    2.1 Provision of Access. Subject to and conditioned on Customer&apos;s payment of Fees and
                    compliance with all the terms and conditions of this Agreement, Double Zero Security hereby grants
                    Customer a non-exclusive, non-transferable (except in compliance with Section 12.7) right to access
                    and use the Services during the Term, solely for use by Authorized Users in accordance with the
                    terms and conditions herein. Such use is limited to Customer&apos;s internal use. Double Zero
                    Security shall provide to Customer the necessary passwords and network links or connections to allow
                    Customer to access the Services.
                    <br />
                    <br />
                    2.2 Documentation License. Subject to the terms and conditions of this Agreement, Double Zero
                    Security hereby grants to Customer a non-exclusive, non-sublicenseable, non-transferable (except in
                    compliance with Section 12.7) license to use the Documentation during the Term solely for
                    Customer&apos;s internal business purposes in connection with its use of the Services.
                    <br />
                    <br />
                    2.3 Use Restrictions. Customer shall not use the Services for any purposes beyond the scope of the
                    access granted in this Agreement. Without limiting the generality of the foregoing, Customer shall
                    not at any time, directly or indirectly, and shall not permit any Authorized Users to: (i) copy,
                    modify, or create derivative works of the Services or Documentation, in whole or in part; (ii) rent,
                    lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make
                    available the Services or Documentation; (iii) reverse engineer, disassemble, decompile, decode,
                    adapt, or otherwise attempt to derive or gain access to any software component of the Services, in
                    whole or in part; (iv) remove any proprietary notices from the Services or Documentation; (v) use
                    the Services or Documentation in any manner or for any purpose that infringes, misappropriates, or
                    otherwise violates any intellectual property right or other right of any person, or that violates
                    any applicable law; (vi) publicly disclose the results of any benchmarking of the Services against
                    any competing products or services; or (vii) use the Services for the purpose of developing a
                    product or service that may be competitive with the Services.
                    <br />
                    <br />
                    2.4 Reservation of Rights. Double Zero Security reserves all rights not expressly granted to
                    Customer in this Agreement. Except for the limited rights and licenses expressly granted under this
                    Agreement, nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to
                    Customer or any third party any intellectual property rights or other right, title, or interest in
                    or to the Double Zero Security IP.
                    <br />
                    <br />
                    2.5 Suspension. Notwithstanding anything to the contrary in this Agreement, Double Zero Security may
                    temporarily suspend Customer&apos;s and any Authorized User&apos;s access to any portion or all of
                    the Services if: (i) Double Zero Security reasonably determines that (a) there is a threat or attack
                    on any of the Double Zero Security IP; (b) Customer&apos;s or any Authorized User&apos;s use of the
                    Double Zero Security IP disrupts or poses a security risk to the Double Zero Security IP or to any
                    other customer or vendor of Double Zero Security; (c) Customer, or any Authorized User, is using the
                    Double Zero Security IP for fraudulent or illegal activities; (d) subject to applicable law,
                    Customer has ceased to continue its business in the ordinary course, made an assignment for the
                    benefit of creditors or similar disposition of its assets, or become the subject of any bankruptcy,
                    reorganization, liquidation, dissolution, or similar proceeding; or (e) Double Zero Security&apos;s
                    provision of the Services to Customer or any Authorized User is prohibited by applicable law; or
                    (ii) any vendor of Double Zero Security has suspended or terminated Double Zero Security&apos;s
                    access to or use of any third-party services or products required to enable Customer to access the
                    Services (any such suspension described in subclause (i) or (ii), a “Service Suspension”). Double
                    Zero Security shall use commercially reasonable efforts to provide written notice of any Service
                    Suspension to Customer and to provide updates regarding resumption of access to the Services
                    following any Service Suspension. Double Zero Security shall use commercially reasonable efforts to
                    resume providing access to the Services as soon as reasonably possible after the event giving rise
                    to the Service Suspension is cured. Double Zero Security will have no liability for any damage,
                    liabilities, losses (including any loss of data or profits), or any other consequences that Customer
                    or any Authorized User may incur as a result of a Service Suspension.
                    <br />
                    <br />
                    2.6 Usage Data. Notwithstanding anything to the contrary in this Agreement, Double Zero Security may
                    monitor Customer&apos;s use of the Services and collect and compile Usage Data. As between Double
                    Zero Security and Customer, all right, title, and interest in Usage Data, and all intellectual
                    property rights therein, belong to and are retained solely by Double Zero Security. Customer
                    acknowledges that Double Zero Security may compile Usage Data based on Customer Data input into the
                    Services. Customer agrees that Double Zero Security may: (i) make Usage Data publicly available in
                    compliance with applicable law; and (ii) use Usage Data to the extent and in the manner permitted
                    under applicable law; provided that such Usage Data do not identify Customer or Customer&apos;s
                    Confidential Information.
                    <br />
                    <br />
                    3. Customer Responsibilities. Customer is responsible and liable for all uses of the Services and
                    Documentation resulting from access provided by Customer, directly or indirectly, whether such
                    access or use is permitted by or in violation of this Agreement. Without limiting the generality of
                    the foregoing, Customer is responsible for all acts and omissions of Authorized Users, and any act
                    or omission by an Authorized User that would constitute a breach of this Agreement if taken by
                    Customer will be deemed a breach of this Agreement by Customer. Customer shall use reasonable
                    efforts to make all Authorized Users aware of this Agreement&apos;s provisions as applicable to such
                    Authorized User&apos;s use of the Services and shall cause Authorized Users to comply with such
                    provisions. Double Zero Security may from time-to-time make Third-Party Products available to
                    Customer. For purposes of this Agreement, such Third-Party Products are subject to their own terms
                    and conditions.
                    <br />
                    <br />
                    4. Support. Subject to payment of the corresponding fees, Double Zero Security will provide its
                    standard support and maintenance services and associated service level agreements specified in the
                    applicable order form subject to the terms and conditions located{' '}
                    <a href="https://fabric.doublezero.io/terms">fabric.doublezero.io/terms</a> as updated from
                    time-to-time by Double Zero Security in its sole discretion.
                    <br />
                    <br />
                    5. Fees and Audit.
                    <br />
                    <br />
                    5.1 Fees. Customer shall pay Double Zero Security the fees (&quot;Fees&quot;) as set forth in the
                    applicable order form without offset or deduction. All Fees are non-refundable and non-cancelable.
                    All Fees and other amounts payable by Customer under this Agreement are exclusive of taxes and
                    similar assessments. Customer is responsible for all sales, use, and excise taxes, and any other
                    similar taxes, duties, and charges of any kind imposed by any federal, state, or local governmental
                    or regulatory authority on any amounts payable by Customer hereunder, other than any taxes imposed
                    on Double Zero Security&apos;s income.
                    <br />
                    <br />
                    5.2 Auditing Rights and Required Records. Customer agrees to maintain complete and accurate records
                    in accordance with generally accepted accounting principles during the Term and for a period of two
                    (2) years after the termination or expiration of this Agreement with respect to matters necessary
                    for accurately determining amounts due hereunder. Double Zero Security may, at its own expense, on
                    reasonable prior notice, periodically inspect and audit Customer&apos;s records to evaluate
                    Customer’s compliance with this Agreement, including without limitation Customer’s payment of all
                    amounts due hereunder. If such inspection and audit reveal that Customer has underpaid Double Zero
                    Security with respect to any amounts due and payable during the Term, Customer shall promptly pay
                    the amounts necessary to rectify such underpayment, together with interest, if applicable. Customer
                    shall pay for the costs of the audit if the audit determines that Customer&apos;s underpayment
                    equals or exceeds five percent (5%) for any quarter or if the audit discovers a material breach of
                    this Agreement. Such inspection and auditing rights will extend throughout the Term of this
                    Agreement and for a period of two (2) years after the termination or expiration of this Agreement.
                    <br />
                    <br />
                    6. Confidential Information. From time to time during the Term, either Party may disclose or make
                    available to the other Party information about its business affairs, products, confidential
                    intellectual property, trade secrets, third-party confidential information, and other sensitive or
                    proprietary information, whether orally or in written, electronic, or other form or media/in written
                    or electronic form or media that is marked or otherwise designated as “confidential”, “proprietary”,
                    or something similar at the time of disclosure or within a reasonable period of time thereafter
                    and/or would be considered confidential or proprietary by a reasonable person given the nature of
                    the information or the circumstances of its disclosure (collectively, &quot;Confidential
                    Information&quot;). Confidential Information does not include information that, at the time of
                    disclosure is: (i) in the public domain; (ii) known to the receiving Party at the time of
                    disclosure; (iii) rightfully obtained by the receiving Party on a non-confidential basis from a
                    third party; or (iv) independently developed by the receiving Party. The receiving Party shall not
                    disclose the disclosing Party&apos;s Confidential Information to any person or entity, except to the
                    receiving Party&apos;s employees who have a need to know the Confidential Information for the
                    receiving Party to exercise its rights or perform its obligations hereunder. Notwithstanding the
                    foregoing, each Party may disclose Confidential Information to the limited extent required (a) in
                    order to comply with the order of a court or other governmental body, or as otherwise necessary to
                    comply with applicable law, provided that the Party making the disclosure pursuant to the order
                    shall first have given written notice to the other Party and made a reasonable effort to obtain a
                    protective order; or (b) to establish a Party&apos;s rights under this Agreement, including to make
                    required court filings. On the expiration or termination of the Agreement, the receiving Party shall
                    promptly return to the disclosing Party all copies, whether in written, electronic, or other form or
                    media, of the disclosing Party&apos;s Confidential Information, or destroy all such copies and
                    certify in writing to the disclosing Party that such Confidential Information has been destroyed.
                    Each Party&apos;s obligations of non-disclosure with regard to Confidential Information are
                    effective as of the Effective Date and will expire five (5) years from the date first disclosed to
                    the receiving Party; provided, however, with respect to any Confidential Information that
                    constitutes a trade secret (as determined under applicable law), such obligations of non-disclosure
                    will survive the termination or expiration of this Agreement for as long as such Confidential
                    Information remains subject to trade secret protection under applicable law.
                    <br />
                    <br />
                    7. Intellectual Property Ownership; Feedback.
                    <br />
                    <br />
                    7.1 Double Zero Security IP. Customer acknowledges that, as between Customer and Double Zero
                    Security, Double Zero Security owns all right, title, and interest, including all intellectual
                    property rights, in and to the Double Zero Security IP and, with respect to Third-Party Products,
                    the applicable third-party providers own all right, title, and interest, including all intellectual
                    property rights, in and to the Third-Party Products.
                    <br />
                    <br />
                    7.2 Customer Data. Double Zero Security acknowledges that, as between Double Zero Security and
                    Customer, Customer owns all right, title, and interest, including all intellectual property rights,
                    in and to the Customer Data. Customer hereby grants, and hereby represents, warrants, and covenants
                    that it has all rights necessary to grant, to Double Zero Security a non-exclusive, royalty-free,
                    worldwide license to reproduce, distribute, and otherwise use and display the Customer Data and
                    perform all acts with respect to the Customer Data as may be necessary for Double Zero Security to
                    provide the Services to Customer, and a non-exclusive, perpetual, irrevocable, royalty-free,
                    worldwide license to reproduce, distribute, modify, and otherwise use and display Customer Data
                    incorporated within the Usage Data.
                    <br />
                    <br />
                    7.3 Feedback. If Customer or any of its employees or contractors sends or transmits any
                    communications or materials to Double Zero Security by mail, email, telephone, or otherwise,
                    suggesting or recommending changes to the Double Zero Security IP, including without limitation, new
                    features or functionality relating thereto, or any comments, questions, suggestions, or the like
                    (&quot;Feedback&quot;), Double Zero Security is free to use such Feedback irrespective of any other
                    obligation or limitation between the Parties governing such Feedback. Customer hereby assigns to
                    Double Zero Security on Customer&apos;s behalf, and on behalf of its employees, contractors and/or
                    agents, all right, title, and interest in, and Double Zero Security is free to use, without any
                    attribution or compensation to any party, any ideas, know-how, concepts, techniques, or other
                    intellectual property rights contained in the Feedback, for any purpose whatsoever, although Double
                    Zero Security is not required to use any Feedback.
                    <br />
                    <br />
                    8. Warranty Disclaimer. ALL DOUBLE ZERO SECURITY IP PROVIDED HEREUNDER, INCLUDING WITHOUT LIMITATION
                    THE SERVICES, IS PROVIDED &quot;AS IS&quot; AND DOUBLE ZERO SECURITY HEREBY DISCLAIMS ALL
                    WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. DOUBLE ZERO SECURITY SPECIFICALLY
                    DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                    NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE.
                    DOUBLE ZERO SECURITY MAKES NO WARRANTY OF ANY KIND THAT THE DOUBLE ZERO SECURITY IP, OR ANY PRODUCTS
                    OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER&apos;S OR ANY OTHER PERSON&apos;S REQUIREMENTS,
                    OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE,
                    SYSTEM OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.
                    <br />
                    <br />
                    9. Indemnification. Customer shall indemnify and, at Double Zero Security&apos;s option, defend
                    Double Zero Security and its affiliates, and its and their respective employees, contractors,
                    agents, representatives, successors, and assigns from and against any damages and costs resulting
                    from a third-party claim that the Customer Data, or any use of the Customer Data in accordance with
                    this Agreement, infringes or misappropriates such third party&apos;s rights and any third-party
                    claims based on Customer&apos;s or any Authorized User&apos;s (i) negligence or willful misconduct;
                    (ii) use of the Services in a manner not authorized by this Agreement; or (iii) use of the Services
                    in combination with data, software, hardware, equipment, or technology not provided or authorized by
                    Double Zero Security in writing.
                    <br />
                    <br />
                    10. Limitations of Liability. IN NO EVENT WILL DOUBLE ZERO SECURITY (OR ITS SUPPLIERS) BE LIABLE TO
                    THE CUSTOMER UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY,
                    INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY:
                    (i) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (ii)
                    INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (iii) LOSS
                    OF GOODWILL OR REPUTATION; (iv) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR RECOVERY OF ANY
                    DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (v) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH
                    CASE REGARDLESS OF WHETHER CUSTOMER WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH
                    LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE.
                    <br />
                    <br />
                    11. Term and Termination. The term of this Agreement begins on the Effective Date and, unless
                    terminated earlier pursuant to this Agreement&apos;s express provisions, will continue in effect for
                    the period set forth on the applicable order form (the &quot;Term&quot;). Double Zero Security may
                    terminate this Agreement, effective on written notice to Customer. Upon expiration or earlier
                    termination of this Agreement, Customer shall immediately discontinue use of the Double Zero
                    Security IP and, without limiting Customer&apos;s obligations under Section 6, Customer shall
                    delete, destroy, or return all copies of the Double Zero Security IP and certify in writing to the
                    Double Zero Security that the Double Zero Security IP has been deleted or destroyed. No expiration
                    or termination will affect Customer&apos;s obligation to pay all Fees that may have become due
                    before such expiration or termination. This Section 11.4 and Sections 1, 5, 6, 7, 8, 9, 10, and 12
                    survive any termination or expiration of this Agreement. No other provisions of this Agreement
                    survive the expiration or earlier termination of this Agreement.
                    <br />
                    <br />
                    12. Miscellaneous.
                    <br />
                    <br />
                    12.1 Entire Agreement. This Agreement, together with any other documents incorporated herein by
                    reference, constitutes the sole and entire agreement of the Parties with respect to the subject
                    matter of this Agreement and supersedes all prior and contemporaneous understandings, agreements,
                    and representations and warranties, both written and oral, with respect to such subject matter.
                    <br />
                    <br />
                    12.2 Notices. All notices, requests, consents, claims, demands, waivers, and other communications
                    hereunder (each, a &quot;Notice&quot;) must be in writing and addressed to the Parties at the
                    addresses set forth in the applicable order form or to such other address that may be designated by
                    the Party giving Notice from time to time in accordance with this Section. All Notices must be
                    delivered by personal delivery, nationally recognized overnight courier (with all fees pre-paid),
                    email (with confirmation of transmission) or certified or registered mail (in each case, return
                    receipt requested, postage pre-paid). Except as otherwise provided in this Agreement, a Notice is
                    effective only: (i) upon receipt by the receiving Party; and (ii) if the Party giving the Notice has
                    complied with the requirements of this Section.
                    <br />
                    <br />
                    12.3 Force Majeure. In no event shall Double Zero Security be liable to Customer, or be deemed to
                    have breached this Agreement, for any failure or delay in performing its obligations under this
                    Agreement (except for any obligations to make payments), if and to the extent such failure or delay
                    is caused by any circumstances beyond Double Zero Security’s reasonable control, including but not
                    limited to acts of God, flood, fire, earthquake, explosion, war, terrorism, invasion, riot or other
                    civil unrest, strikes, labor stoppages or slowdowns or other industrial disturbances, or passage of
                    law or any action taken by a governmental or public authority, including imposing an embargo.
                    <br />
                    <br />
                    12.4 Amendment and Modification; Waiver. No amendment to or modification of this Agreement is
                    effective unless it is in writing and signed by an authorized representative of each Party. No
                    waiver by any Party of any of the provisions hereof will be effective unless explicitly set forth in
                    writing and signed by the Party so waiving. Except as otherwise set forth in this Agreement: (i) no
                    failure to exercise, or delay in exercising, any rights, remedy, power, or privilege arising from
                    this Agreement will operate or be construed as a waiver thereof; and (ii) no single or partial
                    exercise of any right, remedy, power, or privilege hereunder will preclude any other or further
                    exercise thereof or the exercise of any other right, remedy, power, or privilege.
                    <br />
                    <br />
                    12.5 Severability. If any provision of this Agreement is invalid, illegal, or unenforceable in any
                    jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term or
                    provision of this Agreement or invalidate or render unenforceable such term or provision in any
                    other jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or
                    unenforceable, the Parties shall negotiate in good faith to modify this Agreement so as to effect
                    their original intent as closely as possible in a mutually acceptable manner in order that the
                    transactions contemplated hereby be consummated as originally contemplated to the greatest extent
                    possible.
                    <br />
                    <br />
                    12.6 Governing Law; Submission to Jurisdiction. This Agreement is governed by and construed in
                    accordance with the internal laws of the State of California without giving effect to any choice or
                    conflict of law provision or rule that would require or permit the application of the laws of any
                    jurisdiction other than those of the State of California. Any legal suit, action, or proceeding
                    arising out of or related to this Agreement or the licenses granted hereunder will be instituted
                    exclusively in the federal courts of the United States or the courts of the State of California in
                    each case located in San Francisco County, and each Party irrevocably submits to the exclusive
                    jurisdiction of such courts in any such suit, action, or proceeding.
                    <br />
                    <br />
                    12.7 Assignment. Customer may not assign any of its rights or delegate any of its obligations
                    hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise,
                    without the prior written consent of Double Zero Security. Double Zero Security may assign this
                    Agreement freely without restriction. Any purported assignment or delegation in violation of this
                    Section will be null and void. No assignment or delegation will relieve the assigning or delegating
                    Party of any of its obligations hereunder. This Agreement is binding upon and inures to the benefit
                    of the Parties and their respective permitted successors and assigns.
                    <br />
                    <br />
                    12.8 Export Regulation. The Services utilize software and technology that may be subject to US
                    export control laws, including the US Export Administration Act and its associated regulations.
                    Customer shall not, directly or indirectly, export, re-export, or release the Services or the
                    underlying software or technology, or make the Services or the underlying software or technology
                    accessible from, any jurisdiction or country to which export, re-export, or release is prohibited by
                    law, rule, or regulation. Customer shall comply with all applicable federal laws, regulations, and
                    rules, and complete all required undertakings (including obtaining any necessary export license or
                    other governmental approval), prior to exporting, re-exporting, releasing, or otherwise making the
                    Services or the underlying software or technology available outside the US.
                    <br />
                    <br />
                    12.9 US Government Rights. Each of the Documentation and the software components that constitute the
                    Services is a &quot;commercial item&quot; as that term is defined at 48 C.F.R. § 2.101, consisting
                    of &quot;commercial computer software&quot; and &quot;commercial computer software
                    documentation&quot; as such terms are used in 48 C.F.R. § 12.212. Accordingly, if Customer is an
                    agency of the US Government or any contractor therefor, Customer only receives those rights with
                    respect to the Services and Documentation as are granted to all other end users, in accordance with
                    (i) 48 C.F.R. § 227.7201 through 48 C.F.R. § 227.7204, with respect to the Department of Defense and
                    their contractors, or (ii) 48 C.F.R. § 12.212, with respect to all other US Government users and
                    their contractors.
                    <br />
                    <br />
                    12.10 Equitable Relief. Customer acknowledges and agrees that a breach or threatened breach of any
                    of its obligations under Section 6 or Section 2.3, would cause Double Zero Security irreparable harm
                    for which monetary damages would not be an adequate remedy and agrees that, in the event of such
                    breach or threatened breach, Double Zero Security will be entitled to equitable relief, including a
                    restraining order, an injunction, specific performance and any other relief that may be available
                    from any court, without any requirement to post a bond or other security, or to prove actual damages
                    or that monetary damages are not an adequate remedy. Such remedies are not exclusive and are in
                    addition to all other remedies that may be available at law, in equity or otherwise.
                    <br />
                    <br />
                    12.11 Anticorruption Laws. Customer acknowledges that it is familiar with and understands the
                    provisions of the U.S. Foreign Corrupt Practices Act (the “FCPA&quot;) and the U.K. Bribery Act of
                    2010 (“UKBA”) and agrees to comply with its terms as well as any provisions of local law related
                    thereto. Customer further understands the provisions relating to the FCPA and UKBA’s prohibitions
                    regarding the payment or giving of anything of value, including but not limited to payments, gifts,
                    travel, entertainment and meals, either directly or indirectly, to an official of a foreign
                    government or political party for the purpose of influencing an act or decision in his or her
                    official capacity or inducing the official to use his or her party&apos;s influence with that
                    government, to obtain or retain business involving the Deliverables. Customer agrees to not violate
                    or knowingly let anyone violate the FCPA or UKBA, and Customer agrees that no payment it makes will
                    constitute a bribe, influence payment, kickback, rebate, or other payment that violates the FCPA,
                    the UKBA, or any other applicable anticorruption or antibribery law
                    <br />
                    <br />
                    WEBSITE TERMS OF USE VERSION 1.0
                    <br />
                    <br />
                    LAST REVISED ON: JUNE 22, 2021
                    <br />
                    <br />
                    The website located at doublezero.io (the “Site”) is a copyrighted work belonging to Double Zero
                    Security, Inc. (“Company”, “us”, “our”, and “we”). Certain features of the Site may be subject to
                    additional guidelines, terms, or rules, which will be posted on the Site in connection with such
                    features. All such additional terms, guidelines, and rules are incorporated by reference into these
                    Terms.
                    <br />
                    <br />
                    THESE TERMS OF USE (THESE “TERMS”) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN
                    YOUR USE OF THE SITE. BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF
                    YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT,
                    AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                    REPRESENT). YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS
                    OLD. IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE
                    SITE. THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 10.2) ON AN INDIVIDUAL BASIS TO RESOLVE
                    DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN
                    THE EVENT OF A DISPUTE.
                    <br />
                    <br />
                    1. ACCOUNTS
                    <br />
                    <br />
                    1.1 Account Creation. In order to use certain features of the Site, you must register for an account
                    (“Account”) and provide certain information about yourself as prompted by the account registration
                    form. You represent and warrant that: (a) all required registration information you submit is
                    truthful and accurate; (b) you will maintain the accuracy of such information. You may delete your
                    Account at any time, for any reason, by following the instructions on the Site. Company may suspend
                    or terminate your Account in accordance with Section 8.
                    <br />
                    <br />
                    1.2 Account Responsibilities. You are responsible for maintaining the confidentiality of your
                    Account login information and are fully responsible for all activities that occur under your
                    Account. You agree to immediately notify Company of any unauthorized use, or suspected unauthorized
                    use of your Account or any other breach of security. Company cannot and will not be liable for any
                    loss or damage arising from your failure to comply with the above requirements.
                    <br />
                    <br />
                    2. ACCESS TO THE SITE
                    <br />
                    <br />
                    2.1 License. Subject to these Terms, Company grants you a non-transferable, non-exclusive,
                    revocable, limited license to use and access the Site solely for your own personal, noncommercial
                    use.
                    <br />
                    <br />
                    2.2 Certain Restrictions. The rights granted to you in these Terms are subject to the following
                    restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or
                    otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on
                    the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or
                    reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar
                    or competitive website, product, or service; and (d) except as expressly stated herein, no part of
                    the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or
                    transmitted in any form or by any means. Unless otherwise indicated, any future release, update, or
                    other addition to functionality of the Site shall be subject to these Terms. All copyright and other
                    proprietary notices on the Site (or on any content displayed on the Site) must be retained on all
                    copies thereof.
                    <br />
                    <br />
                    2.3 Modification. Company reserves the right, at any time, to modify, suspend, or discontinue the
                    Site (in whole or in part) with or without notice to you. You agree that Company will not be liable
                    to you or to any third party for any modification, suspension, or discontinuation of the Site or any
                    part thereof.
                    <br />
                    <br />
                    2.4 No Support or Maintenance. You acknowledge and agree that Company will have no obligation to
                    provide you with any support or maintenance in connection with the Site.
                    <br />
                    <br />
                    2.5 Ownership. Excluding any User Content that you may provide (defined below), you acknowledge that
                    all the intellectual property rights, including copyrights, patents, trade marks, and trade secrets,
                    in the Site and its content are owned by Company or Company’s suppliers. Neither these Terms (nor
                    your access to the Site) transfers to you or any third party any rights, title or interest in or to
                    such intellectual property rights, except for the limited access rights expressly set forth in
                    Section 2.1. Company and its suppliers reserve all rights not granted in these Terms. There are no
                    implied licenses granted under these Terms.
                    <br />
                    <br />
                    3. USER CONTENT
                    <br />
                    <br />
                    3.1 User Content. “User Content” means any and all information and content that a user submits to,
                    or uses with, the Site (e.g., content in the user’s profile or postings). You are solely responsible
                    for your User Content. You assume all risks associated with use of your User Content, including any
                    reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User
                    Content that personally identifies you or any third party. You hereby represent and warrant that
                    your User Content does not violate our Acceptable Use Policy (defined in Section 3.3). You may not
                    represent or imply to others that your User Content is in any way provided, sponsored or endorsed by
                    Company. Because you alone are responsible for your User Content, you may expose yourself to
                    liability if, for example, your User Content violates the Acceptable Use Policy. Company is not
                    obligated to backup any User Content, and your User Content may be deleted at any time without prior
                    notice. You are solely responsible for creating and maintaining your own backup copies of your User
                    Content if you desire.
                    <br />
                    <br />
                    3.2 License. You hereby grant (and you represent and warrant that you have the right to grant) to
                    Company an irrevocable, nonexclusive, royalty-free and fully paid, worldwide license to reproduce,
                    distribute, publicly display and perform, prepare derivative works of, incorporate into other works,
                    and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights,
                    solely for the purposes of including your User Content in the Site. You hereby irrevocably waive
                    (and agree to cause to be waived) any claims and assertions of moral rights or attribution with
                    respect to your User Content.
                    <br />
                    <br />
                    3.3 Acceptable Use Policy. The following terms constitute our “Acceptable Use Policy”: (a) You agree
                    not to use the Site to collect, upload, transmit, display, or distribute any User Content (i) that
                    violates any third-party right, including any copyright, trademark, patent, trade secret, moral
                    right, privacy right, right of publicity, or any other intellectual property or proprietary right;
                    (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s
                    privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene,
                    patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group
                    or individual or is otherwise objectionable; (iii) that is harmful to minors in any way; or (iv)
                    that is in violation of any law, regulation, or obligations or restrictions imposed by any third
                    party. (b) In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site
                    any computer viruses, worms, or any software intended to damage or alter a computer system or data;
                    (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk
                    mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited
                    messages, whether commercial or otherwise; (iii) use the Site to harvest, collect, gather or
                    assemble information or data regarding other users, including e-mail addresses, without their
                    consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to
                    the Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain
                    unauthorized access to the Site (or to other computer systems or networks connected to or used
                    together with the Site), whether through password mining or any other means; (vi) harass or
                    interfere with any other user’s use and enjoyment of the Site; or (vi) use software or automated
                    agents or scripts to produce multiple accounts on the Site, or to generate automated searches,
                    requests, or queries to (or to strip, scrape, or mine data from) the Site (provided, however, that
                    we conditionally grant to the operators of public search engines revocable permission to use spiders
                    to copy materials from the Site for the sole purpose of and solely to the extent necessary for
                    creating publicly available searchable indices of the materials, but not caches or archives of such
                    materials, subject to the parameters set forth in our robots.txt file).
                    <br />
                    <br />
                    3.4 Enforcement. We reserve the right (but have no obligation) to review any User Content, and to
                    investigate and/or take appropriate action against you in our sole discretion if you violate the
                    Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or
                    any other person. Such action may include removing or modifying your User Content, terminating your
                    Account in accordance with Section 8, and/or reporting you to law enforcement authorities.
                    <br />
                    <br />
                    3.5 Feedback. If you provide Company with any feedback or suggestions regarding the Site
                    (“Feedback”), you hereby assign to Company all rights in such Feedback and agree that Company shall
                    have the right to use and fully exploit such Feedback and related information in any manner it deems
                    appropriate. Company will treat any Feedback you provide to Company as non-confidential and
                    non-proprietary. You agree that you will not submit to Company any information or ideas that you
                    consider to be confidential or proprietary.
                    <br />
                    <br />
                    4. INDEMNIFICATION. You agree to indemnify and hold Company (and its officers, employees, and
                    agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third
                    party due to or arising out of (a) your use of the Site, (b) your violation of these Terms, (c) your
                    violation of applicable laws or regulations or (d) your User Content. Company reserves the right, at
                    your expense, to assume the exclusive defense and control of any matter for which you are required
                    to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to
                    settle any matter without the prior written consent of Company. Company will use reasonable efforts
                    to notify you of any such claim, action or proceeding upon becoming aware of it.
                    <br />
                    <br />
                    5. THIRD-PARTY LINKS & ADS; OTHER USERS
                    <br />
                    <br />
                    5.1 Third-Party Links & Ads. The Site may contain links to third-party websites and services, and/or
                    display advertisements for third parties (collectively, “Third-Party Links & Ads”). Such Third-Party
                    Links & Ads are not under the control of Company, and Company is not responsible for any Third-Party
                    Links & Ads. Company provides access to these Third-Party Links & Ads only as a convenience to you,
                    and does not review, approve, monitor, endorse, warrant, or make any representations with respect to
                    Third-Party Links & Ads. You use all Third-Party Links & Ads at your own risk, and should apply a
                    suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links
                    & Ads, the applicable third party’s terms and policies apply, including the third party’s privacy
                    and data gathering practices. You should make whatever investigation you feel necessary or
                    appropriate before proceeding with any transaction in connection with such Third-Party Links & Ads.
                    <br />
                    <br />
                    5.2 Other Users. Each Site user is solely responsible for any and all of its own User Content.
                    Because we do not control User Content, you acknowledge and agree that we are not responsible for
                    any User Content, whether provided by you or by others. We make no guarantees regarding the
                    accuracy, currency, suitability, or quality of any User Content. Your interactions with other Site
                    users are solely between you and such users. You agree that Company will not be responsible for any
                    loss or damage incurred as the result of any such interactions. If there is a dispute between you
                    and any Site user, we are under no obligation to become involved.
                    <br />
                    <br />
                    5.3 Release. You hereby release and forever discharge the Company (and our officers, employees,
                    agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present
                    and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of
                    action of every kind and nature (including personal injuries, death, and property damage), that has
                    arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site
                    (including any interactions with, or act or omission of, other Site users or any Third-Party Links &
                    Ads). IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN
                    CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
                    CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE,
                    WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”
                    <br />
                    <br />
                    6. DISCLAIMERS THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY (AND OUR
                    SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS,
                    IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
                    PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE (AND OUR SUPPLIERS)
                    MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED,
                    TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL
                    CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE,
                    ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE. SOME
                    JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY
                    TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE
                    ABOVE LIMITATION MAY NOT APPLY TO YOU.
                    <br />
                    <br />
                    7. LIMITATION ON LIABILITY TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR
                    SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT
                    OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
                    DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN
                    IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITE IS
                    AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR
                    COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. TO THE MAXIMUM EXTENT PERMITTED BY LAW,
                    NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES
                    ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
                    THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE
                    OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO
                    LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW
                    THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                    LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                    <br />
                    <br />
                    8. TERM AND TERMINATION. Subject to this Section, these Terms will remain in full force and effect
                    while you use the Site. We may suspend or terminate your rights to use the Site (including your
                    Account) at any time for any reason at our sole discretion, including for any use of the Site in
                    violation of these Terms. Upon termination of your rights under these Terms, your Account and right
                    to access and use the Site will terminate immediately. You understand that any termination of your
                    Account may involve deletion of your User Content associated with your Account from our live
                    databases. Company will not have any liability whatsoever to you for any termination of your rights
                    under these Terms, including for termination of your Account or deletion of your User Content. Even
                    after your rights under these Terms are terminated, the following provisions of these Terms will
                    remain in effect: Sections 2.2 through 2.5, Section 3 and Sections 4 through 10.
                    <br />
                    <br />
                    9. COPYRIGHT POLICY. Company respects the intellectual property of others and asks that users of our
                    Site do the same. In connection with our Site, we have adopted and implemented a policy respecting
                    copyright law that provides for the removal of any infringing materials and for the termination, in
                    appropriate circumstances, of users of our online Site who are repeat infringers of intellectual
                    property rights, including copyrights. If you believe that one of our users is, through the use of
                    our Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly
                    infringing material removed, the following information in the form of a written notification
                    (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent: 1. your
                    physical or electronic signature; 2. identification of the copyrighted work(s) that you claim to
                    have been infringed; 3. identification of the material on our services that you claim is infringing
                    and that you request us to remove; 4. sufficient information to permit us to locate such material;
                    5. your address, telephone number, and e-mail address; 6. a statement that you have a good faith
                    belief that use of the objectionable material is not authorized by the copyright owner, its agent,
                    or under the law; and 7. a statement that the information in the notification is accurate, and under
                    penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed
                    or that you are authorized to act on behalf of the copyright owner. Please note that, pursuant to 17
                    U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification
                    automatically subjects the complaining party to liability for any damages, costs and attorney’s fees
                    incurred by us in connection with the written notification and allegation of copyright infringement.
                    <br />
                    <br />
                    10. GENERAL
                    <br />
                    <br />
                    10.1 Changes. These Terms are subject to occasional revision, and if we make any substantial
                    changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us
                    (if any), and/or by prominently posting notice of the changes on our Site. You are responsible for
                    providing us with your most current e-mail address. In the event that the last e-mail address that
                    you have provided us is not valid, or for any reason is not capable of delivering to you the notice
                    described above, our dispatch of the e-mail containing such notice will nonetheless constitute
                    effective notice of the changes described in the notice. Any changes to these Terms will be
                    effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice
                    to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes
                    on our Site. These changes will be effective immediately for new users of our Site. Continued use of
                    our Site following notice of such changes shall indicate your acknowledgement of such changes and
                    agreement to be bound by the terms and conditions of such changes.
                    <br />
                    <br />
                    10.2 Dispute Resolution. Please read this Arbitration Agreement carefully. It is part of your
                    contract with Company and affects your rights. It contains procedures for MANDATORY BINDING
                    ARBITRATION AND A CLASS ACTION WAIVER. (a) Applicability of Arbitration Agreement. All claims and
                    disputes (excluding claims for injunctive or other equitable relief as set forth below) in
                    connection with the Terms or the use of any product or service provided by the Company that cannot
                    be resolved informally or in small claims court shall be resolved by binding arbitration on an
                    individual basis under the terms of this Arbitration Agreement. Unless otherwise agreed to, all
                    arbitration proceedings shall be held in English. This Arbitration Agreement applies to you and the
                    Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest,
                    successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of
                    services or goods provided under the Terms. (b) Notice Requirement and Informal Dispute Resolution.
                    Before either party may seek arbitration, the party must first send to the other party a written
                    Notice of Dispute (“Notice”) describing the nature and basis of the claim or dispute, and the
                    requested relief. A Notice to the Company should be sent to: 610 University Avenue, Palo Alto, CA
                    94304. After the Notice is received, you and the Company may attempt to resolve the claim or dispute
                    informally. If you and the Company do not resolve the claim or dispute within thirty (30) days after
                    the Notice is received, either party may begin an arbitration proceeding. The amount of any
                    settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator
                    has determined the amount of the award, if any, to which either party is entitled. (c) Arbitration
                    Rules. Arbitration shall be initiated through the American Arbitration Association (“AAA”), an
                    established alternative dispute resolution provider (“ADR Provider”) that offers arbitration as set
                    forth in this section. If AAA is not available to arbitrate, the parties shall agree to select an
                    alternative ADR Provider. The rules of the ADR Provider shall govern all aspects of the arbitration,
                    including but not limited to the method of initiating and/or demanding arbitration, except to the
                    extent such rules are in conflict with the Terms. The AAA Consumer Arbitration Rules (“Arbitration
                    Rules”) governing the arbitration are available online at www.adr.org or by calling the AAA at
                    1-800-778-7879. The arbitration shall be conducted by a single, neutral arbitrator. Any claims or
                    disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US
                    $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the
                    party seeking relief. For claims or disputes where the total amount of the award sought is Ten
                    Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the
                    Arbitration Rules. Any hearing will be held in a location within 100 miles of your residence, unless
                    you reside outside of the United States, and unless the parties agree otherwise. If you reside
                    outside of the U.S., the arbitrator shall give the parties reasonable notice of the date, time and
                    place of any oral hearings. Any judgment on the award rendered by the arbitrator may be entered in
                    any court of competent jurisdiction. If the arbitrator grants you an award that is greater than the
                    last settlement offer that the Company made to you prior to the initiation of arbitration, the
                    Company will pay you the greater of the award or $2,500.00. Each party shall bear its own costs
                    (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal
                    share of the fees and costs of the ADR Provider. (d) Additional Rules for Non-Appearance Based
                    Arbitration. If non-appearance based arbitration is elected, the arbitration shall be conducted by
                    telephone, online and/or based solely on written submissions; the specific manner shall be chosen by
                    the party initiating the arbitration. The arbitration shall not involve any personal appearance by
                    the parties or witnesses unless otherwise agreed by the parties. (e) Time Limits. If you or the
                    Company pursue arbitration, the arbitration action must be initiated and/or demanded within the
                    statute of limitations (i.e., the legal deadline for filing a claim) and within any deadline imposed
                    under the AAA Rules for the pertinent claim. (f) Authority of Arbitrator. If arbitration is
                    initiated, the arbitrator will decide the rights and liabilities, if any, of you and the Company,
                    and the dispute will not be consolidated with any other matters or joined with any other cases or
                    parties. The arbitrator shall have the authority to grant motions dispositive of all or part of any
                    claim. The arbitrator shall have the authority to award monetary damages, and to grant any
                    non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and
                    the Terms. The arbitrator shall issue a written award and statement of decision describing the
                    essential findings and conclusions on which the award is based, including the calculation of any
                    damages awarded. The arbitrator has the same authority to award relief on an individual basis that a
                    judge in a court of law would have. The award of the arbitrator is final and binding upon you and
                    the Company. (g) Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY
                    RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all
                    claims and disputes shall be resolved by arbitration under this Arbitration Agreement. Arbitration
                    procedures are typically more limited, more efficient and less costly than rules applicable in a
                    court and are subject to very limited review by a court. In the event any litigation should arise
                    between you and the Company in any state or federal court in a suit to vacate or enforce an
                    arbitration award or otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead
                    electing that the dispute be resolved by a judge. (h) Waiver of Class or Consolidated Actions. ALL
                    CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED
                    ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT
                    BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER. (i)
                    Confidentiality. All aspects of the arbitration proceeding, including but not limited to the award
                    of the arbitrator and compliance therewith, shall be strictly confidential. The parties agree to
                    maintain confidentiality unless otherwise required by law. This paragraph shall not prevent a party
                    from submitting to a court of law any information necessary to enforce this Agreement, to enforce an
                    arbitration award, or to seek injunctive or equitable relief. (j) Severability. If any part or parts
                    of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of
                    competent jurisdiction, then such specific part or parts shall be of no force and effect and shall
                    be severed and the remainder of the Agreement shall continue in full force and effect. (k) Right to
                    Waive. Any or all of the rights and limitations set forth in this Arbitration Agreement may be
                    waived by the party against whom the claim is asserted. Such waiver shall not waive or affect any
                    other portion of this Arbitration Agreement. (l) Survival of Agreement. This Arbitration Agreement
                    will survive the termination of your relationship with Company. (m) Small Claims Court.
                    Notwithstanding the foregoing, either you or the Company may bring an individual action in small
                    claims court. (n) Emergency Equitable Relief. Notwithstanding the foregoing, either party may seek
                    emergency equitable relief before a state or federal court in order to maintain the status quo
                    pending arbitration. A request for interim measures shall not be deemed a waiver of any other rights
                    or obligations under this Arbitration Agreement. (o) Claims Not Subject to Arbitration.
                    Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act,
                    and infringement or misappropriation of the other party’s patent, copyright, trademark or trade
                    secrets shall not be subject to this Arbitration Agreement. (p) Courts. In any circumstances where
                    the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby
                    agree to submit to the personal jurisdiction of the courts located within San Francisco County,
                    California, for such purpose
                    <br />
                    <br />
                    10.3 Export. The Site may be subject to U.S. export control laws and may be subject to export or
                    import regulations in other countries. You agree not to export, reexport, or transfer, directly or
                    indirectly, any U.S. technical data acquired from Company, or any products utilizing such data, in
                    violation of the United States export laws or regulations.
                    <br />
                    <br />
                    10.4 Disclosures. Company is located at the address in Section 10.8. If you are a California
                    resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer
                    Product of the California Department of Consumer Affairs by contacting them in writing at 400 R
                    Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
                    <br />
                    <br />
                    10.5 Electronic Communications. The communications between you and Company use electronic means,
                    whether you use the Site or send us emails, or whether Company posts notices on the Site or
                    communicates with you via email. For contractual purposes, you (a) consent to receive communications
                    from Company in an electronic form; and (b) agree that all terms and conditions, agreements,
                    notices, disclosures, and other communications that Company provides to you electronically satisfy
                    any legal requirement that such communications would satisfy if it were be in a hardcopy writing.
                    The foregoing does not affect your non-waivable rights.
                    <br />
                    <br />
                    10.6 Entire Terms. These Terms constitute the entire agreement between you and us regarding the use
                    of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not
                    operate as a waiver of such right or provision. The section titles in these Terms are for
                    convenience only and have no legal or contractual effect. The word “including” means “including
                    without limitation”. If any provision of these Terms is, for any reason, held to be invalid or
                    unenforceable, the other provisions of these Terms will be unimpaired and the invalid or
                    unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum
                    extent permitted by law. Your relationship to Company is that of an independent contractor, and
                    neither party is an agent or partner of the other. These Terms, and your rights and obligations
                    herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without
                    Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer
                    in violation of the foregoing will be null and void. Company may freely assign these Terms. The
                    terms and conditions set forth in these Terms shall be binding upon assignees.
                    <br />
                    <br />
                    10.7 Copyright/Trademark Information. Copyright © 2024 Double Zero Security, Inc. All rights
                    reserved. All trademarks, logos and service marks (“Marks”) displayed on the Site are our property
                    or the property of other third parties. You are not permitted to use these Marks without our prior
                    written consent or the consent of such third party which may own the Marks.
                    <br />
                    <br />
                    10.8 Contact Information: Double Zero Security Address: 610 University Avenue, Palo Alto, California
                    94304 Telephone: 703-965-6079 Email: support@doublezero.io
                </p>
            </div>
        </ExternalPage>
    );
};
export default Terms;
