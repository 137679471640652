import { CheckCircleIcon } from '@heroicons/react/24/solid';
import { getIconSourceURL, getProviderIconElement } from 'Map/Graph/Icons';

export const availableRecipients = [
    {
        value: 'email',
        label: 'Email',
        description: 'mail.example.com',
    },
    {
        value: 'notification',
        label: 'Notification',
        description: 'In-App Notification',
    },
    {
        value: 'webhook',
        label: 'Webhook',
        description: 'Custom Webhook',
    },
    {
        value: 'kafka',
        label: 'Kafka',
        description: 'Confluent Cloud',
    },
    {
        value: 'slack',
        label: 'Slack',
        description: '#ops',
    },
    {
        value: 'pagerduty',
        label: 'PagerDuty',
        description: 'Security Team',
    },

    {
        value: 'splunk',
        label: 'Splunk',
        description: 'Production Splunk',
    },

    {
        value: 'zoom',
        label: 'Zoom Chat',
        description: '#security',
    },
];

export const AlertsRecipients = (): JSX.Element => {
    return (
        <div className="p-4 text-xs">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">
                    <button className="uppercase tracking-wider font-bold text-xs text-gray-400">
                        Alert Recipients
                    </button>
                </p>
            </div>
            <div className="ml-2 pt-2 space-y-4 lg:w-1/2 w-full">
                <div>
                    <p className="font-bold mb-4">Default Recipient</p>
                    <ul className="space-y-4 ml-2 pr-1">
                        <li className="flex items-center justify-between">
                            <span className="flex items-center space-x-1 capitalize">
                                <CheckCircleIcon className="h-4 text-green-600" />
                                <h4>
                                    Double Zero API
                                    <span className="text-gray-500"> — fabric.doublezero.io</span>
                                </h4>
                            </span>
                            <span>
                                <button className="focus:outline-none">Show Details</button>
                            </span>
                        </li>
                    </ul>
                </div>
                <div>
                    <p className="font-bold mb-4">Configured Recipients</p>
                    <ul className="space-y-4 ml-2 pr-1">
                        {availableRecipients.map((recipient) => {
                            return (
                                <li className="flex items-center justify-between" key={recipient.value}>
                                    <span className="flex items-center space-x-1 capitalize">
                                        <CheckCircleIcon className="h-4 text-green-600" />
                                        <h4>
                                            {recipient.label}
                                            <span className="text-gray-500"> — {recipient.description}</span>
                                        </h4>
                                    </span>
                                    <span>
                                        <button className="focus:outline-none">Show Details</button>
                                    </span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="pt-2">
                    <p className="font-bold">Add Another +</p>
                    <div className="mt-4 grid grid-cols-4 gap-8 text-gray-100">
                        {availableRecipients.map((provider) => (
                            <button
                                className="btn w-full capitalize flex flex-col justify-center items-center rounded-md shadow-md hover:bg-gray-600 hover:shadow-xl hover:-translate-y-1 active:scale-95 active:shadow-none active:bg-gray-700 active:translate-y-0.5 transition-all"
                                key={provider.value}
                            >
                                <img className="h-8 w-8 mb-2" src={getIcon(provider.label)} />
                                {provider.label}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

const getIcon = (provider: string) => {
    return getIconSourceURL(getProviderIconElement(provider));
};
