import { Link, Node } from 'Types/types';
import { Visible } from 'Types/types';
import { ForceGraphMethods } from 'react-force-graph-2d';

export const clearLocalCaches = (): void => {
    localStorage.removeItem('tenantId');
};

export const capitalize = (word: string): string => word.charAt(0).toUpperCase() + word.slice(1);

// Check if element is truncated
export const isTruncated = (e: HTMLElement) => {
    return e.offsetWidth < e.scrollWidth;
};

//Breaks long strings by underscore for node names
export const wordWrap = (displayName: string) => {
    if (displayName.includes('_') || displayName.includes('-') || displayName.includes(' ')) {
        return {
            __html: `<span class="break-word">${displayName.replaceAll(/_/g, '_<wbr />')}</span>`,
        };
    } else {
        return {
            __html: `<span class="break-all">${displayName}</span>`,
        };
    }
};

export const formatNumber = (number: number) => {
    let unit;
    let num;
    if (number > 1000000000) {
        unit = 'B';
        num = number / 1000000000;
    } else if (number > 1000000) {
        unit = 'M';
        num = number / 1000000;
    } else if (number > 1000) {
        unit = 'K';
        num = number / 1000;
    }

    if (unit && num) {
        if (num % 1 === 0) {
            return num.toString() + unit;
        } else {
            return num.toFixed(1).toString() + unit;
        }
    }

    return number.toString();
};

export const classNames = (...classes: string[]): string => {
    return classes.filter(Boolean).join(' ');
};

export const isNodeHidden = (node: Node, nodeTypesVisible: Visible): boolean => {
    switch (node.label) {
        case 'actor':
            return !nodeTypesVisible.actors;
        case 'device':
            return !nodeTypesVisible.devices;
        case 'application':
            return !nodeTypesVisible.applications;
        case 'identity':
            return !nodeTypesVisible.identities;
        case 'target':
            return !nodeTypesVisible.targets;
    }

    return false;
};

export const breakLoop = (root: string, cur: string | undefined, next: string | undefined): boolean => {
    // Logic to stop loops forming
    if (root == 'device') {
        if (cur == 'identity' && next == 'device') {
            return true;
        }
        if (cur == 'actor' && next == 'identity') {
            return true;
        }
        if (cur == 'target' && next == 'identity') {
            return true;
        }
        if (cur == 'application' && next == 'identity') {
            return true;
        }
    }
    if (root == 'identity') {
        if (cur == 'actor' && next == 'identity') {
            return true;
        }
        if (cur == 'target' && next == 'identity') {
            return true;
        }
        if (cur == 'device' && next == 'identity') {
            return true;
        }
        if (cur == 'application' && next == 'identity') {
            return true;
        }
    }
    if (root == 'actor') {
        if (cur == 'target' && next == 'identity') {
            return true;
        }
        if (cur == 'device' && next == 'identity') {
            return true;
        }
        if (cur == 'application' && next == 'identity') {
            return true;
        }
    }
    if (root == 'application') {
        if (cur == 'target' && next == 'identity') {
            return true;
        }
        if (cur == 'device' && next == 'identity') {
            return true;
        }
        if (cur == 'actor' && next == 'identity') {
            return true;
        }
    }
    if (root == 'target') {
        if (cur == 'identity' && next == 'target') {
            return true;
        }
        if (cur == 'actor' && next == 'identity') {
            return true;
        }
        if (cur == 'device' && next == 'identity') {
            return true;
        }
        if (cur == 'application' && next == 'identity') {
            return true;
        }
    }
    return false;
};

export const includeNodeInMap = (node: Node): boolean => {
    return !node.hidden;
};

export const resetLinkStrengths = (
    g: ForceGraphMethods | undefined,
    strengths: {
        applicationStrength: number;
        actorStrength: number;
        deviceStrength: number;
        identityStrength: number;
        targetStrength: number;
        linkStrength: number;
    },
): void => {
    if (g) {
        g.d3Force('charge')?.strength((node: Node) => {
            if (node.label === 'target') {
                return strengths.targetStrength;
            } else if (node.label === 'identity') {
                return strengths.identityStrength;
            } else if (node.label === 'actor') {
                return strengths.actorStrength;
            } else if (node.label === 'device') {
                return strengths.deviceStrength;
            } else if (node.label === 'application') {
                return strengths.applicationStrength;
            }
            return -1;
        });
        g.d3Force('groupingForce', () => null);
        g.d3Force('link')?.strength(strengths.linkStrength);
    }
};

export const groupingForceApplied = (group: Record<string, string | undefined>) => {
    return Object.values(group).some((g) => g !== undefined);
};

export type deviceTypes = {
    DEVICE_MOBILE: string;
    DEVICE_COMPUTER: string;
    DEVICE_TABLET: string;
    DEVICE_LAPTOP: string;
};
export const deviceTypeNameLookup = (deviceType: keyof deviceTypes): string => {
    const deviceTypeName: deviceTypes = {
        DEVICE_MOBILE: 'Mobile',
        DEVICE_COMPUTER: 'Desktop',
        DEVICE_TABLET: 'Tablet',
        DEVICE_LAPTOP: 'Laptop',
    };
    return deviceTypeName[deviceType] || 'Unknown Device';
};

export type operatingSystem = {
    OS_UNKNOWN: string;
    OS_WINDOWS: string;
    OS_MACOS: string;
    OS_LINUX: string;
    OS_ANDROID: string;
    OS_IOS: string;
    OS_WINDOWS_PHONE: string;
    OS_CHROMEOS: string;
    OS_WATCHOS: string;
    OS_TVOS: string;
    OS_DEBIAN: string;
    OS_GENTOO: string;
    OS_SOLARIS: string;
    OS_FREEBSD: string;
    OS_REDHAT: string;
    OS_UBUNTU: string;
};

export const operatingSystemNameLookup = (operatingSystem: string): string => {
    const operatingSystemName: operatingSystem = {
        OS_UNKNOWN: 'Unknown',
        OS_WINDOWS: 'Windows',
        OS_MACOS: 'macOS',
        OS_LINUX: 'Linux',
        OS_ANDROID: 'Android',
        OS_IOS: 'iOS',
        OS_WINDOWS_PHONE: 'Windows Phone',
        OS_CHROMEOS: 'ChromeOS',
        OS_WATCHOS: 'watchOS',
        OS_TVOS: 'tvOS',
        OS_DEBIAN: 'Debian',
        OS_GENTOO: 'Gentoo',
        OS_SOLARIS: 'Solaris',
        OS_FREEBSD: 'Freebsd',
        OS_REDHAT: 'Redhat',
        OS_UBUNTU: 'Ubuntu',
    };
    return operatingSystemName[operatingSystem as keyof operatingSystem] || 'Unknown System';
};

const tagNames: Record<string, string> = {
    TAG_ACTOR_OFFBOARDING: 'Actor is Off-boarding',
    TAG_ACTOR_AT_HOME: 'Actor is at Home',
    TAG_ACTOR_AT_WORK: 'Actor is at Work',
    TAG_ACTOR_TRAVELLING: 'Actor is Traveling',
    TAG_ACTOR_CLOUD_OPERATOR: 'Actor is a Cloud Service',
    TAG_DEVICE_IS_VM: 'Device is a Virtual Machine',
    TAG_DEVICE_HAS_TPM: 'Device has a TPM',
    TAG_DEVICE_HAS_AGENT: 'Device has an Agent',
    TAG_DEVICE_IS_MDM_MANAGED: 'Device is Managed by MDM',
    TAG_SESSION_FLAGGED_BY_AZURE: 'Session is Flagged by Azure',
    TAG_SESSION_FLAGGED_BY_OKTA: 'Session is Flagged by Okta',
    TAG_SESSION_TOR_EXIT: 'Session is Tor Exit Node',
    TAG_SESSION_CLOUD_ENDPOINT: 'Session is Cloud Endpoint',
    TAG_SESSION_IS_2FA_AUTHENTICATED: 'Session is 2FA Authenticated',
    TAG_IDENTITY_DUMMY: 'Identity is Dummy',
    TAG_IDENTITY_OKTA: 'Observed via Okta',
    TAG_IDENTITY_AZUREAD: 'Observed via AzureAD',
    TAG_IDENTITY_GITHUB: 'Observed via GitHub',
    TAG_IDENTITY_OFFICE365: 'Observed via Office365',
    TAG_IDENTITY_DROPBOX: 'Observed via Dropbox',
    TAG_IDENTITY_BOX: 'Observed via Box',
    TAG_IDENTITY_AWS: 'Observed via AWS',
    TAG_IDENTITY_AZURE: 'Observed via Azure',
    TAG_SOURCE_IDP: 'Identity Source is IDP',
    TAG_DEVICE: 'Device',
    TAG_ACTOR: 'Actor',
    TAG_ACTOR_EXTERNAL: 'Actor is External',
    TAG_ACTOR_INTERNAL: 'Actor is Internal',
    TAG_ACTOR_MACHINE: 'Actor is a Machine',
    TAG_APPLICATION_UNKNOWN: 'Application is Unknown',
    TAG_APPLICATION_WEB: 'Web Application',
    TAG_APPLICATION_MOBILE: 'Mobile Application',
    TAG_APPLICATION_DESKTOP: 'Desktop Application',
    TAG_APPLICATION_SERVICE: 'Service Application',
    TAG_TARGET_EXTERNAL: 'Target is External',
    Unknown: 'Unknown',
};

const reverseRecord = (input: Record<string, string>) => {
    return Object.fromEntries(Object.entries(input).map(([key, value]) => [value.toUpperCase(), key]));
};

export const tagNameLookup = (tag: string): string => {
    return tagNames[tag] || 'Unknown';
};

export const tagDescriptionLookup = (tagDesc: string): string => {
    const tagDescriptions = reverseRecord(tagNames);
    return tagDescriptions[tagDesc] || 'UNKNOWN';
};

export const targetNodeTypeLookup = (targetNodeType: string): string => {
    const targetNodeTypeName: Record<string, string> = {
        NODE_TYPE_GROUP: 'Group',
        NODE_TYPE_MAILBOX: 'Mailbox',
        NODE_TYPE_FILE: 'File',
        NODE_TYPE_SERVICE: 'Service',
        NODE_TYPE_WEB: 'Web Application',
    };

    return targetNodeTypeName[targetNodeType] || 'Unknown';
};

export const getOperatingSystemDisplayNameFromNode = (node: Node): string | undefined => {
    if (node.label == 'device' && node.props.deviceOperatingSystem) {
        const os = node.props.deviceOperatingSystem;
        return operatingSystemNameLookup(os);
    }
};

// take a string in bytes and return a string in the most appropriate unit
export const formatBytes = (bytes: number): string => {
    if (bytes < 1024) {
        return `${bytes} bytes`;
    }
    if (bytes < 1024 * 1024) {
        return `${(bytes / 1024).toFixed(2)} KB`;
    }
    if (bytes < 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024).toFixed(2)} MB`;
    }
    if (bytes < 1024 * 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024 / 1024).toFixed(2)} GB`;
    }
    if (bytes < 1024 * 1024 * 1024 * 1024 * 1024) {
        return `${(bytes / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`;
    }
    return `${(bytes / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(2)} PB`;
};

export const getDisplayName = (node: Node): string => {
    if (!node || !node.props) {
        return '';
    }

    if (node.props.displayName && node.props.displayName !== 'unknown') {
        return node.props.displayName;
    }

    if ((node.label === 'actor' || node.label === 'target') && node.props.alternateId) {
        return node.props.alternateId;
    }

    if (node.label === 'device' && node.props.deviceType) {
        return node.props.deviceType;
    }

    if (node.label) {
        return `${capitalize(node.label)} ${node.id}`;
    }

    return node.id.toString();
};

export const epochInNanoSecondsToLocaleString = (epochInNanoSeconds: number): string => {
    return new Date(epochInNanoSeconds / 1000000).toLocaleString();
};

export const epochInSecondsToLocaleString = (epochInSeconds: number): string => {
    return new Date(epochInSeconds * 1000).toLocaleString();
};

type sortArg<T> = keyof T | `-${string & keyof T}`;

/**
 * Returns a comparator for objects of type T that can be used by sort
 * functions, were T objects are compared by the specified T properties.
 *
 * @param sortBy - the names of the properties to sort by, in precedence order.
 *                 Prefix any name with `-` to sort it in descending order.
 */
export function byPropertiesOf<T extends object>(sortBy: Array<sortArg<T>>) {
    function compareByProperty(arg: sortArg<T>) {
        let key: keyof T;
        let sortOrder = 1;
        if (typeof arg === 'string' && arg.startsWith('-')) {
            sortOrder = -1;
            // Typescript is not yet smart enough to infer that substring is keyof T
            key = arg.substring(1) as keyof T;
        } else {
            // Likewise it is not yet smart enough to infer that arg is not keyof T
            key = arg as keyof T;
        }
        return function (a: T, b: T) {
            const result = a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;

            return result * sortOrder;
        };
    }

    return function (obj1: T, obj2: T) {
        let i = 0;
        let result = 0;
        const numberOfProperties = sortBy?.length;
        while (result === 0 && i < numberOfProperties) {
            result = compareByProperty(sortBy[i])(obj1, obj2);
            i++;
        }

        return result;
    };
}

export function sort<T extends object>(arr: T[], ...sortBy: Array<sortArg<T>>) {
    arr.sort(byPropertiesOf<T>(sortBy));
}

export const getEntityTypeFromLabel = (label: string): string => {
    switch (label) {
        case 'actor':
            return 'STATS_ENTITY_TYPE_ACTOR';
        case 'target':
            return 'STATS_ENTITY_TYPE_TARGET';
        case 'device':
            return 'STATS_ENTITY_TYPE_DEVICE';
        case 'identity':
            return 'STATS_ENTITY_TYPE_IDENTITY';
        case 'application':
            return 'STATS_ENTITY_TYPE_APPLICATION';
    }
    return 'STATS_ENTITY_TYPE_UNKNOWN';
};

export const getEntityType = (node: Node | undefined): string => {
    if (!node || !node.label) {
        return '';
    }
    return getEntityTypeFromLabel(node.label);
};

export const providerNameLookup = (provider: string): string => {
    switch (provider) {
        case 'azuread':
            return 'Azure AD';
        case 'okta':
            return 'Okta';
        case 'office365':
            return 'Office 365';
        case 'box':
            return 'Box';
        case 'sentinelone':
            return 'SentinelOne';
        case 'jamf':
            return 'Jamf';
        case 'aws':
            return 'AWS';
        case 'github':
            return 'GitHub';
        case 'aladdin':
            return 'Aladdin';
        default:
            return provider;
    }
};

export const providerProviderTypeLookup = (provider: string): string => {
    switch (provider) {
        case 'azuread':
            return 'PROVIDER_TYPE_AZUREAD';
        case 'okta':
            return 'PROVIDER_TYPE_OKTA';
        case 'office365':
            return 'PROVIDER_TYPE_OFFICE365';
        case 'box':
            return 'PROVIDER_TYPE_BOX';
        case 'sentinelone':
            return 'PROVIDER_TYPE_SENTINELONE';
        case 'jamf':
            return 'PROVIDER_TYPE_JAMF';
        case 'aws':
            return 'PROVIDER_TYPE_AWS';
        case 'github':
            return 'PROVIDER_TYPE_GITHUB';
        case 'aladdin':
            return 'PROVIDER_TYPE_ALADDIN';
        default:
            return 'PROVIDER_TYPE_UNKNOWN';
    }
};

export const getUsersTimezone = (): string => {
    // Return the users timezone in the format UTC+/-HH:MM
    const offset = new Date().getTimezoneOffset();
    const sign = offset > 0 ? '-' : '+';
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `UTC${sign}${hours}:${paddedMinutes}`;
};

export const compareNodesByDisplayName = (a: Node, b: Node): number => {
    if (a.computedName && b.computedName) {
        return a.computedName.localeCompare(b.computedName);
    } else if (a.props.displayName && b.props.displayName) {
        return a.props.displayName.localeCompare(b.props.displayName);
    }
    return 0;
};

const mfaDetails: Record<string, string> = {
    TAG_FACTOR_FIRST_FACTOR_CLAIM: 'First factor token claim',
    TAG_FACTOR_MFA_CLAIM_EXTERNAL: 'MFA external token claim',
    TAG_FACTOR_MFA_CLAIM_TOKEN: 'MFA token claim',
    TAG_FACTOR_PASSWORD: 'Password',
    TAG_FACTOR_MFA_CALL: 'Phone Call',
    TAG_FACTOR_MFA_EMAIL: 'Email',
    TAG_FACTOR_MFA_PUSH: 'Push',
    TAG_FACTOR_MFA_SMS: 'SMS',
    TAG_FACTOR_MFA_TOTP: 'TOTP',
};

export const mfaDetailLookup = (mfa: string): string => {
    return mfaDetails[mfa] || 'Unknown';
};

export const getMfaDetails = (link: Link): string[] => {
    const details = Object.keys(mfaDetails).map((key) => {
        if (link.props[key as keyof Link['props']]) {
            return mfaDetails[key];
        }
    });

    const prunedDetails = details.filter(Boolean) as string[];

    return prunedDetails;
};

const outcomeNames: Record<string, string> = {
    OUTCOME_SUCCESS: 'Success',
    OUTCOME_SKIPPED: 'Challenge',
    OUTCOME_CHALLENGE: 'Challenge',
    OUTCOME_FAILURE: 'Failure',
    OUTCOME_DENY: 'Failure',
    OUTCOME_UNKNOWN: 'Unknown',
};

export const outcomeLookup = (outcome: string): string => {
    return outcomeNames[outcome] || 'Unknown';
};

const alertLevelNames: Record<string, string> = {
    ALERT_WARNING: 'Warning',
    ALERT_ALERT: 'Alert',
};

export const alertLevelLookup = (alertLevel: string): string => {
    return alertLevelNames[alertLevel] || 'Unknown';
};

const wallTypeNames: Record<string, string> = {
    WallEventType_OTHER: 'Other',
    WallEventType_SESSION_START: 'Session Start',
    WallEventType_SESSION_END: 'Session End',
    WallEventType_LOGIN_FAILED: 'Login Failed',
    WallEventType_ACTOR_ACTIVITY_START: 'Actor Activity Start',
    WallEventType_ACTOR_ACTIVITY_PAUSED: 'Actor Activity Paused',
    WallEventType_ACTOR_CREATED: 'Actor Created',
    WallEventType_ACTOR_UPDATED: 'Actor Updated',
    WallEventType_ACTOR_ENABLED: 'Actor Enabled',
    WallEventType_ACTOR_DISABLED: 'Actor Disabled',
    WallEventType_AUTHENTICATOR_APP_REGISTERED: 'Authenticator App Registered',
    WallEventType_AUTHENTICATOR_APP_REGISTERING_STARTED: 'Authenticator App Registering Started',
};

export const wallTypeLookup = (wallType: string): string => {
    return wallTypeNames[wallType] || 'Unknown';
};
