import { Panel, PanelItem } from './Panel';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { GET_TENANT } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useState } from 'react';
import Select from 'react-select';
import { useClipboard } from 'use-clipboard-copy';
import { classNames } from 'Utilities/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';

export type SSOData = {
    signInURL: string;
    file: FileList;
    signOutURL?: string;
    userIdAttribute?: string;
    signRequestAlgorithm?: `RSA-SHA256` | `RSA-SHA1`;
    signRequestAlgorithmDigest?: `SHA256` | `SHA1`;
    protocolBinding?: `HTTP-Redirect` | `HTTP-POST`;
};

const SSOPanel = (): JSX.Element => {
    return (
        <Panel title="SSO Integration" subtitle="Connect to your SAML Identity Provider">
            <SSOForm />
        </Panel>
    );
};

export default SSOPanel;

const SupportedSSOProviders = [
    {
        value: 'okta',
        label: 'Okta',
    },
    {
        value: 'azuread',
        label: 'Azure AD',
    },
];

const DisplayTexts = {
    okta: {
        articleId: '644bc5320a02941ee486f4d2',
        spSignInURL: 'Single sign-on URL',
        idpSignInURL: 'Identity Provider Single Sign-On URL',
        audience: 'Audience URI (SP Entity ID)',
    },
    azuread: {
        articleId: '644bc5320a02941ee486f4d1',
        spSignInURL: 'Reply URL (Assertion Consumer Service URL)',
        idpSignInURL: 'Login URL',
        audience: 'Identifier (Entity ID)',
    },
};

export const SSOForm = (): JSX.Element => {
    const { ssoActivated } = useFlags();
    const tenantId = useTenant();
    const { loading, error, data } = useQuery(GET_TENANT, { variables: { tenantId } });

    const [selectedProvider, setSelectedProvider] = useState(SupportedSSOProviders[0]);

    const spSignInURLClipboard = useClipboard({
        copiedTimeout: 800,
    });
    const audienceClipboard = useClipboard({
        copiedTimeout: 800,
    });

    const { register, handleSubmit, formState } = useForm<SSOData>({ mode: 'onChange' });
    const submitDisabled = !formState.isDirty || !formState.isValid;

    const requestChanges = () => {
        window.Beacon('open');
        window.Beacon('navigate', '/ask/message/');
        window.Beacon('prefill', {
            subject: 'Single Sign On Changes',
            text: `# Please add supporting information for your request`,
        });
    };

    const onSubmit: SubmitHandler<SSOData> = async (data) => {
        const certText = await data.file[0].text();

        window.Beacon('open');
        window.Beacon('navigate', '/ask/message/');
        window.Beacon('prefill', {
            subject: 'Single Sign On Activation',
            text: `# Request for Single Sign On Activation

Click "Send a message" to submit

# Information for the support team

Sign in URL: 
${data.signInURL}

Certificate: 
${certText}`,
        });
    };

    if (ssoActivated) {
        return (
            <PanelItem title="Your Administrator has configured SSO for your Account">
                <p>If you would like to make changes, please contact support</p>
                <button onClick={requestChanges} className="btn mt-2">
                    Request Changes
                </button>
            </PanelItem>
        );
    }

    if (loading) {
        return <h4 className="text-xs text-gray-300">Loading SSO Configuration...</h4>;
    }
    if (error) {
        return <h4 className="text-xs text-red-500">Could not load SSO configuration. Please retry later.</h4>;
    }

    const { slug } = data.getTenant;
    const labels = DisplayTexts[selectedProvider.value as keyof typeof DisplayTexts];

    return (
        <div>
            <div className="mt-6">
                <h4 className="mt-4 font-bold text-xs ">Step 1. Choose SSO Provider</h4>
                <div>
                    <button
                        className="text-xs text-gray-400 hover:text-blue-600"
                        onClick={() => window.Beacon('article', labels.articleId, { type: 'sidebar' })}
                    >
                        Read the documentation on {selectedProvider.label} SSO configuration
                    </button>
                    <Select
                        classNamePrefix="select-xs"
                        className="w-28 mt-2"
                        styles={{
                            control: (provided) => ({
                                ...provided,
                                borderRadius: '0px !important',
                            }),
                        }}
                        isSearchable={false}
                        name="dataType"
                        options={SupportedSSOProviders}
                        value={selectedProvider}
                        onChange={(e) => {
                            if (e) {
                                setSelectedProvider(e);
                            }
                        }}
                    />
                </div>
            </div>
            <div className="mt-6">
                <h4 className="mt-4 font-bold text-xs ">
                    Step 2. Configure {selectedProvider.label} using these values
                </h4>
            </div>
            <div className="">
                <label className="text-xs">
                    <span className="w-40 text-right pr-3 pt-2 text-gray-400">{labels.spSignInURL}</span>
                    <div className="w-2/3 flex">
                        <input
                            type="url"
                            readOnly
                            className="input-gray text-xs flex-1 w-full rounded-none mt-1 select-all"
                            value={`https://login.doublezero.io/login/callback?connection=sso-${slug}`}
                            ref={spSignInURLClipboard.target}
                        />
                        <button
                            type="button"
                            onClick={spSignInURLClipboard.copy}
                            className={'btn bg-gray-600 hover:bg-gray-600/50 h-8.5 ml-2 mt-1 w-20'}
                        >
                            {spSignInURLClipboard.copied ? 'Copied!' : 'Copy'}
                        </button>
                    </div>
                </label>
                <label className="text-xs">
                    <span className="w-40 text-right pr-3 pt-2 text-gray-400">{labels.audience}</span>
                    <div className="w-2/3 flex">
                        <input
                            type="url"
                            readOnly
                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1 select-all"
                            value={`urn:auth0:00-prod:sso-${slug}`}
                            ref={audienceClipboard.target}
                        />
                        <button
                            type="button"
                            onClick={audienceClipboard.copy}
                            className={'btn bg-gray-600 hover:bg-gray-600/50 h-8.5 ml-2 mt-1 w-20'}
                        >
                            {audienceClipboard.copied ? 'Copied!' : 'Copy'}
                        </button>
                    </div>
                </label>
            </div>
            <div className="mt-6">
                <h4 className="font-bold text-xs">Step 3. Enter your values received from {selectedProvider.label}</h4>
            </div>
            <form className="" onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <label className="text-xs">
                        <span className="w-40 text-right pr-3 pt-2 text-gray-400">{labels.idpSignInURL}</span>
                        <input
                            type="url"
                            required
                            className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
                            {...register('signInURL', { required: true })}
                            placeholder="E.g. https://samlp.example.com/login"
                        />
                    </label>
                </div>
                <div className="mt-1">
                    <label className="text-xs">
                        <span className="w-40 text-right pr-3 text-gray-400">X509 Signing Certificate</span>
                        <span className="text-xs block text-gray-500 mb-2">
                            Must be a public key encoded in PEM or CER format
                        </span>
                        <div className="flex-1">
                            <input
                                type="file"
                                required
                                accept=".pem,.cert,.cer"
                                {...register('file', { required: true })}
                                className="mb-2 text-xs whitespace-nowrap w-2/3 blue-focus-1 ring-offset-1 ring-offset-blue-700"
                            />
                        </div>
                    </label>
                </div>

                <div className="mt-4">
                    <h4 className="font-bold text-xs">Step 4. Request SSO activation</h4>
                </div>
                <div className="mt-3">
                    <button
                        type="submit"
                        className={classNames('text-xs', submitDisabled ? 'btn-disabled' : 'btn')}
                        disabled={submitDisabled}
                    >
                        Request SSO Activation
                    </button>
                </div>
            </form>
            {formState.isSubmitSuccessful && (
                <div className="text-xs text-left w-full mt-3">
                    <span className="text-xs block text-green-500 mb-2">
                        Single-sign on draft ticket created, please submit the ticket to complete your request.
                    </span>
                </div>
            )}
        </div>
    );
};

//                 <div
//                     className="text-xs flex font-bold cursor-pointer items-center"
//                     onClick={() => {
//                         setAdvanced(!advanced);
//                     }}
//                 >
//                     {advanced ? (
//                         <ChevronDownIcon className="h-5 w-5 mr-1" />
//                     ) : (
//                         <ChevronRightIcon className="h-5 w-5 mr-1" />
//                     )}
//                     Show advanced options
//                 </div>
//                 {advanced && (
//                     <div className="space-y-6">
//                         <div>
//                             <label className="text-xs">
//                                 <span className="w-40 text-right pr-3 pt-2 font-bold">User ID Attribute</span>
//                                 <span className="text-xs block text-gray-500 mb-2 mt-1">
//                                     Optional: This is the attribute in the SAML token that will be mapped to the user_id
//                                     property.
//                                 </span>
//                                 <input
//                                     className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
//                                     {...register('userIdAttribute')}
//                                     placeholder="https://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
//                                 />
//                             </label>
//                         </div>
//                         <div>
//                             <div className="flex items-center">
//                                 <label className="text-xs flex items-center">
//                                     <div className="pr-3 font-bold w-24">Sign Request</div>
//                                     <input type="checkbox" className="chk-box text-xs mr-1" />
//                                 </label>
//                                 <span className="text-xs block text-gray-500 ml-3">
//                                     When enabled, the SAML authentication request will be signed. Download the
//                                     certificate and give it to SAMLP that will receive the signed assertion so it can
//                                     validate the signature.
//                                 </span>
//                             </div>
//                         </div>
//                         <div>
//                             <label className="text-xs">
//                                 <span className="w-40 text-right pr-3 pt-2 font-bold">Sign Request Algorithm</span>
//                                 <select
//                                     className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
//                                     {...register('signRequestAlgorithm')}
//                                 >
//                                     <option value="RSA-SHA256">RSA-SHA256</option>
//                                     <option value="RSA-SHA1">RSA-SHA1</option>
//                                 </select>
//                             </label>
//                         </div>
//                         <div>
//                             <label className="text-xs">
//                                 <span className="w-40 text-right pr-3 pt-2 font-bold">
//                                     Sign Request Algorithm Digest
//                                 </span>
//                                 <select
//                                     className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
//                                     {...register('signRequestAlgorithmDigest')}
//                                 >
//                                     <option value="SHA256">SHA256</option>
//                                     <option value="SHA1">SHA1</option>
//                                 </select>
//                             </label>
//                         </div>
//                         <div>
//                             <label className="text-xs">
//                                 <span className="w-40 text-right pr-3 pt-2 font-bold">
//                                     Service Provider X509 Signing Certificate
//                                 </span>
//                                 <div className="flex-1">
//                                     <button className="btn text-xs mt-2 whitespace-nowrap">Download</button>
//                                 </div>
//                             </label>
//                         </div>
//                         <div>
//                             <label className="text-xs">
//                                 <span className="w-40 text-right pr-3 pt-2 font-bold">Protocol Binding</span>
//                                 <span className="text-xs block text-gray-500 mb-2 mt-1">
//                                     Applies only to the SAML Request Binding. The SAML Response Binding only supports
//                                     HTTP-POST.
//                                 </span>
//                                 <div className="flex-1">
//                                     <select
//                                         className="input-gray text-xs flex-1 w-2/3 rounded-none mt-1"
//                                         {...register('protocolBinding')}
//                                     >
//                                         <option value="HTTP-Redirect">HTTP-Redirect</option>
//                                         <option value="HTTP-POST">HTTP-POST</option>
//                                     </select>
//                                 </div>
//                             </label>
//                         </div>
//                     </div>
//                 )}
//                 <hr />
