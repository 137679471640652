import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { PolicyRuleMock } from 'Types/types';
import { classNames } from 'Utilities/utils';
import { RuleCardMock } from './RuleCardMock';
import { Node } from 'Types/types';
import {
    FolderIcon,
    GlobeAltIcon,
    KeyIcon,
    LockClosedIcon,
    CheckCircleIcon,
    PauseIcon,
    NoSymbolIcon,
    MoonIcon,
    UserMinusIcon,
    ShieldCheckIcon,
    ArrowTopRightOnSquareIcon,
    CpuChipIcon,
    ClockIcon,
    BuildingStorefrontIcon,
    CheckBadgeIcon,
    CloudArrowDownIcon,
    BellAlertIcon,
    CubeTransparentIcon,
} from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';
import { PieChart, Pie, Cell, Label } from 'recharts';
import Select from 'react-select';
import { GET_ENTITIES_BY_TYPE_AS_NODES } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useQuery } from '@apollo/client';
import { faker } from '@faker-js/faker';

export interface PolicyRuleCategory {
    name: string;
    defaultOpen: boolean;
    children: Array<PolicyRuleMock>;
}

interface PolicyWindowProps {
    selectedTarget: Node | undefined;
    editorWindow: boolean;
    setEditorWindow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PolicyWindowMock = ({ selectedTarget, editorWindow, setEditorWindow }: PolicyWindowProps): JSX.Element => {
    return selectedTarget?.label == 'target' ? (
        <AdaptiveTrustPolicy
            selectedTarget={selectedTarget}
            editorWindow={editorWindow}
            setEditorWindow={setEditorWindow}
        />
    ) : selectedTarget?.label == 'mapping' ? (
        <TrustProfileMappings />
    ) : (
        <TrustProfile selectedTarget={selectedTarget} editorWindow={editorWindow} setEditorWindow={setEditorWindow} />
    );
};

const policyActions = [
    { value: 'allow', label: 'Allow' },
    { value: 'setup-up', label: 'Step Up' },
];
const policyState = [
    { value: 'notify', label: 'Notify' },
    { value: 'monitor', label: 'Monitor' },
    { value: 'disabled', label: 'Disabled' },
];
const trustProfiles = [
    { value: 'baseline', label: 'Baseline' },
    { value: 'administrators', label: 'Administrators' },
    { value: 'executives', label: 'Executives' },
    { value: 'usemployees', label: 'US Employees' },
    { value: 'contractors', label: 'Contractors' },
];

const weightedTrustProfiles = [
    { value: 'baseline', weight: 7 },
    { value: 'administrators', weight: 1 },
    { value: 'executives', weight: 1 },
    { value: 'usemployees', weight: 2 },
    { value: 'contractors', weight: 2 },
];

const TrustPolicyCard = ({
    trustScore,
    trustProfileIndex,
    policyActionIndex,
    policyStateIndex,
    pieColor = 'rgb(12 92 151)',
}: {
    trustScore: number;
    policyActionIndex: number;
    policyStateIndex: number;
    trustProfileIndex: number;
    pieColor?: string;
}) => {
    const pieSlices = [
        { value: trustScore, color: pieColor },
        { value: 100 - trustScore, color: 'rgb(31 41 55)' },
    ];

    return (
        <div className="w-full px-4 py-2 rounded-md bg-gray-700 my-4 flex">
            <div className="flex flex-col mr-4">
                <div className="flex -ml-1">
                    <PieChart width={100} height={100}>
                        <Pie
                            data={pieSlices}
                            dataKey="value"
                            innerRadius={'75%'}
                            outerRadius={'100%'}
                            paddingAngle={0}
                            stroke="none"
                            isAnimationActive={false}
                        >
                            {pieSlices.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                            <Label
                                value={pieSlices[0].value}
                                position="center"
                                fontSize={24}
                                fontWeight="bold"
                                fill="white"
                            />
                        </Pie>
                    </PieChart>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 w-full">
                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Trust Profile</p>
                        <div>
                            <Select
                                classNamePrefix="select-xs"
                                className="w-2/3 mt-2"
                                isSearchable={false}
                                name="dataType"
                                options={trustProfiles}
                                defaultValue={trustProfiles[trustProfileIndex]}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Action</p>
                        <div>
                            <Select
                                classNamePrefix="select-xs"
                                className="w-2/3 mt-2"
                                isSearchable={false}
                                name="dataType"
                                options={policyActions}
                                defaultValue={policyActions[policyActionIndex]}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Options</p>
                        <div>
                            <div className="bg-gray-600 rounded-md p-1.5 border border-gray-500 mt-2 w-3/4">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        className="chk-box text-blue-700 w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                        checked={true}
                                        disabled={true}
                                    />
                                    <p className="pl-2 text-xs text-gray-300">Consolidate Resources</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">State</p>
                        <div>
                            <Select
                                classNamePrefix="select-xs"
                                className="w-2/3 mt-2"
                                isSearchable={false}
                                name="dataType"
                                options={policyState}
                                defaultValue={policyState[policyStateIndex]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AdaptiveTrustPolicy = ({ selectedTarget }: PolicyWindowProps): JSX.Element => {
    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Adaptive Trust Policy</p>
                <div>
                    <button
                        type="button"
                        className="rounded-md border border-transparent shadow-sm px-3 py-2 bg-gray-700 text-xs font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:w-auto sm:text-xs"
                    >
                        Default Deny
                    </button>
                    <Menu as="div" className="relative inline-block text-left ml-2">
                        <div>
                            <Menu.Button className="inline-flex justify-center w-full rounded-md px-3 py-2 bg-blue-700 text-xs font-medium text-white hover:bg-blue-800 focus:outline-none">
                                Actions
                                <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <CheckCircleIcon
                                                    className="mr-3 h-5 w-5 text-green-400"
                                                    aria-hidden="true"
                                                />
                                                Publish
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <PauseIcon
                                                    className="mr-3 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                />
                                                Pause
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <NoSymbolIcon
                                                    className="mr-3 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                />
                                                Disable
                                            </a>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>

            <div className="pt-0 pb-4">
                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap text-yellow-400">
                    <div className="ml-4 mt-4">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <img
                                    className="h-12 w-12"
                                    src={selectedTarget && getIconSourceURL(getNodeIconElement(selectedTarget))}
                                    alt=""
                                />
                            </div>
                            <div className="ml-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-200">
                                    {selectedTarget?.props.displayName}
                                </h3>
                                <p className="text-xs text-gray-400">
                                    <a href="#">Manage the 1 policy for {selectedTarget?.props.displayName}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TrustPolicyCard trustScore={75} trustProfileIndex={0} policyActionIndex={0} policyStateIndex={1} />
            <TrustPolicyCard trustScore={75} trustProfileIndex={2} policyActionIndex={1} policyStateIndex={0} />
            <TrustPolicyCard
                trustScore={85}
                trustProfileIndex={2}
                policyActionIndex={0}
                policyStateIndex={1}
                pieColor="rgb(4 120 87)"
            />
            <TrustPolicyCard
                trustScore={95}
                trustProfileIndex={1}
                policyActionIndex={0}
                policyStateIndex={0}
                pieColor="rgb(218,165,32)"
            />

            <div className="p-4 rounded-md bg-gray-700 mt-4 text-gray-500 hover:text-gray-200 text-xs cursor-pointer font-semibold">
                <p className="">Add Another Policy +</p>
            </div>
        </div>
    );
};

const TrustProfile = ({ selectedTarget, editorWindow, setEditorWindow }: PolicyWindowProps): JSX.Element => {
    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Trust Profiles</p>
                <div>
                    <button
                        type="button"
                        className="rounded-md border border-transparent shadow-sm px-3 py-2 bg-gray-700 text-xs font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:w-auto sm:text-xs"
                        onClick={() => setEditorWindow(!editorWindow)}
                    >
                        Switch to Editor
                    </button>
                    <Menu as="div" className="relative inline-block text-left ml-2">
                        <div>
                            <Menu.Button className="inline-flex justify-center w-full rounded-md px-3 py-2 bg-blue-700 text-xs font-medium text-white hover:bg-blue-800 focus:outline-none">
                                Actions
                                <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                            </Menu.Button>
                        </div>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                <div className="py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <CheckCircleIcon
                                                    className="mr-3 h-5 w-5 text-green-400"
                                                    aria-hidden="true"
                                                />
                                                Publish
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <PauseIcon
                                                    className="mr-3 h-5 w-5 text-yellow-400"
                                                    aria-hidden="true"
                                                />
                                                Pause
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={classNames(
                                                    active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                    'group flex items-center px-4 py-2 text-xs',
                                                )}
                                            >
                                                <NoSymbolIcon
                                                    className="mr-3 h-5 w-5 text-red-400"
                                                    aria-hidden="true"
                                                />
                                                Disable
                                            </a>
                                        )}
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                </div>
            </div>

            <div className="pt-0 pb-4">
                <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
                    <div className="ml-4 mt-4">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                {/* <img
                                    className="h-12 w-12"
                                    src={selectedTarget && getIconSourceURL(getNodeIconElement(selectedTarget))}
                                    alt=""
                                /> */}
                            </div>
                            <div className="ml-1">
                                <h3 className="text-lg leading-6 font-medium text-gray-200">
                                    {selectedTarget?.props.displayName}
                                </h3>
                                <p className="text-xs text-gray-400">
                                    <a href="#">Manage the 15 policies for {selectedTarget?.props.displayName}</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                {policyRuleCategories.map((item: PolicyRuleCategory) => (
                    <Disclosure as="div" key={item.name} defaultOpen={item.defaultOpen} className="space-y-2 py-1">
                        {({ open }) => (
                            <>
                                <Disclosure.Button
                                    className={classNames(
                                        'bg-gray-700 text-white hover:bg-gray-700 hover:text-white',
                                        'group w-full flex items-center pl-1 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400',
                                    )}
                                    as="button"
                                >
                                    {open ? (
                                        <ChevronDownIcon width="18" height="18" className="mr-2" />
                                    ) : (
                                        <ChevronRightIcon width="18" height="18" className="mr-2" />
                                    )}
                                    {item.name}
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-1">
                                    {item.children.map(
                                        (rule: PolicyRuleMock) =>
                                            rule.icon && (
                                                <RuleCardMock
                                                    key={rule.name}
                                                    name={rule.name}
                                                    description={rule.description}
                                                    author={rule.author}
                                                    icon={rule.icon}
                                                    riskLevel={rule.riskLevel}
                                                />
                                            ),
                                    )}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </div>
        </div>
    );
};

const policyRuleCategories: PolicyRuleCategory[] = [
    {
        name: 'Identity Trust',
        defaultOpen: false,
        children: [
            {
                name: 'Employee Pending Termination',
                description: `The actor is pending termination from the organization`,
                selected: false,
                author: 'Double Zero Security - Data Source: Workday',
                riskLevel: 3,
                icon: <UserMinusIcon />,
            },
            {
                name: 'Employee On Leave',
                description: `The actor is on Leave or PTO from the organization`,
                selected: true,
                author: 'Double Zero Security - Data Source: Workday',
                riskLevel: 2,
                icon: <MoonIcon />,
            },
        ],
    },
    {
        name: 'Behavior Trust',
        defaultOpen: false,
        children: [
            {
                name: 'Failed Login Attempts',
                description: `Actor has had multiple failed login attempts in the last 24 hours`,
                selected: false,
                author: 'Double Zero Security - Data Source: MFA provider',
                riskLevel: 3,
                icon: <LockClosedIcon />,
            },
            {
                name: 'Multi-Factor Authentication Requirement',
                description: `Actor accessing resource without multi-factor authentication requirement`,
                selected: false,
                author: 'Double Zero Security - Data Source: MFA provider',
                riskLevel: 3,
                icon: <KeyIcon />,
            },
            {
                name: 'Multi-Factor Authentication Strength',
                description: `Actor is using weak or non-phishing-resistant MFA method`,
                selected: false,
                author: 'Double Zero Security - Data Source: MFA provider',
                riskLevel: 3,
                icon: <CheckBadgeIcon />,
            },
            {
                name: 'Multi-Factor Authentication Age',
                description: `Actor has not passed multi-factor authentication challenge for the past 6 hours`,
                selected: false,
                author: 'Double Zero Security - Data Source: MFA provider',
                riskLevel: 2,
                icon: <ClockIcon />,
            },
        ],
    },

    {
        name: 'Access Trust',
        defaultOpen: true,
        children: [
            {
                name: 'Connecting from an Anonymous IP',
                description: `The actor is connecting from an anonymous IP address, such as a VPN, proxy, or TOR`,
                author: 'Double Zero Security - Data Source: Identity Provider',
                selected: false,
                riskLevel: 3,
                icon: <CubeTransparentIcon />,
            },
            {
                name: 'Connecting from Non-Directory Location',
                description: `The actor is connecting from a location that does not match their directory location.`,
                author: 'Double Zero Security - data from Identity Provider, Concur, and Workday',
                selected: false,
                riskLevel: 4,
                icon: <GlobeAltIcon />,
            },
            {
                name: 'Connecting from a Public Location',
                description: `This actor is connecting from a public location, such as a coffee shop or airport`,
                selected: true,
                author: 'Double Zero Security - Data Source: Identity Provider',
                riskLevel: 2,
                icon: <BuildingStorefrontIcon />,
            },
            {
                name: 'Connecting from Business Travel Location',
                description: `The actor is connecting from expected business travel location`,
                selected: false,
                author: 'Double Zero Security - Data Source: Identity Provider and Concur',
                riskLevel: 1,
                icon: <ArrowTopRightOnSquareIcon />,
            },
            {
                name: 'Connecting from an Approved Remote Location',
                description: `The actor is working from an approved non-company location such as home office`,
                selected: false,
                author: 'Double Zero Security - Data Source: Identity Provider and Workday',
                riskLevel: 1,
                icon: <ShieldCheckIcon />,
            },
        ],
    },
    {
        name: 'Device Trust',
        defaultOpen: false,
        children: [
            {
                name: 'Device Compromise',
                description: `The device reported as compromised by endpoint security`,
                selected: true,
                author: 'Double Zero Security - Data Source: CrowdStrike',
                riskLevel: 3,
                icon: <BellAlertIcon />,
            },
            {
                name: 'Device Trust',
                description: `The device trust level reported below acceptable threshold`,
                selected: true,
                author: 'Double Zero Security - Data Source: SentinelOne',
                riskLevel: 2,
                icon: <CheckIcon />,
            },
            {
                name: 'Device Management',
                description: `The device is not managed by UEM/MDM`,
                selected: true,
                author: 'Double Zero Security - Data Source: Jamf, Intune',
                riskLevel: 2,
                icon: <CloudArrowDownIcon />,
            },
            {
                name: 'Device TPM',
                description: `The device does not have a verified TPM enabled`,
                selected: false,
                author: 'Double Zero Security - Data Source: Jamf, Intune',
                riskLevel: 2,
                icon: <CpuChipIcon />,
            },
            {
                name: 'Disk Encryption',
                description: `Device disk encryption not enabled`,
                selected: false,
                author: 'Double Zero Security - Data Source: Jamf, InTune',
                riskLevel: 2,
                icon: <FolderIcon />,
            },
        ],
    },
];

const now = new Date().getTime();

export const TrustProfileMappings = (): JSX.Element => {
    const tenantId = useTenant();

    const {
        loading: loadingGroups,
        data: dataGroups,
        error: errorGroups,
    } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_GROUP',
            permissionsOnly: true,
            dateInMs: now,
        },
    });

    const groups = dataGroups?.getEntitiesByTypeAsNodes.nodes;

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Trust Profile Mappings</p>
            </div>
            <div className="w-full px-4 py-4 rounded-md bg-gray-700 mt-2 space-y-1 text-xs">
                {loadingGroups && <div>Loading...</div>}
                {errorGroups && <div>Error...</div>}
                {groups &&
                    groups.map((group: any) => {
                        faker.seed(group.props.displayName);
                        const trustProfile = faker.helpers.weightedArrayElement(weightedTrustProfiles);
                        const trustProfileIndex = weightedTrustProfiles.findIndex((item) => item.value == trustProfile);
                        return (
                            <div className="flex items-center justify-between text-gray-200 text-xs hover:bg-gray-800 rounded-md px-2 py-1">
                                <div>{group.props.displayName}</div>
                                <div className="w-1/4">
                                    <Select
                                        classNamePrefix="select-xs"
                                        className="w-full"
                                        isSearchable={false}
                                        name="dataType"
                                        options={trustProfiles}
                                        defaultValue={trustProfiles[trustProfileIndex]}
                                    />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );

    return <div>No groups...</div>;
};
