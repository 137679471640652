import { getEnvironment } from 'Hooks/Hooks';
import { useAuth } from 'Hooks/Auth';
import { useCurrentUser } from 'Hooks/User';
import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CubeTransparentIcon } from '@heroicons/react/24/solid';
import { datadogRum, RumFetchResourceEventDomainContext } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const env = getEnvironment();

datadogRum.init({
    applicationId: window.__env__.DD_APPLICATION_ID,
    clientToken: window.__env__.DD_CLIENT_TOKEN,
    site: window.__env__.DD_SITE,
    service: 'fabric-ui',
    env: env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: false,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [{ match: /http(s?):\/\/graph\..*\/query/, propagatorTypes: ['datadog', 'tracecontext'] }],
    beforeSend(event, context: RumFetchResourceEventDomainContext) {
        // Annotate the GraphQL name, query, variables, and headers to datadog events
        if (event.type === 'resource' && event.resource.url.includes('/query')) {
            if (context.requestInit) {
                if (context.requestInit.body !== undefined && context.requestInit.body !== null) {
                    // Parse the body to get the operation name, query, and variables
                    const body = JSON.parse(context.requestInit.body as string);
                    const operationName = body.operationName;
                    const query = body.query;
                    const variables = body.variables;

                    // Convert the headers array of tuples into an object
                    const headers: Record<string, string> = {};

                    (context.requestInit.headers as unknown as [string, string][]).forEach((h: [string, string]) => {
                        headers[h[0]] = h[1];
                    });

                    // Add the operation name to the URL
                    event.resource.url = event.resource.url.replace(/query/, `query=${operationName}`);

                    // Add the query, variables, and headers to the event context
                    event.context = {
                        ...event.context,
                        query: query,
                        variables: variables,
                        requestHeaders: headers,
                    };
                }
            }
        }
        return true;
    },
});

datadogLogs.init({
    clientToken: window.__env__.DD_CLIENT_TOKEN,
    site: window.__env__.DD_SITE,
    service: 'fabric-ui',
    env: env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
});

datadogRum.startSessionReplayRecording();

type MonitoringAndFlagsProps = {
    children: JSX.Element;
};

export const MonitoringAndFlags = ({ children }: MonitoringAndFlagsProps) => {
    const { userId, tenantId, email } = useAuth();
    const { enableMaintenanceMode } = useFlags();
    // name will be undefined until we receive the user's name from the server
    // if for some reason the backend is down or encounters an error (most common in dev)
    // name will be "null"
    const { name } = useCurrentUser();

    useEffect(() => {
        if (userId && email && name !== undefined) {
            const resolvedTenantId = tenantId || 'User tenantId missing';
            const resolvedName = name || 'User name missing';
            console.log('Logged in user is', resolvedName, email, resolvedTenantId);

            if (datadogRum) {
                console.log(`Identifying user ${userId} to Datadog`);
                datadogRum.setUser({
                    id: userId,
                    name: resolvedName,
                    email: email,
                    tenantId: tenantId,
                });
            }
        }
    }, [userId, tenantId, name, email]);
    return <>{enableMaintenanceMode ? <MaintenanceOverlay /> : children}</>;
};

const MaintenanceOverlay = (): JSX.Element => {
    const { enableMaintenanceMode } = useFlags();
    return (
        enableMaintenanceMode && (
            <div className="relative flex items-center justify-center">
                <div className="w-screen h-screen absolute top-0 left-0 flex items-center justify-center bg-gray-900 z-10"></div>
                <div className="flex flex-col place-content-center text-center z-20 h-screen">
                    <div className="relative mb-5 h-8 w-8 ml-auto mr-auto">
                        <CubeTransparentIcon className="animate-[ping_2s_ease-in-out_infinite] h-7 w-7 text-blue-900 absolute top-0.5 left-0.5" />
                        <CubeTransparentIcon className="animate-[pulse_1s_ease-in-out_infinite] h-8 w-8 text-blue-700 absolute top-0 left-0" />
                    </div>
                    <h4 className="text-gray-200 font-semibold tracking-widest text-base mb-3">
                        Currently Under Maintenance
                    </h4>
                    <p className="text-gray-500 text-xs mb-3">
                        Double Zero Security is getting some upgrades - please come back later.
                    </p>
                    <p className="text-gray-500 text-xs">
                        If you have any other issues, please email our team at
                        <br />
                        support@doublezero.io
                    </p>
                </div>
            </div>
        )
    );
};
