import { Node } from 'Types/types';

import CodeMirror from '@uiw/react-codemirror';

import './00-theme.css';
import 'codemirror/addon/comment/comment';
import 'codemirror/addon/edit/matchbrackets';

import 'codemirror-rego/key-map'; // rego key map
import 'codemirror-rego/mode'; // rego syntax highlighting

interface EditorWindowProps {
    selectedTarget: Node | undefined;
}

export const EditorWindowMock = ({ selectedTarget }: EditorWindowProps): JSX.Element => {
    const editorValue = createSamplePolicy(selectedTarget?.props.displayName);

    return (
        <div className="mt-16">
            <CodeMirror
                value={editorValue}
                options={{
                    theme: '00-theme',
                    mode: 'rego',
                    keyMap: 'styra',
                    tabSize: 2,
                }}
            />
        </div>
    );
};

const createSamplePolicy = (name?: string): string => {
    const policy = `#### Resource Access Policy

#### This is a a sample policy that can be adapted to your needs

#### Further documentation available at http://doublezero.io/docs/policy-editor/

const target = "${name}"

${SAMPLE_POLICY_TEXT}
    `;

    return policy;
};

const SAMPLE_POLICY_TEXT = `import input.attributes.request.http

default allow = false

allow {
    is_token_valid
    action_allowed
}

is_token_valid {
    token.valid
    now := time.now_ns() / 1000000000
    token.payload.nbf <= now
    now < token.payload.exp
}

action_allowed {
    http.method == "GET"
    token.payload.role == "guest"
    glob.match("/people/*", ["/"], http.path)
}`;
