import EntryPage from 'Onboarding/ExternalPage';
import { useState } from 'react';
import CreateProviders from './CreateProviders';
import CreateUsers from './CreateUsers';
import Intro from './Intro';
import Review from './Review';
import { Helmet } from 'react-helmet-async';

export enum WelcomeStep {
    Intro,
    CreateProviders,
    CreateUsers,
    Review,
}

const Welcome = (): JSX.Element => {
    const [step, setStep] = useState(WelcomeStep.Intro);
    return (
        <EntryPage>
            <Helmet>
                <title>Welcome to Double Zero</title>
            </Helmet>
            <div className="overflow-y-scroll">
                {step === WelcomeStep.Intro && <Intro nextStep={() => setStep(WelcomeStep.CreateProviders)} />}
                {step === WelcomeStep.CreateProviders && (
                    <CreateProviders
                        nextStep={() => setStep(WelcomeStep.CreateUsers)}
                        prevStep={() => setStep(WelcomeStep.Intro)}
                    />
                )}
                {step === WelcomeStep.CreateUsers && (
                    <CreateUsers
                        nextStep={() => setStep(WelcomeStep.Review)}
                        prevStep={() => setStep(WelcomeStep.CreateProviders)}
                    />
                )}
                {step === WelcomeStep.Review && <Review prevStep={() => setStep(WelcomeStep.CreateUsers)} />}
            </div>
        </EntryPage>
    );
};
export default Welcome;
