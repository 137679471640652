import { gql } from '@apollo/client';

export const CREATE_PROVIDER = gql`
    mutation createProvider($input: ProviderInput!) {
        createProvider(input: $input) {
            authHeader
            url
            provider {
                providerId
                type
                name
            }
        }
    }
`;

export const UPDATE_PROVIDER = gql`
    mutation updateProvider($providerId: ID!, $input: ProviderInput!) {
        updateProvider(providerId: $providerId, input: $input) {
            providerId
            type
            name
        }
    }
`;

export const DELETE_PROVIDER = gql`
    mutation deleteProvider($tenantId: ID!, $providerId: ID!) {
        deleteProvider(tenantId: $tenantId, providerId: $providerId)
    }
`;

export const LIST_PROVIDERS = gql`
    query listProviders($tenantId: ID!) {
        listProviders(tenantId: $tenantId) {
            providerId
            type
            name
            status
            firstEventAt
            lastEventAt
            storageUsed
            lastError
            lastErrorAt
        }
    }
`;

export const GET_AUTH_FIELDS_PROVIDER = gql`
    query getAuthFieldsProvider($tenantId: ID!, $providerId: ID!) {
        getAuthFieldsProvider(tenantId: $tenantId, providerId: $providerId) {
            Name
            DisplayName
            HelpURI
            Fields {
                Name
                DisplayName
                InputName
                Generated
                Mandatory
                Output
                RegexValidation
                Enum {
                    key
                    value
                }
                Value
            }
            Hook
            HookAuthorizationKey
        }
    }
`;

export const LIST_TENANTS = gql`
    query listTenants {
        listTenants {
            tenantId
        }
    }
`;

export const GET_TENANT = gql`
    query getTenant($tenantId: ID!) {
        getTenant(tenantId: $tenantId) {
            tenantId
            name
            status
            slug
            firstEventAt
            lastEventAt
            storageUsed
        }
    }
`;

export const ONBOARD_TENANT = gql`
    mutation onboardTenant($input: OnboardInput!) {
        onboardTenant(input: $input) {
            orgId
            tenantId
            result
        }
    }
`;

export const GET_IDENTITY_MAP = gql`
    query getIdentityMap(
        $tenantId: ID!
        $queryId: String
        $startTime: Int64!
        $endTime: Int64!
        $loadAttributes: Boolean
        $aggregated: Boolean
        $edgesOnly: Boolean
        $filter: Filter
    ) {
        getIdentityMap(
            tenantId: $tenantId
            queryId: $queryId
            startTime: $startTime
            endTime: $endTime
            maxLevelDepth: 1
            loadAttributes: $loadAttributes
            aggregated: $aggregated
            edgesOnly: $edgesOnly
            filter: $filter
        ) {
            queryId
            isLoading
            nodes {
                nodeId
                nodeType
                label
                props
                tags
                attributes {
                    updatedAt
                    key
                    value
                }
                groupingData
                currentLevelTrail
                hasNextLevel
                nextLevelBase64
            }
            edges {
                edgeId
                label
                inV
                outV
                tags
                props
            }
        }
    }
`;

export const GET_EXPANDED_IDENTITY_MAP = gql`
    query getExpandedIdentityMap(
        $tenantId: ID!
        $startTime: Int64!
        $endTime: Int64!
        $levelTrails: [String!]!
        $loadAttributes: Boolean
        $filter: Filter
    ) {
        getExpandedGrouping(
            tenantId: $tenantId
            startTime: $startTime
            endTime: $endTime
            levelTrails: $levelTrails
            loadAttributes: $loadAttributes
            filter: $filter
        ) {
            nodes {
                nodeId
                nodeType
                label
                props
                tags
                attributes {
                    updatedAt
                    key
                    value
                }
                groupingData
                currentLevelTrail
                hasNextLevel
                nextLevelBase64
            }
            edges {
                edgeId
                label
                inV
                outV
                tags
                props
            }
        }
    }
`;

export const CREATE_USER = gql`
    mutation createUser($input: UserInput!) {
        createUser(input: $input) {
            name
            email
            userId
            roleIds
        }
    }
`;

export const GET_USER = gql`
    query getUser($tenantId: ID!, $userId: ID!) {
        getUser(tenantId: $tenantId, userId: $userId) {
            userId
            tenantId
            email
            name
            roleIds
        }
    }
`;

export const LIST_USERS = gql`
    query listUsers($tenantId: ID!) {
        listUsers(tenantId: $tenantId) {
            userId
            name
            email
            roleIds
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($tenantId: ID!, $userId: ID!) {
        deleteUser(tenantId: $tenantId, userId: $userId)
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser($tenantId: ID!, $userId: ID!, $userData: UserUpdateInput) {
        updateUser(tenantId: $tenantId, userId: $userId, userData: $userData)
    }
`;

export const LIST_AGENTS = gql`
    query listAgents($tenantId: ID!) {
        listAgents(tenantId: $tenantId) {
            agentId
            tenantId
            type
            os
            arch
            hostname
            version
            lastLocation {
                latitude
                longitude
            }
            status
        }
    }
`;

export const DELETE_AGENT = gql`
    mutation deleteAgent($tenantId: ID!, $agentId: ID!) {
        deleteAgent(tenantId: $tenantId, agentId: $agentId)
    }
`;

export const GET_AGENT_DOWNLOAD_URL = gql`
    query getAgentDownloadUrl($arch: AgentArch!, $os: AgentOS!) {
        getAgentDownloadUrl(arch: $arch, os: $os)
    }
`;

export const ZENDESK_USER = gql`
    query getZendeskJWT($tenantId: ID!, $userId: ID!) {
        getZendeskJWT(tenantId: $tenantId, userId: $userId) {
            jwtToken
        }
    }
`;

export const GET_PROVIDER_FIELD_MAP = gql`
    query getProviderFieldMap($tenantId: ID!) {
        getProviderFieldMap(tenantId: $tenantId) {
            Name
            DisplayName
            Fields {
                Name
                DisplayName
                InputName
                Generated
                Mandatory
                Output
                RegexValidation
                Enum {
                    key
                    value
                }
            }
            Hook
            HookAuthorizationKey
        }
    }
`;

export const LIST_ROLES = gql`
    query listRoles($tenantId: ID!) {
        listRoles(tenantId: $tenantId) {
            roleId
            tenantId
            name
        }
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($tenantId: ID!, $userId: ID!) {
        generatePasswordReset(tenantId: $tenantId, userId: $userId)
    }
`;

export const LIST_API_KEYS = gql`
    query listApiKeys($tenantId: ID!) {
        listApiKeys(tenantId: $tenantId) {
            apiKeyId
            tenantId
            createdByUserId
            name
            clientKey
            createdAt
            updatedAt
        }
    }
`;

export const DELETE_API_KEY = gql`
    mutation deleteApiKey($tenantId: ID!, $apiKeyId: ID!) {
        deleteApiKey(tenantId: $tenantId, apiKeyId: $apiKeyId)
    }
`;

export const CREATE_API_KEY = gql`
    mutation createApiKey($tenantId: ID!, $apiKeyName: String!) {
        createApiKey(tenantId: $tenantId, apiKeyName: $apiKeyName) {
            clientKey
            clientSecret
            name
            apiKeyId
            tenantId
            createdByUserId
            createdAt
            updatedAt
        }
    }
`;

export const GET_API_KEY = gql`
    query getApiKey($tenantId: ID!, $apiKeyId: ID!) {
        getApiKey(tenantId: $tenantId, apiKeyId: $apiKeyId) {
            apiKeyId
            tenantId
            createdByUserId
            name
            clientKey
            createdAt
            updatedAt
        }
    }
`;

export const LIST_POLICIES = gql`
    query listPolicies($tenantId: ID!) {
        listPolicies(tenantId: $tenantId) {
            policyId
            tenantId
            name
            description
            policyDocument
            status
            requestedState
            version
            originVersion
            signature
            createdAt
        }
    }
`;

export const GET_POLICY = gql`
    query getPolicy($tenantId: ID!, $policyId: ID!, $version: Int64) {
        getPolicy(tenantId: $tenantId, policyId: $policyId, version: $version) {
            policyId
            tenantId
            name
            description
            policyDocument
            status
            requestedState
            version
            originVersion
            signature
            createdAt
        }
    }
`;

export const STAGE_POLICY_DOCUMENT = gql`
    mutation stagePolicyDocument(
        $tenantId: ID!
        $policyDocument: String!
        $name: String
        $description: String
        $validateOnly: Boolean
    ) {
        stagePolicyDocument(
            tenantId: $tenantId
            policyDocument: $policyDocument
            name: $name
            description: $description
            validateOnly: $validateOnly
        ) {
            policyId
            tenantId
            policy {
                policyId
                tenantId
                name
                description
                status
                policyDocument
                requestedState
                version
                originVersion
                signature
                createdAt
            }
            status
            errors {
                code
                message
            }
        }
    }
`;

export const PUBLISH_POLICY = gql`
    mutation publishPolicy($tenantId: ID!, $policyId: String!, $version: Int64, $action: PolicyAction) {
        publishPolicy(tenantId: $tenantId, policyId: $policyId, version: $version, action: $action) {
            policyId
            tenantId
            status
            errors {
                code
                message
            }
        }
    }
`;

export const DELETE_POLICY = gql`
    mutation deletePolicy($tenantId: ID!, $policyId: ID!, $version: Int64) {
        deletePolicy(tenantId: $tenantId, policyId: $policyId, version: $version)
    }
`;

export const UPDATE_POLICY = gql`
    mutation updatePolicy($tenantId: ID!, $policyUpdates: UpdatePolicyFieldsInput!) {
        updatePolicy(tenantId: $tenantId, policyUpdates: $policyUpdates) {
            policyId
            tenantId
            name
            description
            policyDocument
            status
            requestedState
            version
            originVersion
            signature
            createdAt
        }
    }
`;

export const GET_ENTITY_EVENT_HISTORY = gql`
    query getEntityEventHistory(
        $tenantId: ID!
        $entityId: ID!
        $entityType: StatsEntityType!
        $unit: StatsUnits!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityEventHistory(
            entity: $entityType
            tenantId: $tenantId
            entityId: $entityId
            unit: $unit
            startDate: $startDate
            endDate: $endDate
        ) {
            items {
                date
                successCount
                denyCount
                otherCount
                challengeCount
                riskCount
                trustScore
            }
        }
    }
`;

export const GET_ENTITY_ACCESSORS = gql`
    query getEntityAccessors(
        $tenantId: ID!
        $entityId: ID!
        $entityType: StatsEntityType!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityAccessors(
            entity: $entityType
            tenantId: $tenantId
            entityId: $entityId
            startDate: $startDate
            endDate: $endDate
        ) {
            actorsCount
            targetsCount
            applicationsCount
            identitiesCount
            devicesCount
            highRiskActorsCount
            highRiskApplicationsCount
            highRiskDevicesCount
            highRiskIdentitiesCount
            highRiskTargetsCount
            identities {
                id
                risk
                count
            }
            devices {
                id
                risk
                count
            }
            applications {
                id
                risk
                count
            }
            actors {
                id
                risk
                count
            }
            targets {
                id
                risk
                count
            }
        }
    }
`;

export const GET_ENTITY_EVENTS_SUMMARY = gql`
    query getEntityEventsSummary(
        $tenantId: ID!
        $entityId: ID!
        $entityType: StatsEntityType!
        $targetType: StatsEntityType!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityEventsSummary(
            entity: $entityType
            tenantId: $tenantId
            entityId: $entityId
            entityTarget: $targetType
            startDate: $startDate
            endDate: $endDate
        ) {
            items {
                summaryId
                entityTargetId
                count
                status
                risk
            }
        }
    }
`;

export const GET_ENTITY_EVENTS_SUMMARY_CODES = gql`
    query getEntityEventsSummaryCodes($summaryId: String!, $tenantId: ID!, $entityType: StatsEntityType!) {
        getEntityEventsSummaryCodes(summaryId: $summaryId, entity: $entityType, tenantId: $tenantId) {
            items {
                count
                risk
                reason
                codeId
                summaryId
            }
        }
    }
`;

export const GET_ENTITY_EVENTS_SUMMARY_CODES_LIST = gql`
    query getEntityEventsSummaryCodesList($codeId: String!, $tenantId: ID!, $entityType: StatsEntityType!) {
        getEntityEventsSummaryCodesList(codeId: $codeId, entity: $entityType, tenantId: $tenantId) {
            items {
                accesses
                deviceId
                applicationId
                location {
                    city
                    state
                    postalCode
                    geolocation {
                        latitude
                        longitude
                    }
                }
                mfa
                date
                status
                risk
                provider
            }
        }
    }
`;

export const GET_GLOBAL_EVENTS_HISTORY = gql`
    query getEntityHistory(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $unit: StatsUnits!
        $startDate: Int64!
        $endDate: Int64!
        $detailed: Boolean!
    ) {
        getEntityHistory(
            entity: $entityType
            tenantId: $tenantId
            unit: $unit
            startDate: $startDate
            endDate: $endDate
            detailed: $detailed
        ) {
            items {
                date
                successCount
                denyCount
                otherCount
                challengeCount
                riskCount
                entityCount
                trustScore
            }
        }
    }
`;

export const GET_ENTITY_RISKY = gql`
    query getEntityRisky(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $limit: Int!
        $order: StatsOrder!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityRisky(
            entity: $entityType
            tenantId: $tenantId
            limit: $limit
            startDate: $startDate
            order: $order
            endDate: $endDate
        ) {
            items {
                id
                riskyCount
            }
        }
    }
`;

export const GET_ENTITY_DORMANT = gql`
    query getEntityDormant(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $limit: Int!
        $endDate: Int64!
        $order: StatsOrder!
        $thresholdInMinutes: Int64!
    ) {
        getEntityDormant(
            entity: $entityType
            tenantId: $tenantId
            limit: $limit
            endDate: $endDate
            thresholdInMinutes: $thresholdInMinutes
            order: $order
        ) {
            items {
                lastAccessDate
                dormantTime
                id
            }
        }
    }
`;

export const GET_ENTITY_LAST_CREATED = gql`
    query getEntityLastCreated(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $limit: Int!
        $endDate: Int64!
        $order: StatsOrder!
    ) {
        getEntityLastCreated(
            entity: $entityType
            tenantId: $tenantId
            limit: $limit
            endDate: $endDate
            order: $order
        ) {
            items {
                id
                creationDate
            }
        }
    }
`;

export const GET_ENTITY_RETURN_TO_LIFE = gql`
    query getEntityReturnToLife(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $limit: Int!
        $startDate: Int64!
        $endDate: Int64!
        $order: StatsOrder!
        $thresholdInMinutes: Int64!
    ) {
        getEntityReturnToLife(
            entity: $entityType
            tenantId: $tenantId
            limit: $limit
            startDate: $startDate
            endDate: $endDate
            order: $order
            thresholdInMinutes: $thresholdInMinutes
        ) {
            items {
                lastAccessDate
                dormantTime
                returnedToLife
                id
            }
        }
    }
`;

export const GET_ENTITY_LIST = gql`
    query getEntityList(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $limit: Int32!
        $order: StatsOrder!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityList(
            entity: $entityType
            tenantId: $tenantId
            limit: $limit
            startDate: $startDate
            order: $order
            endDate: $endDate
        ) {
            items {
                id
                accessCount
            }
        }
    }
`;

export const GET_ENTITY_BY_STATUS = gql`
    query getEntityByStatus(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $status: AccessOutcomeType!
        $limit: Int!
        $order: StatsOrder!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityByStatus(
            entity: $entityType
            tenantId: $tenantId
            status: $status
            limit: $limit
            startDate: $startDate
            order: $order
            endDate: $endDate
        ) {
            items {
                id
                count
                totalCount
                rate
            }
        }
    }
`;

export const GET_STATUS_REASONS = gql`
    query getStatusReasons(
        $tenantId: ID!
        $status: AccessOutcomeType!
        $limit: Int!
        $order: StatsOrder!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getStatusReasons(
            tenantId: $tenantId
            status: $status
            limit: $limit
            order: $order
            startDate: $startDate
            endDate: $endDate
        ) {
            items {
                reason
                count
            }
        }
    }
`;

export const GET_ENTITY_LOCATIONS = gql`
    query getEntityLocations(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $unit: StatsUnits!
        $limit: Int!
        $order: StatsOrder!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityLocations(
            tenantId: $tenantId
            entity: $entityType
            unit: $unit
            limit: $limit
            order: $order
            startDate: $startDate
            endDate: $endDate
        ) {
            items {
                id
                date
                locations {
                    city
                    state
                    postalCode
                    geolocation {
                        latitude
                        longitude
                    }
                }
            }
        }
    }
`;

export const GET_ENTITY_BY_NEIGHBOR_COUNT = gql`
    query getEntityByNeighborCount(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $neighborType: StatsEntityType!
        $limit: Int!
        $order: StatsOrder!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityByNeighborCount(
            tenantId: $tenantId
            entity: $entityType
            neighborType: $neighborType
            limit: $limit
            order: $order
            startDate: $startDate
            endDate: $endDate
        ) {
            items {
                id
                neighborCount
            }
        }
    }
`;

export const GET_ENTITY_COUNT_HISTORY = gql`
    query getEntityCountHistory(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $tags: [IdentityTags]!
        $unit: StatsUnits!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getEntityCountHistory(
            tenantId: $tenantId
            entity: $entityType
            tags: $tags
            unit: $unit
            startDate: $startDate
            endDate: $endDate
        ) {
            items {
                date
                tags {
                    tag
                    count
                    list
                }
            }
        }
    }
`;

export const GET_LOCATION_HISTORY = gql`
    query getLocationHistory(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $groupingLevel: String!
        $unit: StatsUnits!
        $startDate: Int64!
        $endDate: Int64!
    ) {
        getLocationHistory(
            entity: $entityType
            tenantId: $tenantId
            groupingLevel: $groupingLevel
            unit: $unit
            startDate: $startDate
            endDate: $endDate
        ) {
            items {
                date
                locations
                successCount
                challengeCount
                denyCount
                otherCount
                riskCount
                list
            }
        }
    }
`;

export const GET_ENTITIES_AS_NODES = gql`
    query getEntitiesAsNodes($tenantId: ID!, $entityType: StatsEntityType!, $entityIds: [String!]) {
        getEntitiesAsNodes(tenantId: $tenantId, entityType: $entityType, entityIds: $entityIds) {
            nodeId
            nodeType
            label
            props
            tags
            attributes {
                updatedAt
                key
                value
            }
            groupingData
            currentLevelTrail
            hasNextLevel
            nextLevelBase64
        }
    }
`;

export const GET_ENTITY_ATTRIBUTES = gql`
    query getEntityAttributes(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $entityId: String!
        $startTimeInMs: Int64!
        $endTimeInMs: Int64!
    ) {
        getEntityAttributes(
            tenantId: $tenantId
            entityType: $entityType
            entityId: $entityId
            startTimeInMs: $startTimeInMs
            endTimeInMs: $endTimeInMs
        ) {
            updatedAt
            key
            value
        }
    }
`;

export const SEARCH_ATTRIBUTES = gql`
    query searchAttributes(
        $entity: StatsEntityType!
        $tenantId: ID!
        $startDate: Int64!
        $endDate: Int64!
        $sortField: String!
        $search: String!
    ) {
        searchAttributes(
            entity: $entity
            tenantId: $tenantId
            startDate: $startDate
            endDate: $endDate
            sortField: $sortField
            search: $search
        ) {
            items {
                id
                displayName
                email
            }
        }
    }
`;

export const GET_PERMISSIONS_MAP = gql`
    query getPermissionsMap($tenantId: ID!, $date: Int64!, $entryEntityId: ID, $exitEntityId: ID, $limit: Int64) {
        getPermissionsMap(
            tenantId: $tenantId
            date: $date
            entryEntityId: $entryEntityId
            exitEntityId: $exitEntityId
            limit: $limit
        ) {
            permissions {
                label
                inV
                outV
                edgeId
                tenantId
                label
                props
                tags
            }
        }
    }
`;

export const GET_ENTITIES_BY_TYPE_AS_NODES = gql`
    query getEntitiesByTypeAsNodes(
        $tenantId: ID!
        $entityType: StatsEntityType!
        $permissionsOnly: Boolean
        $dateInMs: Int64
    ) {
        getEntitiesByTypeAsNodes(
            entityType: $entityType
            tenantId: $tenantId
            permissionsOnly: $permissionsOnly
            dateInMs: $dateInMs
        ) {
            nodes {
                nodeId
                label
                tags
                props
                nodeType
            }
            cursor
        }
    }
`;

export const GET_HELPSCOUT_TOKEN = gql`
    query getHelpscoutToken($tenantId: ID!, $userId: ID!) {
        getHelpscoutToken(tenantId: $tenantId, userId: $userId) {
            token
        }
    }
`;

export const GET_LOCATION_GRID = gql`
    query getLocationGrid($tenantId: ID!, $startTimeInMs: Int64!, $endTimeInMs: Int64!, $filter: Filter) {
        getLocationGrid(
            tenantId: $tenantId
            startTimeInMs: $startTimeInMs
            endTimeInMs: $endTimeInMs
            filter: $filter
        ) {
            grid {
                Cols
            }
            gridLevel
            columns
            rows
            xValues
            yValues
        }
    }
`;

export const GET_LOCATION_GRID_X_Y = gql`
    query getLocationGrid(
        $tenantId: ID!
        $startTimeInMs: Int64!
        $endTimeInMs: Int64!
        $filter: Filter
        $gridLevel: Int32
        $xValues: [Float!]
        $yValues: [Float!]
    ) {
        getLocationGrid(
            tenantId: $tenantId
            startTimeInMs: $startTimeInMs
            endTimeInMs: $endTimeInMs
            filter: $filter
            gridLevel: $gridLevel
            xValues: $xValues
            yValues: $yValues
        ) {
            grid {
                Cols
            }
            gridLevel
            columns
            rows
            xValues
            yValues
        }
    }
`;

export const QUERY_ACCESS_DETAILS = gql`
    query queryAccessDetails($tenantId: ID!, $startTime: Int64!, $endTime: Int64!, $filter: Filter) {
        queryAccessDetails(tenantId: $tenantId, startTime: $startTime, endTime: $endTime, filter: $filter) {
            eventId
            requestedResource
            dataVolumeInBytes
            accessOutcome
            accessOutcomeDetails
            tags
            from
            to
            raw
        }
    }
`;

export const LIST_ALERT_DEFINITIONS = gql`
    query listAlertDefinitions($tenantId: ID!) {
        listAlertDefinitions(tenantId: $tenantId) {
            alertDefinitions {
                id
                enabled
                jobType
                jobPayload
                name
                query
                message
                alertThreshold
                warningThreshold
            }
        }
    }
`;

export const ADD_ALERT_DEFINITION = gql`
    mutation addAlertDefinitions($tenantId: ID!, $addAlertDefinitionInput: AddAlertDefinitionInput!) {
        addAlertDefinition(tenantId: $tenantId, addAlertDefinitionInput: $addAlertDefinitionInput) {
            id
        }
    }
`;

export const UPDATE_ALERT_DEFINITION = gql`
    mutation updateAlertDefinitions($tenantId: ID!, $updateAlertDefinitionInput: UpdateAlertDefinitionInput!) {
        updateAlertDefinition(tenantId: $tenantId, updateAlertDefinitionInput: $updateAlertDefinitionInput) {
            success
        }
    }
`;

export const DELETE_ALERT_DEFINITION = gql`
    mutation deleteAlertDefinitions($tenantId: ID!, $deleteAlertDefinitionInput: DeleteAlertDefinitionInput!) {
        deleteAlertDefinition(tenantId: $tenantId, deleteAlertDefinitionInput: $deleteAlertDefinitionInput) {
            success
        }
    }
`;

export const LIST_USER_PERMISSIONS = gql`
    query listUserPermissions($tenantId: ID!, $userId: ID!) {
        listUserPermissions(tenantId: $tenantId, userId: $userId) {
            path
            permissions
        }
    }
`;

export const GET_ALERT_COUNT = gql`
    query getAlertCount($tenantId: ID!, $input: GetAlertCountInput!) {
        getAlertCount(tenantId: $tenantId, input: $input) {
            count
        }
    }
`;

export const RESOLVE_ALERTS = gql`
    mutation resolveAlerts($tenantId: ID!, $resolveAlertsInput: ResolveAlertsInput!) {
        resolveAlerts(tenantId: $tenantId, resolveAlertsInput: $resolveAlertsInput) {
            count
        }
    }
`;

export const GET_RAW_EVENT = gql`
    query getRawEvent($tenantId: ID!, $providerType: ProviderType!, $providerId: String!, $eventId: ID!) {
        getRawEvent(tenantId: $tenantId, providerType: $providerType, providerId: $providerId, eventId: $eventId) {
            type
            data
        }
    }
`;

export const GET_DETAILED_ACCESSES = gql`
    query getDetailedAccesses($tenantId: ID!, $queryId: String, $startTime: Int64!, $endTime: Int64!, $filter: Filter) {
        getIdentityMap(
            tenantId: $tenantId
            queryId: $queryId
            startTime: $startTime
            endTime: $endTime
            maxLevelDepth: 1
            loadAttributes: false
            aggregated: false
            edgesOnly: true
            filter: $filter
        ) {
            queryId
            isLoading
            edges {
                edgeId
                label
                inV
                outV
                tags
                props
            }
        }
    }
`;

export const GET_WALL_EVENTS = gql`
    query getWallEvents($tenantId: ID!, $startDate: Int64!, $endDate: Int64!, $limit: Int64!) {
        getWallEvents(tenantId: $tenantId, startDate: $startDate, endDate: $endDate, limit: $limit) {
            events {
                key
                nodeId
                externalId
                displayName
                alternateId
                type
                publishedAt
                sourceProcessorType
                object
            }
        }
    }
`;

export const GET_WALL_EVENTS_FOR_NODE = gql`
    query getWallEvents($tenantId: ID!, $startDate: Int64!, $endDate: Int64!, $nodeId: String!) {
        getWallEvents(tenantId: $tenantId, startDate: $startDate, endDate: $endDate, nodeId: $nodeId) {
            events {
                key
                nodeId
                externalId
                displayName
                alternateId
                type
                publishedAt
                sourceProcessorType
            }
        }
    }
`;
