import User from 'assets/icons/Actors/User.png';
import DoubleZero from 'assets/icons/Identities/DoubleZero.png';
import Application from 'assets/icons/Applications/Application.png';
import Query from 'assets/icons/Queries/Query.png';

import Desktop from 'assets/icons/Devices/Desktop.png';
import Tablet from 'assets/icons/Devices/Tablet.png';
import Phone from 'assets/icons/Devices/Phone.png';
import Laptop from 'assets/icons/Devices/Laptop.png';

import Target from 'assets/icons/Targets/Target.png';
import File from 'assets/icons/Targets/SubTypes/File.png';
import Group from 'assets/icons/Targets/SubTypes/Group.png';
import Mailbox from 'assets/icons/Targets/SubTypes/Mailbox.png';
import Service from 'assets/icons/Targets/SubTypes/Service.png';
import UnknownSubType from 'assets/icons/Targets/SubTypes/Unknown.png';
import Web from 'assets/icons/Targets/SubTypes/Web.png';

import Amplitude from 'assets/icons/Targets/Amplitude.png';
import Asana from 'assets/icons/Targets/Asana.png';
import Atlassian from 'assets/icons/Targets/Atlassian.png';
import AWS from 'assets/icons/Targets/AWS.png';
import Azure from 'assets/icons/Targets/Azure.png';
import AzureDevOps from 'assets/icons/Targets/AzureDevOps.png';
import AzureService from 'assets/icons/Targets/AzureService.png';
import Bing from 'assets/icons/Targets/Bing.png';
import Boundary from 'assets/icons/Targets/Boundary.png';
import Box from 'assets/icons/Targets/Box.png';
import CiscoWebex from 'assets/icons/Targets/CiscoWebex.png';
import Concur from 'assets/icons/Targets/Concur.png';
import Cortana from 'assets/icons/Targets/Cortana.png';
import CortanaService from 'assets/icons/Targets/CortanaService.png';
import Coupa from 'assets/icons/Targets/Coupa.png';
import Crowdstrike from 'assets/icons/Targets/Crowdstrike.png';
import Datadog from 'assets/icons/Targets/Datadog.png';
import Dataverse from 'assets/icons/Targets/Dataverse.png';
import Delve from 'assets/icons/Targets/Delve.png';
import Docusign from 'assets/icons/Targets/Docusign.png';
import Dynamics365 from 'assets/icons/Targets/Dynamics365.png';
import EntraID from 'assets/icons/Targets/EntraID.png';
import EntraIDService from 'assets/icons/Targets/EntraIDService.png';
import Exchange from 'assets/icons/Targets/Exchange.png';
import ExchangeService from 'assets/icons/Targets/ExchangeService.png';
import Forms from 'assets/icons/Targets/Forms.png';
import GitHub from 'assets/icons/Targets/GitHub.png';
import GitLab from 'assets/icons/Targets/GitLab.png';
import Gong from 'assets/icons/Targets/Gong.png';
import GoogleWorkspace from 'assets/icons/Targets/GoogleWorkspace.png';
import Graph from 'assets/icons/Targets/Graph.png';
import HubSpot from 'assets/icons/Targets/HubSpot.png';
import Intune from 'assets/icons/Targets/Intune.png';
import IntuneService from 'assets/icons/Targets/IntuneService.png';
import JamfPro from 'assets/icons/Targets/JamfPro.png';
import Lattice from 'assets/icons/Targets/Lattice.png';
import Microsoft365 from 'assets/icons/Targets/Microsoft365.png';
import Microsoft365Service from 'assets/icons/Targets/Microsoft365Service.png';
import MicrosoftService from 'assets/icons/Targets/MicrosoftService.png';
import MongoDB from 'assets/icons/Targets/MongoDB.png';
import Okta from 'assets/icons/Targets/Okta.png';
import OneDrive from 'assets/icons/Targets/OneDrive.png';
import OneNote from 'assets/icons/Targets/OneNote.png';
import Outlook from 'assets/icons/Targets/Outlook.png';
import PaloAltoNetworks from 'assets/icons/Targets/PaloAltoNetworks.png';
import PowerApps from 'assets/icons/Targets/PowerApps.png';
import PowerAppsService from 'assets/icons/Targets/PowerAppsService.png';
import PowerAutomate from 'assets/icons/Targets/PowerAutomateService.png';
import PowerAutomateService from 'assets/icons/Targets/PowerAutomateService.png';
import PowerBI from 'assets/icons/Targets/PowerBI.png';
import PowerPlatform from 'assets/icons/Targets/PowerPlatform.png';
import Purview from 'assets/icons/Targets/Purview.png';
import Salesforce from 'assets/icons/Targets/Salesforce.png';
import Salesloft from 'assets/icons/Targets/Salesloft.png';
import SentinelOne from 'assets/icons/Targets/SentinelOne.png';
import ServiceNow from 'assets/icons/Targets/ServiceNow.png';
import SharePoint from 'assets/icons/Targets/SharePoint.png';
import Skype from 'assets/icons/Targets/Skype.png';
import SkypeService from 'assets/icons/Targets/SkypeService.png';
import Slack from 'assets/icons/Targets/Slack.png';
import Snowflake from 'assets/icons/Targets/Snowflake.png';
import Tableau from 'assets/icons/Targets/Tableau.png';
import Teams from 'assets/icons/Targets/Teams.png';
import TeamsService from 'assets/icons/Targets/TeamsService.png';
import ToDo from 'assets/icons/Targets/ToDo.png';
import UdemyBusiness from 'assets/icons/Targets/UdemyBusiness.png';
import WindowsDefender from 'assets/icons/Targets/WindowsDefender.png';
import WindowsService from 'assets/icons/Targets/WindowsService.png';
import Workday from 'assets/icons/Targets/Workday.png';
import Yammer from 'assets/icons/Targets/Yammer.png';
import Zoom from 'assets/icons/Targets/Zoom.png';

import AppAha from 'assets/icons/Applications/Aha.png';
import AppApple from 'assets/icons/Applications/Apple.png';
import AppAvoma from 'assets/icons/Applications/Avoma.png';
import AppAzureService from 'assets/icons/Applications/AzureService.png';
import AppMicrosoftService from 'assets/icons/Applications/MicrosoftService.png';
import AppPowerAppsService from 'assets/icons/Applications/PowerAppsService.png';
import AppAzure from 'assets/icons/Applications/Azure.png';
import AppBing from 'assets/icons/Applications/Bing.png';
import AppBrave from 'assets/icons/Applications/Brave.png';
import AppCalendly from 'assets/icons/Applications/Calendly.png';
import AppChrome from 'assets/icons/Applications/Chrome.png';
import AppClauseMatch from 'assets/icons/Applications/ClauseMatch.png';
import AppEdge from 'assets/icons/Applications/Edge.png';
import AppEntraID from 'assets/icons/Applications/EntraID.png';
import AppExchange from 'assets/icons/Applications/Exchange.png';
import AppExchangeService from 'assets/icons/Applications/ExchangeService.png';
import AppFantastical from 'assets/icons/Applications/Fantastical.png';
import AppFirefox from 'assets/icons/Applications/Firefox.png';
import AppForms from 'assets/icons/Applications/Forms.png';
import AppGo from 'assets/icons/Applications/Go.png';
import AppInternetExplorer from 'assets/icons/Applications/InternetExplorer.png';
import AppIntune from 'assets/icons/Applications/Intune.png';
import AppIntuneService from 'assets/icons/Applications/IntuneService.png';
import AppJamf from 'assets/icons/Applications/Jamf.png';
import AppMicrosoft365 from 'assets/icons/Applications/Microsoft365.png';
import AppMicrosoft365Service from 'assets/icons/Applications/Microsoft365Service.png';
import AppOkta from 'assets/icons/Applications/Okta.png';
import AppOneDrive from 'assets/icons/Applications/OneDrive.png';
import AppOneDriveService from 'assets/icons/Applications/OneDriveService.png';
import AppOpera from 'assets/icons/Applications/Opera.png';
import AppOutlook from 'assets/icons/Applications/Outlook.png';
import AppOutlookService from 'assets/icons/Applications/OutlookService.png';
import AppPipedrive from 'assets/icons/Applications/Pipedrive.png';
import AppPowerApps from 'assets/icons/Applications/PowerApps.png';
import AppPowerAutomateService from 'assets/icons/Applications/PowerAutomateService.png';
import AppPowerAutomate from 'assets/icons/Applications/PowerAutomate.png';
import AppPowerBI from 'assets/icons/Applications/PowerBI.png';
import AppPurview from 'assets/icons/Applications/Purview.png';
import AppSafari from 'assets/icons/Applications/Safari.png';
import AppSailpoint from 'assets/icons/Applications/Sailpoint.png';
import AppServiceNow from 'assets/icons/Applications/ServiceNow.png';
import AppSkypeService from 'assets/icons/Applications/SkypeService.png';
import AppSharePoint from 'assets/icons/Applications/SharePoint.png';
import AppSharePointService from 'assets/icons/Applications/SharePointService.png';
import AppSmartsheet from 'assets/icons/Applications/Smartsheet.png';
import AppStickyNotes from 'assets/icons/Applications/StickyNotes.png';
import AppTeams from 'assets/icons/Applications/Teams.png';
import AppTeamsService from 'assets/icons/Applications/TeamsService.png';
import AppToDo from 'assets/icons/Applications/ToDo.png';
import AppVaronis from 'assets/icons/Applications/Varonis.png';
import AppVaronisService from 'assets/icons/Applications/VaronisService.png';
import AppWindowsDefender from 'assets/icons/Applications/WindowsDefender.png';
import AppWindowsDefenderService from 'assets/icons/Applications/WindowsDefenderService.png';
import AppWindowsService from 'assets/icons/Applications/WindowsService.png';
import AppWorkday from 'assets/icons/Applications/Workday.png';
import AppZoom from 'assets/icons/Applications/Zoom.png';

import ProviderAuth0 from 'assets/icons/Providers/Auth0.png';
import ProviderAWS from 'assets/icons/Providers/AWS.png';
import ProviderAzureAD from 'assets/icons/Providers/AzureAD.png';
import ProviderBox from 'assets/icons/Providers/Box.png';
import Provider from 'assets/icons/Providers/Provider.png';
import ProviderDropbox from 'assets/icons/Providers/Dropbox.png';
import ProviderGitHub from 'assets/icons/Providers/GitHub.png';
import ProviderOffice365 from 'assets/icons/Providers/Office365.png';
import ProviderOkta from 'assets/icons/Providers/Okta.png';
import ProviderActiveDirectory from 'assets/icons/Providers/ActiveDirectory.png';
import ProviderJamf from 'assets/icons/Providers/Jamf.png';
import ProviderWorkday from 'assets/icons/Providers/Workday.png';
import ProviderOneLogin from 'assets/icons/Providers/OneLogin.png';
import ProviderKafka from 'assets/icons/Providers/Kafka.png';
import ProviderPagerDuty from 'assets/icons/Providers/PagerDuty.png';
import ProviderSplunk from 'assets/icons/Providers/Splunk.png';
import ProviderWebHook from 'assets/icons/Providers/WebHook.png';
import ProviderRestAPI from 'assets/icons/Providers/RestAPI.png';
import ProviderCrowdStrike from 'assets/icons/Providers/Crowdstrike.png';
import ProviderNotification from 'assets/icons/Providers/Notification.png';
import ProviderSailPoint from 'assets/icons/Providers/SailPoint.png';
import ProviderCyberArk from 'assets/icons/Providers/CyberArk.png';

import TagActorAtHome from 'assets/icons/Tags/TAG_ACTOR_AT_HOME.png';
import TagActorAtWork from 'assets/icons/Tags/TAG_ACTOR_AT_WORK.png';
import TagActor from 'assets/icons/Tags/TAG_ACTOR.png';
import TagActorExternal from 'assets/icons/Tags/TAG_ACTOR_EXTERNAL.png';
import TagActorInternal from 'assets/icons/Tags/TAG_ACTOR_INTERNAL.png';
import TagActorMachine from 'assets/icons/Tags/TAG_ACTOR_MACHINE.png';
import TagActorOffboarding from 'assets/icons/Tags/TAG_ACTOR_OFFBOARDING.png';
import TagActorTravelling from 'assets/icons/Tags/TAG_ACTOR_TRAVELLING.png';
import TagDevice from 'assets/icons/Tags/TAG_DEVICE.png';
import TagDeviceHasAgent from 'assets/icons/Tags/TAG_DEVICE_HAS_AGENT.png';
import TagDeviceHasTPM from 'assets/icons/Tags/TAG_DEVICE_HAS_TPM.png';
import TagDeviceMDMManaged from 'assets/icons/Tags/TAG_DEVICE_IS_MDM_MANAGED.png';
import TagDeviceIsVM from 'assets/icons/Tags/TAG_DEVICE_IS_VM.png';
import TagSessionFlaggedByAzure from 'assets/icons/Tags/TAG_SESSION_FLAGGED_BY_AZURE.png';
import TagSessionFlaggedByOkta from 'assets/icons/Tags/TAG_SESSION_FLAGGED_BY_OKTA.png';
import TagSessionFlaggedTorExit from 'assets/icons/Tags/TAG_SESSION_FLAGGED_TOR_EXIT.png';
import TagSessionCloudEndpoint from 'assets/icons/Tags/TAG_SESSION_CLOUD_ENDPOINT.png';
import TagSessionIs2FAAuth from 'assets/icons/Tags/TAG_SESSION_IS_2FA_AUTHENTICATED.png';
import TagIdentityDummy from 'assets/icons/Tags/TAG_IDENTITY_DUMMY.png';
import TagIdentityOkta from 'assets/icons/Tags/TAG_IDENTITY_OKTA.png';
import TagIdentityAzureAD from 'assets/icons/Tags/TAG_IDENTITY_AZUREAD.png';
import TagIdentityGitHub from 'assets/icons/Tags/TAG_IDENTITY_GITHUB.png';
import TagIdentityOffice365 from 'assets/icons/Tags/TAG_IDENTITY_OFFICE365.png';
import TagIdentityDropbox from 'assets/icons/Tags/TAG_IDENTITY_DROPBOX.png';
import TagIdentityBox from 'assets/icons/Tags/TAG_IDENTITY_BOX.png';
import TagIdentityAWS from 'assets/icons/Tags/TAG_IDENTITY_AWS.png';
import TagIdentityAzure from 'assets/icons/Tags/TAG_IDENTITY_AZURE.png';
import TagSourceIDP from 'assets/icons/Tags/TAG_SOURCE_IDP.png';
import TagApplicationUnknown from 'assets/icons/Tags/TAG_APPLICATION_UNKNOWN.png';
import TagApplicationWeb from 'assets/icons/Tags/TAG_APPLICATION_WEB.png';
import TagApplicationMobile from 'assets/icons/Tags/TAG_APPLICATION_MOBILE.png';
import TagApplicationDesktop from 'assets/icons/Tags/TAG_APPLICATION_DESKTOP.png';
import TagApplicationService from 'assets/icons/Tags/TAG_APPLICATION_SERVICE.png';
import TagTargetExternal from 'assets/icons/Tags/TAG_TARGET_EXTERNAL.png';
import TagUnknown from 'assets/icons/Tags/Unknown.png';

import Android from 'assets/icons/OS/Android.png';
import ChromeOS from 'assets/icons/OS/ChromeOS.png';
import Debian from 'assets/icons/OS/Debian.png';
import FreeBSD from 'assets/icons/OS/FreeBSD.png';
import Gentoo from 'assets/icons/OS/Gentoo.png';
import iOS from 'assets/icons/OS/iOS.png';
import Linux from 'assets/icons/OS/Linux.png';
import macOS from 'assets/icons/OS/macOS.png';
import RedHat from 'assets/icons/OS/RedHat.png';
import Solaris from 'assets/icons/OS/Solaris.png';
import tvOS from 'assets/icons/OS/tvOS.png';
import Ubuntu from 'assets/icons/OS/Ubuntu.png';
import watchOS from 'assets/icons/OS/watchOS.png';
import WindowsPhone from 'assets/icons/OS/WindowsPhone.png';
import Windows from 'assets/icons/OS/Windows.png';
import Unknown from 'assets/icons/OS/Unknown.png';

import { Node } from 'Types/types';
import { isAGroupLeafNode, isATargetGroup } from 'Utilities/NodeUtilities';

type ImageLocations = Record<string, string>;
type ImageCache = Record<string, HTMLImageElement>;

const nodeIconCache: ImageCache = {};
const osIconCache: ImageCache = {};
const providerIconCache: ImageCache = {};
const tagIconCache: ImageCache = {};

const labels = ['actor', 'device', 'target', 'identity', 'session', 'application', 'query'];
const providerIconLocations: ImageLocations = {
    Default: Provider,
    'Amazon Web Services': ProviderAWS,
    'Azure AD': ProviderAzureAD,
    GitHub: ProviderGitHub,
    Auth0: ProviderAuth0,
    Box: ProviderBox,
    Dropbox: ProviderDropbox,
    'Office 365': ProviderOffice365,
    Okta: ProviderOkta,
    'Active Directory': ProviderActiveDirectory,
    'Jamf Pro': ProviderJamf,
    Workday: ProviderWorkday,
    'One Login': ProviderOneLogin,
    IPFIX: Provider,
    Slack: Slack,
    Email: Mailbox,
    Splunk: ProviderSplunk,
    Kafka: ProviderKafka,
    Webhook: ProviderWebHook,
    PagerDuty: ProviderPagerDuty,
    'Double Zero API': ProviderRestAPI,
    'Zoom Chat': Zoom,
    CrowdStrike: ProviderCrowdStrike,
    Notification: ProviderNotification,
    SailPoint: ProviderSailPoint,
    CyberArk: ProviderCyberArk,
};

const tagIconLocations: ImageLocations = {
    TAG_ACTOR_OFFBOARDING: TagActorOffboarding,
    TAG_ACTOR_AT_HOME: TagActorAtHome,
    TAG_ACTOR_AT_WORK: TagActorAtWork,
    TAG_ACTOR_TRAVELLING: TagActorTravelling,
    TAG_ACTOR_CLOUD_OPERATOR: TagActorMachine,
    TAG_DEVICE_IS_VM: TagDeviceIsVM,
    TAG_DEVICE_HAS_TPM: TagDeviceHasTPM,
    TAG_DEVICE_HAS_AGENT: TagDeviceHasAgent,
    TAG_DEVICE_IS_MDM_MANAGED: TagDeviceMDMManaged,
    TAG_SESSION_FLAGGED_BY_AZURE: TagSessionFlaggedByAzure,
    TAG_SESSION_FLAGGED_BY_OKTA: TagSessionFlaggedByOkta,
    TAG_SESSION_TOR_EXIT: TagSessionFlaggedTorExit,
    TAG_SESSION_CLOUD_ENDPOINT: TagSessionCloudEndpoint,
    TAG_SESSION_IS_2FA_AUTHENTICATED: TagSessionIs2FAAuth,
    TAG_IDENTITY_DUMMY: TagIdentityDummy,
    TAG_IDENTITY_OKTA: TagIdentityOkta,
    TAG_IDENTITY_AZUREAD: TagIdentityAzureAD,
    TAG_IDENTITY_GITHUB: TagIdentityGitHub,
    TAG_IDENTITY_OFFICE365: TagIdentityOffice365,
    TAG_IDENTITY_DROPBOX: TagIdentityDropbox,
    TAG_IDENTITY_BOX: TagIdentityBox,
    TAG_IDENTITY_AWS: TagIdentityAWS,
    TAG_IDENTITY_AZURE: TagIdentityAzure,
    TAG_SOURCE_IDP: TagSourceIDP,
    TAG_DEVICE: TagDevice,
    TAG_ACTOR: TagActor,
    TAG_ACTOR_EXTERNAL: TagActorExternal,
    TAG_ACTOR_INTERNAL: TagActorInternal,
    TAG_ACTOR_MACHINE: TagActorMachine,
    TAG_APPLICATION_UNKNOWN: TagApplicationUnknown,
    TAG_APPLICATION_WEB: TagApplicationWeb,
    TAG_APPLICATION_MOBILE: TagApplicationMobile,
    TAG_APPLICATION_DESKTOP: TagApplicationDesktop,
    TAG_APPLICATION_SERVICE: TagApplicationService,
    TAG_TARGET_EXTERNAL: TagTargetExternal,
    Unknown: TagUnknown,
};

const targetIconLocations: ImageLocations = {
    Default: Target,
    NODE_TYPE_GROUP: Group,
    NODE_TYPE_FILE: File,
    NODE_TYPE_MAILBOX: Mailbox,
    NODE_TYPE_SERVICE: Service,
    NODE_TYPE_WEB: Web,
    NODE_TYPE_UNKNOWN: UnknownSubType,
    'AAD App Management': EntraIDService,
    AADPasswordProtectionProxy: EntraIDService,
    AADReporting: EntraIDService,
    ADIbizaUX: AzureService,
    ADIbizaUXFairfax: AzureService,
    Amplitude: Amplitude,
    'ApiHub-Connectors-GCCHigh': PowerApps,
    Asana: Asana,
    'Atlassian Cloud': Atlassian,
    'Augmentation Loop': Microsoft365Service,
    AWS: AWS,
    'AWS Account Federation': AWS,
    'AWS IAM Identity Center': AWS,
    'AWS Single Sign-on': AWS,
    'Azure Active Directory': EntraID,
    'Azure Advanced Threat Protection': AzureService,
    'Azure API Hub': AzureService,
    'Azure DevOps': AzureDevOps,
    'Azure Government Cloud Management API': AzureService,
    'Azure Key Vault': AzureService,
    'Azure Multi-Factor Auth Connector': EntraIDService,
    'Azure Portal': Azure,
    'Azure Purview': Purview,
    'Azure Virtual Desktop': AzureService,
    Bing: Bing,
    Boundary: Boundary,
    Box: Box,
    'Call Recorder': TeamsService,
    'Cisco Webex': CiscoWebex,
    Concur: Concur,
    Connectors: Microsoft365Service,
    'Cortana Basic': Cortana,
    'Cortana Runtime Service': CortanaService,
    Coupa: Coupa,
    Crowdstrike: Crowdstrike,
    Datadog: Datadog,
    Dataverse: Dataverse,
    'Device Registration Service': Microsoft365Service,
    Docusign: Docusign,
    'Double Zero': DoubleZero,
    'Edge Sync': MicrosoftService,
    EnrichmentSvc: Microsoft365Service,
    'Focused Inbox': ExchangeService,
    Exchange: Exchange,
    'Github Enterprise Cloud - Organization': GitHub,
    Github: GitHub,
    GitLab: GitLab,
    'Google Workspace': GoogleWorkspace,
    Gong: Gong,
    HubSpot: HubSpot,
    'IAM Supportability': AzureService,
    'IC3 Gateway': Microsoft365Service,
    IrisSelectionFrontDoor: AzureService,
    'Jamf Pro SAML': JamfPro,
    Lattice: Lattice,
    'Media Analysis and Transformation Service': Microsoft365Service,
    'Message Recall': Microsoft365Service,
    'M365 License Manager': Microsoft365Service,
    'M365 Tenant Feedback': Microsoft365Service,
    'Metrics Monitor API': AzureService,
    'Microsoft 365 Security and Compliance Center': Microsoft365Service,
    'Microsoft 365 App Catalog Services': Microsoft365Service,
    'Microsoft Activity Feed Service': MicrosoftService,
    'Microsoft App Access Panel': EntraID,
    'Microsoft Azure Active Directory Connect': EntraIDService,
    'Microsoft Azure Signup Portal': AzureService,
    'Microsoft Cloud App Security': WindowsDefender,
    'Microsoft Device Directory Service': EntraIDService,
    'Microsoft Edge Auth': MicrosoftService,
    'Microsoft Edge Identity Service': MicrosoftService,
    'Microsoft Exchange Online Protection': ExchangeService,
    'Microsoft Flow': PowerAutomate,
    'Microsoft Flow Service': PowerAutomateService,
    'Microsoft Flow Service GCC High': PowerAutomateService,
    'Microsoft Forms': Forms,
    'Microsoft Forms Pro': Dynamics365,
    'Microsoft Graph': Graph,
    'Microsoft Information Protection Sync Service': Purview,
    'Microsoft Intune': Intune,
    'Microsoft Intune Checkin': Intune,
    'Microsoft Intune Enrollment': IntuneService,
    'Microsoft Intune portal extension': IntuneService,
    'Microsoft Mobile Application Management': MicrosoftService,
    'Microsoft News Feed': MicrosoftService,
    'Microsoft Office 365': Microsoft365,
    'Microsoft Office 365 Portal': Microsoft365,
    'Microsoft Outlook': Outlook,
    'Microsoft Outlook Mobile': Outlook,
    'Microsoft Partner': MicrosoftService,
    'Microsoft Partner Center': MicrosoftService,
    'Microsoft People Cards Service': Microsoft365Service,
    'Microsoft Purview Platform': Purview,
    'Microsoft Rights Management Services': Purview,
    'Microsoft SharePoint Online - SharePoint Home': SharePoint,
    'Microsoft Stream Portal': Microsoft365Service,
    'Microsoft Stream Service': Microsoft365Service,
    'Microsoft Teams': Teams,
    'Microsoft Teams - Teams And Channels Service': TeamsService,
    'Microsoft Teams Chat Aggregator': TeamsService,
    'Microsoft Teams Graph Service': TeamsService,
    'Microsoft Teams Retail Service': TeamsService,
    'Microsoft Teams Services': TeamsService,
    'Microsoft Teams UIS': TeamsService,
    'Microsoft Teams Web Client': TeamsService,
    'Microsoft Threat Protection': AzureService,
    'Microsoft To-Do': ToDo,
    'Microsoft Whiteboard Services': Microsoft365Service,
    'Microsoft Workplace Search Service': Microsoft365Service,
    Microsoft_Azure_Billing: AzureService,
    Microsoft_Azure_PIMCommon: AzureService,
    'Modern Workplace Customer APIs': AzureService,
    'MongoDB Atlas': MongoDB,
    'MongoDB Cloud': MongoDB,
    'My Apps': EntraID,
    'My Profile': EntraID,
    'O365 Diagnostic Service': Microsoft365Service,
    'O365 Suite UX': Microsoft365Service,
    'o365.servicecommunications.microsoft.com': Microsoft365Service,
    'OCaaS Client Interaction Service': MicrosoftService,
    'OCaaS Experience Management Service': MicrosoftService,
    'OCaaS Worker Services': MicrosoftService,
    'OCPS Admin Service': Microsoft365Service,
    'OCPS Checkin Service': Microsoft365Service,
    'Office 365': Microsoft365,
    'Office 365 Exchange Microservices': ExchangeService,
    'Office 365 Exchange Online': Exchange,
    'Office 365 Information Protection': Purview,
    'Office 365 Management APIs': Microsoft365Service,
    'Office 365 Search Service': Microsoft365Service,
    'Office 365 SharePoint Online': SharePoint,
    'Office Admin Controlled Messaging Service': Microsoft365Service,
    'Office Delve': Delve,
    'Office Hive': Microsoft365,
    'Office MRO Device Manager Service': Microsoft365Service,
    'Office Online Service': Microsoft365Service,
    'Office Shredding Service': Microsoft365Service,
    'Office Web': Microsoft365,
    'Office.com': Microsoft365,
    'Office365 Shell SS-Server': Microsoft365Service,
    'Office365 Shell WCSS-Client': Microsoft365Service,
    'Office365 Shell WCSS-Server': Microsoft365Service,
    OfficeClientService: Microsoft365Service,
    OfficeHome: Microsoft365,
    OfficeServicesManager: Microsoft365Service,
    'Okta Admin Console': Okta,
    'Okta Browser Plugin': Okta,
    'Okta Dashboard': Okta,
    'One Drive': OneDrive,
    OneDrive: OneDrive,
    'OneDrive SyncEngine': OneDrive,
    'OneDrive Web': OneDrive,
    OneNote: OneNote,
    'Palo Alto Networks - GlobalProtect': PaloAltoNetworks,
    'P2P Server': AzureService,
    'Power Apps': PowerApps,
    'Power BI Service': PowerBI,
    'Power Platform API': PowerPlatform,
    'PowerApps - apps.powerapps.com': PowerApps,
    'PowerApps Service': PowerAppsService,
    'PowerApps Service - GCC L4': PowerAppsService,
    'PowerApps Web Player Service - high.apps.powerapps.us': PowerAppsService,
    ProjectWorkManagement_USGov: PowerAppsService,
    PushChannel: Microsoft365Service,
    'Salesforce.com': Salesforce,
    Salesloft: Salesloft,
    SentinelOne: SentinelOne,
    ServiceNow: ServiceNow,
    'ServiceNow UD': ServiceNow,
    SharePoint: SharePoint,
    'SharePoint Online Client': SharePoint,
    Signup: Microsoft365Service,
    'Skype Core Calling Service': SkypeService,
    'Skype for Business': Skype,
    'Skype Presence Service': SkypeService,
    Slack: Slack,
    Snowflake: Snowflake,
    'Tableau Online': Tableau,
    'Targeted Messaging Service': Microsoft365Service,
    'Teams CMD Services Artifacts': TeamsService,
    'Teams User Engagement Profile Service': TeamsService,
    'Threat Intelligence': WindowsDefender,
    'Udemy Business': UdemyBusiness,
    'Universal Print': WindowsService,
    'Win10 Token Broker': WindowsService,
    'Windows Azure Active Directory': EntraID,
    'Windows Azure Service Management API': AzureService,
    'Windows Notification Service': WindowsService,
    'Windows Store for Business': MicrosoftService,
    WindowsDefenderATP: WindowsDefender,
    Workday: Workday,
    Yammer: Yammer,
    'Yammer Web': Yammer,
    Zoom: Zoom,
};

const applicationIconLocations: ImageLocations = {
    Application: Application,
    '/owa/': AppOutlook,
    'AAD App Management': AppAzureService,
    ADIbizaUXFairfax: AppMicrosoftService,
    'Aha!': AppAha,
    'ApiHub-Connectors-GCCHigh': AppPowerAppsService,
    'Apple Internet Accounts': AppApple,
    'Augmentation Loop': AppMicrosoft365Service,
    Avoma: AppAvoma,
    'Azure Active Directory': AppAzure,
    'Azure Advanced Threat Protection': AppAzureService,
    'Azure API Hub': AppAzureService,
    'Azure DevOps': AppAzureService,
    'Azure Government Portal': AppAzureService,
    'Azure Portal': AppAzure,
    'Azure Virtual Desktop Client': AppAzureService,
    Bing: AppBing,
    Brave: AppBrave,
    calendly: AppCalendly,
    Chrome: AppChrome,
    ClauseMatch: AppClauseMatch,
    ConfigMgrAzureClient: AppAzureService,
    Connectors: Microsoft365Service,
    'Device Management Client': AppWindowsService,
    Edge: AppEdge,
    EnrichmentSvc: AppMicrosoft365Service,
    Exchange: AppExchange,
    'Fantastical for Mac, iOS, and iPadOS': AppFantastical,
    Firefox: AppFirefox,
    'Go-http-client v2.0': AppGo,
    IdentityIQ: AppSailpoint,
    IE: AppInternetExplorer,
    'Jamf Native macOS Connector': AppJamf,
    'JAMF-Connect - Native application': AppJamf,
    MacOutlook: AppOutlook,
    'Microsoft 365 Security and Compliance Center': AppMicrosoft365Service,
    'Microsoft 365 Support Service': AppMicrosoft365Service,
    'Microsoft Account Controls V2': AppMicrosoftService,
    'Microsoft App Access Panel': AppMicrosoftService,
    'Microsoft Application Command Service': AppMicrosoftService,
    'Microsoft Authentication Broker': AppMicrosoftService,
    'Microsoft Authenticator App': AppMicrosoftService,
    'Microsoft Azure Active Directory Connect': AppAzureService,
    'Microsoft Azure CLI': AppAzure,
    'Microsoft Azure Information Protection': AppPurview,
    'Microsoft Azure Information Protection Labelling Addin': AppPurview,
    'Microsoft Bing Search for Microsoft Edge': AppBing,
    'Microsoft Cloud App Security': AppMicrosoftService,
    'Microsoft Edge': AppEdge,
    'Microsoft Edge Enterprise New Tab Page': AppEdge,
    'Microsoft Exchange Online Remote PowerShell': AppExchangeService,
    'Microsoft Flow': AppPowerAutomate,
    'Microsoft Flow Portal GCC High': AppPowerAutomateService,
    'Microsoft Forms': AppForms,
    'Microsoft Intune portal extension': AppIntuneService,
    'Microsoft Intune Windows Agent': AppIntune,
    'Microsoft Mobile Application Management': AppIntuneService,
    'Microsoft Office 365 Portal': AppMicrosoft365Service,
    'Microsoft Office Web Apps Service': AppMicrosoft365Service,
    'Microsoft Office': AppMicrosoft365,
    'Microsoft SkyDriveSync': AppOneDrive,
    'Microsoft SkyDriveSync v22.121.0605': AppOneDrive,
    'Microsoft SkyDriveSync v23.147.0716': AppOneDrive,
    'Microsoft Teams': AppTeams,
    'Microsoft Teams Graph Service': AppTeamsService,
    'Microsoft Teams Services': AppTeamsService,
    'Microsoft Teams Web Client': AppTeamsService,
    'Microsoft To-Do client': AppToDo,
    'Microsoft Todo Web App': AppToDo,
    'Microsoft Whiteboard Services': AppMicrosoft365Service,
    Microsoft_Azure_Billing: AppAzureService,
    Microsoft_Azure_PIMCommon: AppAzureService,
    'My Apps': AppEntraID,
    'My Profile': AppEntraID,
    'O365 Suite UX': AppMicrosoft365Service,
    'Office 365 Exchange Online': AppExchange,
    'Office 365 SharePoint Online': AppSharePoint,
    'Office Online Core SSO': AppMicrosoft365Service,
    'Office UWP PWA': AppMicrosoft365Service,
    'Office365 Shell WCSS-Client': AppMicrosoft365Service,
    'Office365 Shell WCSS-Server': AppMicrosoft365Service,
    Okta: AppOkta,
    'One Drive': AppOneDrive,
    'OneDrive for Slack': AppOneDriveService,
    'OneDrive SyncEngine': AppOneDriveService,
    Opera: AppOpera,
    Outlook: AppOutlook,
    'Outlook Calendar for Slack': AppOutlookService,
    'Outlook Mobile': AppOutlook,
    'Outlook Web Access': AppOutlook,
    'Pipedrive CRM': AppPipedrive,
    'Power Apps': AppPowerApps,
    'PowerApps Web Player Service - high.apps.powerapps.us': AppPowerAppsService,
    'Power BI Service': AppPowerBI,
    ProjectWorkManagement_USGov: AppPowerAppsService,
    Safari: AppSafari,
    Sailpoint: AppSailpoint,
    SailpointAzureADManagement: AppSailpoint,
    ServiceNow: AppServiceNow,
    SharePoint: AppSharePoint,
    'SharePoint Online Client Extensibility Web Application Principal': AppSharePointService,
    'SharePoint Online Web Client Extensibility': AppSharePointService,
    'Skype Presence Service': AppSkypeService,
    Smartsheet: AppSmartsheet,
    'Sticky Notes Client': AppStickyNotes,
    'Teams CMD Services Artifacts': AppTeamsService,
    'Threat Intelligence': AppWindowsDefender,
    'Universal Store Native Client': AppMicrosoftService,
    Varonis_filewalk_exo_17875: AppVaronis,
    varonisapi: AppVaronisService,
    'Windows Search': AppWindowsService,
    'Windows Sign In': AppWindowsService,
    'Windows Spotlight': AppWindowsService,
    'WindowsDefenderATP Portal': AppWindowsDefenderService,
    WindowsDefenderATP: AppWindowsDefenderService,
    Workday: AppWorkday,
    Zoom: AppZoom,
};

const deviceIconLocations: ImageLocations = {
    DEVICE_COMPUTER: Desktop,
    DEVICE_LAPTOP: Laptop,
    DEVICE_MOBILE: Phone,
    DEVICE_TABLET: Tablet,
};

const operatingSystemIconLocations: ImageLocations = {
    OS_ANDROID: Android,
    OS_CHROMEOS: ChromeOS,
    OS_DEBIAN: Debian,
    OS_FREEBSD: FreeBSD,
    OS_GENTOO: Gentoo,
    OS_IOS: iOS,
    OS_LINUX: Linux,
    OS_MACOS: macOS,
    OS_REDHAT: RedHat,
    OS_SOLARIS: Solaris,
    OS_TVOS: tvOS,
    OS_UBUNTU: Ubuntu,
    OS_WATCHOS: watchOS,
    OS_WINDOWSPHONE: WindowsPhone,
    OS_WINDOWS: Windows,
    OS_UNKNOWN: Unknown,
};

const createNodeImageCache = (): void => {
    labels.map((label) => {
        const image = new Image();
        switch (label) {
            case 'target':
                Object.entries(targetIconLocations).map(([key, value]) => {
                    const image = new Image();
                    image.src = value;
                    nodeIconCache[`target$${key}`] = image;
                });
                return;
            case 'device':
                Object.entries(deviceIconLocations).map(([key, value]) => {
                    const image = new Image();
                    image.src = value;
                    nodeIconCache[`device$${key}`] = image;
                });
                return;
            case 'identity':
                image.src = DoubleZero;
                nodeIconCache[label] = image;
                return;
            case 'session':
                image.src = DoubleZero;
                nodeIconCache[label] = image;
                return;
            case 'application':
                Object.entries(applicationIconLocations).map(([key, value]) => {
                    const image = new Image();
                    image.src = value;
                    nodeIconCache[`application$${key}`] = image;
                });
                return;
            case 'query':
                image.src = Query;
                nodeIconCache[label] = image;
                return;
            default:
                image.src = User;
                nodeIconCache[label] = image;
                return;
        }
    });
};
createNodeImageCache();

const createSimpleImageCache = (locations: ImageLocations, cache: ImageCache): void => {
    Object.entries(locations).map(([key, value]) => {
        const image = new Image();
        image.src = value;
        cache[`${key}`] = image;
    });
};

createSimpleImageCache(providerIconLocations, providerIconCache);
createSimpleImageCache(operatingSystemIconLocations, osIconCache);
createSimpleImageCache(tagIconLocations, tagIconCache);

export const getOSIconElement = (node: Node): HTMLImageElement => {
    if (node.label && node.label == 'device' && node.props.deviceOperatingSystem) {
        const icon = osIconCache[node.props.deviceOperatingSystem];
        if (icon != undefined) {
            return icon;
        }
    }
    return osIconCache['OS_UNKNOWN'];
};

export const getProviderIconElement = (displayName: string): HTMLImageElement => {
    const icon = providerIconCache[displayName];
    if (icon != undefined) {
        return icon;
    }
    return providerIconCache['Default'];
};

export const getTagIconElement = (tag: string): HTMLImageElement => {
    const icon = tagIconCache[tag];
    if (icon != undefined) {
        return icon;
    }
    return tagIconCache['Unknown'];
};

export const getIconSourceURL = (icon: HTMLImageElement): string => {
    return icon.src;
};

const env = window.__env__.ENVIRONMENT;
const iconsBeingFetched = new Set<string>();
let iconServiceURL = 'http://icons.00.local';
if (env !== 'dev' && env !== 'local') {
    iconServiceURL = window.__env__.ASSET_HOST;
}

// Return the icon element for the given node
//
// The order of precedence is as follows:
//  1. If the node has an icon image bundled with the product, use that (highest quality)
//  2. If the node has an icon image in the icon service, use that (variable quality)
//  3. Fallback to the default icon
//
// On first execution only option (1) or (3) can be returned, returning (2) requires a network request
// so will only be available on subsequent executions after the request has completed.
//
// All icons are stored in the imageDictionary keyed by the node type and a property (varies by node type)
// to allow for caching. Once a node has an icon in the image dictionary, it will be returned on subsequent calls.
export const getNodeIconElement = (node: Node): HTMLImageElement => {
    if (node.label) {
        switch (node.label) {
            case 'target':
                // If the target is a group, it will "have a next level" so we return the group icon
                if (isATargetGroup(node)) {
                    return nodeIconCache['target$NODE_TYPE_GROUP'];
                }
                // If the target has some grouping data, it is a subtype of the target type and will have a special icon
                if (isAGroupLeafNode(node)) {
                    return nodeIconCache[`target$${node.nodeType}`];
                }
                // Otherwise, deal with finding the right top level icon for the target service
                const image = nodeIconCache[`target$${node.props.displayName}`];
                if (!image && node.props.serviceDomain) {
                    const serviceDomain = node.props.serviceDomain;
                    // If we don't have an image for this target, we can try fetch it from the icon service
                    // We place a lock on the icon so that we don't try to fetch it multiple times by adding
                    // it to the set of icons being fetched.
                    //
                    // The "size" parameter is referencing [min..default..max] in pixels
                    //
                    // We set redirect to 'manual' to handle the case where the icon service does not have an image
                    if (!iconsBeingFetched.has(serviceDomain)) {
                        iconsBeingFetched.add(serviceDomain);

                        fetch(`${iconServiceURL}/icon?url=${node.props.serviceDomain}&size=24..128..256`, {
                            redirect: 'manual',
                        })
                            .then((response) => {
                                // If there is an available image, we grab the blob and create and image element from it
                                if (response.ok) {
                                    response.blob().then((blob) => {
                                        const url = URL.createObjectURL(blob);
                                        const img = new Image();
                                        img.src = url;
                                        nodeIconCache[`target$${node.props.displayName}`] = img;
                                        iconsBeingFetched.delete(serviceDomain);
                                    });
                                }
                                // A redirect happens when the icon service does not have the icon
                                // for the service domain. We simply ignore the re-direct and assign the default icon.
                                if (response.type === 'opaqueredirect') {
                                    nodeIconCache[`target$${node.props.displayName}`] = nodeIconCache['target$Default'];
                                    iconsBeingFetched.delete(serviceDomain);
                                }
                            })
                            // If there is an error, we assign the default icon
                            .catch((error) => {
                                console.warn(
                                    'Could not find an icon for',
                                    serviceDomain,
                                    `using default icon [error = ${error}]`,
                                );
                                nodeIconCache[`target$${node.props.displayName}`] = nodeIconCache['target$Default'];
                                iconsBeingFetched.delete(serviceDomain);
                            });
                    }
                }
                return image || nodeIconCache['target$Default'];
            case 'application':
                const name = `application$${node.props.displayName}`;
                // First, try find the icon based on an exact name match
                const icon = nodeIconCache[name];
                if (icon) {
                    return icon;
                }
                // If not, we'll do a substring search different icon names to find a match
                // For example, the node name might be "Chrome v99" and the icon cache will match with "Chrome"
                const cacheKeys = Object.keys(nodeIconCache);
                for (const cacheKey of cacheKeys) {
                    if (name.includes(cacheKey)) {
                        nodeIconCache[name] = nodeIconCache[cacheKey];
                        return nodeIconCache[cacheKey];
                    }
                }
                // If we can't find an exact or partial match, we'll use the default icon
                return nodeIconCache['application$Application'];
            case 'device':
                return nodeIconCache[`device$${node.props.deviceType}`] || nodeIconCache['device$DEVICE_LAPTOP'];
            default:
                return nodeIconCache[node.label];
        }
    } else {
        return nodeIconCache['identity'];
    }
};
