import { Providers } from 'Configuration/Providers/Providers';
import { useState } from 'react';
import { Panel, PanelItem } from './Panel';
import {
    AvailableDynamicProviders,
    EditDynamicProvider,
    NewDynamicProvider,
} from 'Configuration/Providers/DynamicProviders';
import { DynamicProvider } from 'Types/types';
import { useUserPermissions } from 'Utilities/UserPermissions';

const ProviderPanel = (): JSX.Element => {
    const [requestedNewProvider, setRequestedNewProvider] = useState<DynamicProvider>();
    const [requestedEditProviderId, setRequestedEditProviderId] = useState<string | undefined>();
    const [requestedEditProviderName, setRequestedEditProviderName] = useState<string | undefined>();

    const editProvider = (providerId: string, providerName: string) => {
        setRequestedEditProviderId(providerId);
        setRequestedEditProviderName(providerName);
    };

    const { userCan } = useUserPermissions();

    const canCreateProviders = userCan('create', '/provider');

    return (
        <Panel id="ProvidersMap" title="Configure Providers" subtitle="Integrate Double Zero with your environment">
            {!requestedNewProvider && !requestedEditProviderId && (
                <PanelItem title="Enabled Integrations">
                    <Providers onEdit={editProvider} />
                </PanelItem>
            )}

            {!requestedNewProvider && !requestedEditProviderId && canCreateProviders && (
                <PanelItem title="Add Another Provider">
                    <div className="mt-4 grid grid-cols-4 gap-4 text-gray-100">
                        <AvailableDynamicProviders handleClick={setRequestedNewProvider} />
                    </div>
                </PanelItem>
            )}

            {requestedNewProvider && !requestedEditProviderId && canCreateProviders && (
                <PanelItem title="Configure New Provider">
                    <NewDynamicProvider
                        provider={requestedNewProvider}
                        onClose={() => setRequestedNewProvider(undefined)}
                    />
                </PanelItem>
            )}

            {requestedEditProviderId && requestedEditProviderName && !requestedNewProvider && canCreateProviders && (
                <PanelItem title="Edit Existing Provider">
                    <EditDynamicProvider
                        providerName={requestedEditProviderName}
                        providerId={requestedEditProviderId}
                        onClose={() => {
                            setRequestedEditProviderId(undefined);
                            setRequestedEditProviderName(undefined);
                        }}
                    />
                </PanelItem>
            )}
        </Panel>
    );
};

export default ProviderPanel;
