import React, { Dispatch, SetStateAction, useEffect } from 'react';
import logo from 'assets/logo-192x192.png';
import { ApolloClient, ApolloError, HttpLink, InMemoryCache, useMutation } from '@apollo/client';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SignUpStep } from './SignUpTypes';
import { ONBOARD_TENANT } from 'Graph/queries';
import { getAuthProvider } from 'Hooks/Auth';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useForm } from 'react-hook-form';

interface FormProps {
    setStep: Dispatch<SetStateAction<SignUpStep>>;
}

interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    slug: string;
    name: string;
    cloud: string;
    region: string;
    tos: boolean;
    cluster?: string;
}

const Form = ({ setStep }: FormProps): JSX.Element => {
    const { availableClusters } = useFlags();
    const [availableClustersLoaded, setAvailableClustersLoaded] = useState<boolean>(false);
    const history = useHistory();

    const { register, setValue, handleSubmit } = useForm<FormData>();

    const [onboardTenant] = useMutation(ONBOARD_TENANT);

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const [success, setSuccess] = useState<boolean>(false);

    // When we learn of the available clusters via launch darkly, set the selected cluster to the first one
    useEffect(() => {
        if (availableClusters && availableClusters.length > 0 && !availableClustersLoaded) {
            console.log('Setting cluster to:', availableClusters[0].url);
            setValue('cluster', availableClusters[0].url);
            setAvailableClustersLoaded(true);
        }
    }, [availableClusters, availableClustersLoaded, setValue]);

    const provider = getAuthProvider();

    const submitForm = async (formData: FormData) => {
        console.log('Creating tenant:', formData);

        if (provider == 'keycloak') {
            formData.name = formData.name || '';
            formData.slug = formData.slug || '';
        }

        try {
            const defaultUri = window.__env__.GRAPHQL_URI;

            let result;

            setError(undefined);
            setSuccess(false);
            setLoading(true);

            if (defaultUri == formData.cluster) {
                console.log('Onboarding tenant with default URI:', defaultUri);

                // Remove cluster from the form data before sending
                delete formData.cluster;

                result = await onboardTenant({ variables: { input: formData } });
            } else {
                console.log('Onboarding tenant with selected URI:', formData.cluster);
                const link = new HttpLink({
                    uri: formData.cluster,
                });

                const client = new ApolloClient({
                    link: link,
                    cache: new InMemoryCache(),
                });

                // Remove cluster from the form data before sending
                delete formData.cluster;

                result = await client.mutate({ mutation: ONBOARD_TENANT, variables: { input: formData } });
            }

            setError(undefined);
            setLoading(false);

            if (result.data.onboardTenant.result == true) {
                setSuccess(true);
                localStorage.setItem('tenantId', result.data.onboardTenant.tenantId);
                localStorage.setItem('org_id', result.data.onboardTenant.orgId);
                if (history) {
                    history.push('/public/sign-up/confirmation');
                }
            } else {
                setSuccess(false);
                setError('Backend returned false result');
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
            setSuccess(false);

            if (e instanceof ApolloError) {
                setError(e.message);
            } else {
                setError(`An unknown error occurred:  ${e}`);
            }
        }
    };

    const getOrganizationSection = () => {
        return (
            <>
                <div className="mt-6 relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-400" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-gray-800 text-gray-400">Organization</span>
                    </div>
                </div>

                <div>
                    <label htmlFor="slug" className="block text-sm font-medium text-gray-300">
                        Short Name
                    </label>
                    <p className="text-gray-600 text-xs">
                        {`Must only contain lowercase characters, '-', and`}
                        <br />
                        {`start and end with a letter or number`}
                    </p>
                    <div className="mt-1">
                        <input
                            {...register('slug')}
                            id="slug"
                            name="slug"
                            type="text"
                            required
                            pattern="^[0-9a-z][0-9a-z\-]{1,48}[0-9a-z]$"
                            autoComplete="new-password"
                            className="input-gray"
                            minLength={3}
                            maxLength={48}
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-300">
                        Display Name
                    </label>
                    <div className="mt-1">
                        <input
                            {...register('name')}
                            id="name"
                            name="name"
                            type="text"
                            required
                            autoComplete="new-password"
                            className="input-gray"
                            minLength={3}
                            maxLength={48}
                        />
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <div>
                <img className="h-12 w-auto" src={logo} alt="Double Zero Icon" />
                <h2 className="text-3xl font-extrabold text-gray-200">Create your Trust Fabric</h2>
            </div>

            <div className="mt-10 md:mt-8">
                <div className="mt-6">
                    <form name="sign-up" className="space-y-6" onSubmit={handleSubmit(submitForm)}>
                        <div className="mt-6 relative">
                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t border-gray-400" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-gray-800 text-gray-400">Administrator Account</span>
                            </div>
                        </div>
                        <div className="space-y-1">
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-300">
                                First Name
                            </label>
                            <div className="mt-1">
                                <input
                                    {...register('firstName')}
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    autoComplete="firstName"
                                    required
                                    className="input-gray"
                                    maxLength={48}
                                />
                            </div>
                        </div>
                        <div className="space-y-1">
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-300">
                                Last Name
                            </label>
                            <div className="mt-1">
                                <input
                                    {...register('lastName')}
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    autoComplete="lastName"
                                    required
                                    className="input-gray"
                                    maxLength={48}
                                />
                            </div>
                        </div>
                        <div className="space-y-1">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                                Email
                            </label>
                            <div className="mt-1">
                                <input
                                    {...register('email')}
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    className="input-gray"
                                />
                            </div>
                        </div>

                        {(provider != 'keycloak' && getOrganizationSection()) || ''}

                        {provider != 'keycloak' && (
                            <div>
                                <div className="mt-6 relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-400" />
                                    </div>
                                    <div className="relative flex justify-center text-sm">
                                        <span className="px-2 bg-gray-800 text-gray-400">Cluster</span>
                                    </div>
                                </div>
                                <div className="space-y-1 mt-4">
                                    <div className="mt-1">
                                        <select {...register('cluster')} id="cluster" className="input-gray" required>
                                            {availableClusters &&
                                                availableClusters.map(
                                                    ({ name, url }: { name: string; url: string }) => (
                                                        <option key={name} value={url}>
                                                            {name}
                                                        </option>
                                                    ),
                                                )}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="h-2"></div>

                        <div className="flex items-center">
                            <input
                                {...register('tos')}
                                id="tos"
                                name="tos"
                                type="checkbox"
                                required
                                className="chk-box h-4 w-4"
                            />
                            <label htmlFor="tos" className="ml-2 block text-sm text-gray-400">
                                I Agree to the Double Zero{' '}
                                <a
                                    onClick={() => setStep(SignUpStep.TermsOfService)}
                                    className="font-medium text-blue-600 hover:text-blue-500 cursor-pointer"
                                >
                                    Terms of Service
                                </a>
                            </label>
                        </div>

                        {error && (
                            <div className="space-y-2 text-red-500 text-sm">
                                <h4>There was an issue creating your Trust Fabric</h4>
                                <h4>Please retry later</h4>
                                <h4>If this issue persists, please contact support</h4>
                            </div>
                        )}

                        <div>
                            {loading ? (
                                <div className="h-10 flex justify-center items-center">
                                    <h4 className="text-sm text-gray-300">Creating Trust Fabric...</h4>
                                </div>
                            ) : (
                                <button
                                    type="submit"
                                    id="submit"
                                    className="btn btn-primary w-full text-white font-medium text-sm rounded-md h-10"
                                    disabled={loading || success}
                                >
                                    Create
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Form;
