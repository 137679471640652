import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    FunnelIcon,
    MagnifyingGlassIcon,
    ChevronUpDownIcon,
    XMarkIcon,
    XCircleIcon,
} from '@heroicons/react/20/solid';

import { Fragment, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { Column, useBlockLayout, useFilters, usePagination, useResizeColumns, useSortBy, useTable } from 'react-table';
import { classNames, getDisplayName, getMfaDetails, isNodeHidden } from 'Utilities/utils';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useGraphControls } from 'Hooks/GraphHooks';
import { Transition } from '@headlessui/react';
import { Helmet } from 'react-helmet-async';
import { useHotkeys } from 'react-hotkeys-hook';
import { getDataBrowserColumns, DataBrowserFields } from './DataBrowserColumns';
import {
    fuzzyTextFilterFn,
    fuzzyNodeDisplayNameFilterFn,
    DefaultColumnFilter,
    sortTableNodes,
    sortPolicyStats,
    sortGreaterThan,
    filterGreaterThan,
} from 'Library/TableComponents';
import { DateCell, DefaultCell, MfaCell, NodeCell, ProviderCell, StratifiedPercentageCell } from './DataBrowserCells';
import { useLocalStorage } from 'Hooks/Hooks';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import { Node, PolicyStats } from 'Types/types';

import CsvDownloader from 'react-csv-downloader';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const DataBrowser = () => {
    const { policy = true } = useFlags();
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { getVisibleNodes } = useGraphControls();
    const { getScopedStorageKey } = useLocalStorage();
    const [cachedHiddenColumnsLoaded, setCachedHiddenColumnsLoaded] = useState(false);
    const [leftColumnOpen, setLeftColumnOpen] = useState(true);

    const closeDataBrowser = () => {
        if (mapState.dataBrowserOpen) {
            dispatch({ type: 'toggle-data-browser' });
        }
    };
    useHotkeys('esc', closeDataBrowser, [mapState.dataBrowserOpen]);

    const [facetFilter, setFacetFilter] = useState('');

    const [toggleFilters, setToggleFilters] = useState(false);

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            fuzzyNodeDisplayName: fuzzyNodeDisplayNameFilterFn,
            greaterThan: filterGreaterThan,
        }),
        [],
    );

    const sortTypes = useMemo(
        () => ({
            nodeDisplayName: sortTableNodes,
            policyStats: sortPolicyStats,
            greaterThan: sortGreaterThan,
        }),
        [],
    );

    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            minWidth: 100,
            width: 300,
            maxWidth: 800,
        }),
        [],
    );

    const data = useMemo(() => {
        const data: DataBrowserFields[] = [];

        const visibleNodes = getVisibleNodes();
        const visibleLayers = {
            devices: true,
            actors: true,
            applications: true,
            targets: true,
            identities: true,
        };
        const targets = visibleNodes.filter((n) => n.label === 'target');

        targets.map((target) => {
            // If this target is hidden then don't include it in the CSV
            if (isNodeHidden(target, mapState.visible)) return;

            target.neighbors.map((identity) => {
                if (isNodeHidden(identity, mapState.visible)) return;

                const targetId = String(target.id);
                const targetDomain = target.props.serviceDomain;

                const identityId = String(identity.id);

                const actor = identity.neighbors.find((n) => n.label === 'actor');
                let actorId;
                if (actor && !isNodeHidden(actor, visibleLayers)) {
                    actorId = String(actor?.id);
                }

                const device = identity.neighbors.find((n) => n.label === 'device');
                let deviceId;
                if (device && !isNodeHidden(device, visibleLayers)) {
                    deviceId = String(device?.id);
                }

                const application = identity.neighbors.find((n) => n.label === 'application');
                let applicationId;
                if (application && !isNodeHidden(application, visibleLayers)) {
                    applicationId = String(application?.id);
                }

                const link = identity.links.find((l) => l.source === target);

                if (actor && device && application && link) {
                    const row = {
                        targetId: targetId || '',
                        target: target,
                        targetDomain: targetDomain || '',
                        identityId: identityId,
                        identity: identity,
                        actorId: actorId || '',
                        actor: actor,
                        trustScore: 0,
                        mfaRequired: link.props.TAG_FACTOR_REQUIRE_MFA ? 'True' : 'False',
                        mfaStatus:
                            link.props.TAG_FACTOR_OK && link.props.TAG_FACTOR_FAILED
                                ? 'Success with Failed'
                                : link.props.TAG_FACTOR_OK && link.props.TAG_FACTOR_REJECTED
                                  ? 'Success with Rejected'
                                  : !link.props.TAG_FACTOR_REQUIRE_MFA && link.props.TAG_FACTOR_OK
                                    ? 'N/A'
                                    : link.props.TAG_FACTOR_OK
                                      ? 'Success'
                                      : link.props.TAG_FACTOR_FAILED
                                        ? 'Failed'
                                        : link.props.TAG_FACTOR_REJECTED
                                          ? 'Rejected'
                                          : 'Unknown',
                        mfaDetails: getMfaDetails(link).join(', '),
                        deviceId: deviceId || '',
                        device: device,
                        applicationId: applicationId || '',
                        application: application,
                        success: link.policyStatsAbsolute?.success || 0,
                        challenge: link.policyStatsAbsolute?.warning || 0,
                        failure: link.policyStatsAbsolute?.critical || 0,
                        accessRate: link.policyStats,
                        successRate: link.policyStats?.success || 0,
                        challengeRate: link.policyStats?.warning || 0,
                        failureRate: link.policyStats?.critical || 0,
                    };
                    data.push(row);
                }
            });
        });

        return data;
    }, [getVisibleNodes, mapState.visible]);

    // Columns based on the data
    const columns: Column<DataBrowserFields>[] = useMemo(() => getDataBrowserColumns(policy), [policy]);

    const defaultHiddenColumns = useMemo(() => {
        return [
            'targetId',
            'identityId',
            'actorId',
            'deviceId',
            'applicationId',
            'targetDomain',
            'success',
            'challenge',
            'failure',
        ];
    }, []);

    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex, pageSize, hiddenColumns, filters },
        allColumns,
        rows,
        setHiddenColumns,
        setAllFilters,
    } = useTable<DataBrowserFields>(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            sortTypes,
            disableMultiSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: 40,
                hiddenColumns: defaultHiddenColumns,
                sortBy: [{ id: 'actor' }],
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
        useBlockLayout,
        useResizeColumns,
    );

    useEffect(() => {
        if (hiddenColumns && cachedHiddenColumnsLoaded) {
            const key = getScopedStorageKey('dataBrowser');
            if (key) {
                const cachedHiddenColumns = localStorage.getItem(key);
                if (cachedHiddenColumns !== JSON.stringify(hiddenColumns)) {
                    localStorage.setItem(key, JSON.stringify(hiddenColumns));
                    console.debug('Saved hidden columns to local storage');
                }
            }
        }
    }, [cachedHiddenColumnsLoaded, getScopedStorageKey, hiddenColumns]);

    useEffect(() => {
        const key = getScopedStorageKey('dataBrowser');
        if (!cachedHiddenColumnsLoaded) {
            if (key) {
                const hiddenColumns = localStorage.getItem(key);
                if (hiddenColumns) {
                    setHiddenColumns(JSON.parse(hiddenColumns));
                    console.debug('Loaded hidden columns from local storage');
                    setCachedHiddenColumnsLoaded(true);
                } else {
                    console.debug('No hidden columns found in local storage');
                    setCachedHiddenColumnsLoaded(true);
                }
            }
        }
    }, [cachedHiddenColumnsLoaded, getScopedStorageKey, setHiddenColumns]);

    const exportToCsv = () => {
        const columnHeadersByAccessor: Record<string, string> = {};
        columns.map((column) => {
            if (column.accessor) {
                const accessor = column.accessor as string;
                if (!hiddenColumns || !hiddenColumns.includes(accessor)) {
                    columnHeadersByAccessor[accessor] = column.Header as string;
                }
            }
        });

        const csvData = rows.map((row) => {
            const data = row.original;
            const csvRow: Record<string, string> = {};

            Object.entries(columnHeadersByAccessor).map(([accessor, header]) => {
                if (data[accessor as keyof DataBrowserFields]) {
                    const value = data[accessor as keyof DataBrowserFields];
                    let field = '';
                    switch (accessor) {
                        case 'node':
                        case 'actor':
                        case 'device':
                        case 'application':
                        case 'identity':
                        case 'target':
                            field = getDisplayName(value as Node);
                            break;
                        case 'accessRate':
                            const v = value as PolicyStats;
                            field = `Success ${v?.success || 0}%, Challenge ${v?.warning || 0}%, Failure ${
                                v?.critical || 0
                            }%`;
                            break;
                        default:
                            field = String(value);
                    }
                    csvRow[`"${header}"`] = `"${field}"`;
                }
            });

            return csvRow;
        });

        return csvData;
    };

    if (!mapState.dataBrowserOpen) {
        return <></>;
    }

    return (
        <Transition.Root show={true} as={Fragment}>
            <div
                className="fixed bottom-0 z-10 inset-0 top-[56px] h-[calc(100vh-56px)] overflow-hidden"
                data-test="data-browser-window"
            >
                <Helmet>
                    <title>Access Data Browser</title>
                </Helmet>
                <div className="flex items-end justify-center text-center sm:block sm:p-0">
                    <div
                        className="fixed top-[56px] h-[calc(100vh-56px)] w-[100vw] after:inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                        onClick={closeDataBrowser}
                    />
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>

                    <div className="absolute top-4 left-[50%] translate-x-[-50%] inline-block bg-gray-800 rounded-lg text-left shadow-xl transform transition-all w-[90vw] mt-14 align-top md:mt-5">
                        <div
                            id="Header"
                            className="flex justify-between items-start p-4 pt-3 pb-3 border-b border-gray-700"
                        >
                            <h1 className="text-md font-semibold tracking-wider">Access Data Browser</h1>
                        </div>
                        <div className="flex flex-shrink items-stretch relative h-[calc(100vh-168px)]  overflow-hidden">
                            <div
                                className={classNames(
                                    'px-4 py-3 border-r border-gray-700 xs:collapse overflow-scroll h-[calc(100vh-208px)',
                                    leftColumnOpen ? 'w-64' : 'hidden',
                                )}
                            >
                                <div className="mb-5 relative overflow-y-scroll">
                                    <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2 flex justify-between align-middle">
                                        Filters
                                    </p>
                                    <button
                                        type="button"
                                        className="btn w-full text-xs p-2 mb-3 rounded-md focus:ring-0"
                                        onClick={() => {
                                            setToggleFilters(!toggleFilters);
                                        }}
                                    >
                                        <FunnelIcon className="h-4 w-4 mr-1" />{' '}
                                        {toggleFilters ? 'Hide Filters' : 'Show Filters'}
                                    </button>
                                    {filters.length > 0 && (
                                        <button
                                            type="button"
                                            className="btn w-full text-xs p-2 mb-3 rounded-md mt-2 focus:ring-0"
                                            onClick={() => {
                                                setToggleFilters(false);
                                                setAllFilters([]);
                                            }}
                                        >
                                            <XCircleIcon className="h-4 w-4 mr-1" /> Clear Filters
                                        </button>
                                    )}
                                    <p className="uppercase font-semibold tracking-widest text-xs text-gray-400 mb-2">
                                        Facets
                                    </p>
                                    <div className="flex items-center mt-3">
                                        <input
                                            type="search"
                                            className="input-gray text-xs w-full pl-8"
                                            placeholder="Search facets..."
                                            onChange={(e) => {
                                                setFacetFilter(e.target.value);
                                            }}
                                        />
                                        <MagnifyingGlassIcon className="absolute left-2 w-4 h-4 text-gray-400" />
                                    </div>

                                    <div className="bg-gray-600 rounded-md py-3 px-2 my-5 space-y-3 border border-gray-500">
                                        {allColumns
                                            .filter((c) =>
                                                c.Header?.toString().toLowerCase().includes(facetFilter.toLowerCase()),
                                            )
                                            .map((column) => (
                                                <div key={column.id}>
                                                    <label className="flex items-center">
                                                        <input
                                                            type="checkbox"
                                                            className="chk-box w-4 h-4 bg-gray-500 focus:ring-0 focus:outline-none"
                                                            {...column.getToggleHiddenProps()}
                                                        />
                                                        <p className="pl-2 text-xs text-gray-300">
                                                            {column.Header as ReactNode}
                                                        </p>
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-700/50 flex-1 h-[calc(100vh-168px)] shadow-[2px_2px_4px_0_rgba(0,0,0,0.25)_inset] pb-20 overflow-hidden">
                                <div className="overflow-scroll flex-1 h-[calc(100vh-208px)]">
                                    <table {...getTableProps()} className="w-full text-left text-xs mb-4">
                                        <thead className="sticky top-0 bg-gray-700 z-10">
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th
                                                            {...column.getHeaderProps()}
                                                            className="pt-2 px-2 align-middle bg-gray-700"
                                                        >
                                                            <div className="flex items-center mb-2">
                                                                <span>{column.render('Header')}</span>
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <ChevronDownIcon
                                                                            className="h-5 w-5"
                                                                            {...column.getSortByToggleProps()}
                                                                        />
                                                                    ) : (
                                                                        <ChevronUpIcon
                                                                            className="h-5 w-5"
                                                                            {...column.getSortByToggleProps()}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <ChevronUpDownIcon
                                                                        className="h-5 w-5"
                                                                        {...column.getSortByToggleProps()}
                                                                    />
                                                                )}
                                                                <div
                                                                    {...column.getResizerProps()}
                                                                    className={
                                                                        column.isResizing
                                                                            ? 'resizerIsResizing'
                                                                            : 'resizer'
                                                                    }
                                                                />
                                                            </div>
                                                            {toggleFilters && (
                                                                <div className="pl-1">
                                                                    {column.canFilter
                                                                        ? column.render('Filter', { data })
                                                                        : null}
                                                                </div>
                                                            )}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()} className="overflow-y-hidden pb-[40px]">
                                            {data.length > 0 &&
                                                page.map((row) => {
                                                    prepareRow(row);
                                                    return (
                                                        <tr
                                                            {...row.getRowProps()}
                                                            className="text-gray-300 hover:bg-gray-900"
                                                        >
                                                            {row.cells.map((cell, idx) => {
                                                                switch (cell.column.id) {
                                                                    case 'mfa':
                                                                        return <MfaCell cell={cell} key={idx} />;
                                                                    case 'provider':
                                                                        return <ProviderCell cell={cell} key={idx} />;
                                                                    case 'date':
                                                                        return <DateCell cell={cell} key={idx} />;
                                                                    case 'node':
                                                                    case 'actor':
                                                                    case 'device':
                                                                    case 'application':
                                                                    case 'identity':
                                                                    case 'target':
                                                                        return <NodeCell cell={cell} key={idx} />;
                                                                    case 'accessRate':
                                                                        return (
                                                                            <StratifiedPercentageCell
                                                                                cell={cell}
                                                                                key={idx}
                                                                            />
                                                                        );
                                                                    default:
                                                                        return <DefaultCell cell={cell} key={idx} />;
                                                                }
                                                            })}
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="absolute left-0 w-full bottom-0">
                                    <div className="pagination bg-gray-700 text-xs flex items-center p-2 justify-between">
                                        <div className="flex space-x-2">
                                            <button
                                                className="btn bg-gray-800 text-xs py-1 px-2 flex items-center justify-center"
                                                onClick={() => setLeftColumnOpen(!leftColumnOpen)}
                                            >
                                                {leftColumnOpen ? (
                                                    <>
                                                        Toggle sidebar
                                                        <ChevronDoubleLeftIcon className="h-4 w-4 text-gray-300 ml-1" />
                                                    </>
                                                ) : (
                                                    <>
                                                        Toggle sidebar
                                                        <ChevronDoubleRightIcon className="h-4 w-4 text-gray-300 ml-1" />
                                                    </>
                                                )}
                                            </button>
                                            <CsvDownloader
                                                datas={exportToCsv}
                                                className={
                                                    'btn bg-gray-800 text-xs py-1 px-2 flex items-center justify-center'
                                                }
                                                filename="double-zero-data-browser-export.csv"
                                            >
                                                <DocumentArrowDownIcon className="h-4 w-4 mr-1" /> Download CSV
                                            </CsvDownloader>
                                        </div>

                                        {rows.length > 0 ? (
                                            <div>
                                                Page
                                                <span className="ml-1">
                                                    {pageOptions.length === 0 ? 0 : pageIndex + 1} of{' '}
                                                    {pageOptions.length}
                                                </span>
                                                <span className="ml-1">
                                                    -{'    '}
                                                    {rows.length} records (showing {pageIndex * pageSize + 1} to{' '}
                                                    {Math.min(rows.length, pageIndex * pageSize + pageSize)})
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="text-gray-400">No records found</div>
                                        )}

                                        <div className="flex items-center space-x-0.5">
                                            <button
                                                className="btn bg-gray-800 text-xs p-1"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            >
                                                <ChevronDoubleLeftIcon className="h-4 w-4" />
                                            </button>
                                            <button
                                                className="btn bg-gray-800 text-xs p-1"
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            >
                                                <ChevronLeftIcon className="h-4 w-4" />
                                            </button>
                                            <button
                                                className="btn bg-gray-800 text-xs p-1"
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            >
                                                <ChevronRightIcon className="h-4 w-4" />
                                            </button>
                                            <button
                                                className="btn bg-gray-800 text-xs p-1"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            >
                                                <ChevronDoubleRightIcon className="h-4 w-4" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="text-white text-xs rounded-full p-1 bg-gray-900 border border-gray-500 hover:border-gray-200 absolute -top-3 -right-3 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                            onClick={() => {
                                closeDataBrowser();
                            }}
                        >
                            <XMarkIcon className="h-4 w-4 text-gray-200" />
                        </button>
                    </div>
                </div>
            </div>
        </Transition.Root>
    );
};
