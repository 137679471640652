import { useLazyQuery, useQuery } from '@apollo/client';
import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
} from '@heroicons/react/20/solid';
import {
    ArrowDownTrayIcon,
    ArrowUturnLeftIcon,
    ClipboardDocumentIcon,
    DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { ChartBarIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { GET_DETAILED_ACCESSES, GET_RAW_EVENT } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import {
    fuzzyTextFilterFn,
    fuzzyNodeDisplayNameFilterFn,
    filterGreaterThan,
    DefaultColumnFilter,
} from 'Library/TableComponents';
import { Tooltip } from 'Library/Tooltip';

import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Link, Node } from 'Types/types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Column, useTable, useFilters, useSortBy, usePagination, useBlockLayout, useResizeColumns } from 'react-table';
import {
    classNames,
    getDisplayName,
    getOperatingSystemDisplayNameFromNode,
    outcomeLookup,
    providerNameLookup,
    providerProviderTypeLookup,
} from 'Utilities/utils';

export const Events = (): JSX.Element => {
    const { mapState } = useContext(IdentityMapContext);
    const link = mapState.selectedLink;

    // react-force-graph will only render one link between nodes even if
    // there are multiple links between the same two nodes in the data model
    // so when a selection is made, it's only one of N links that may exist
    //
    // to handle this, we compute all the links between the nodes based on
    // currently selected link
    const allLinksBetweenSourceAndTarget = useMemo(() => {
        if (link) {
            const { source, target } = link;
            return source.links.filter((l) => l.target === target);
        }
    }, [link]);

    if (link && allLinksBetweenSourceAndTarget) {
        const { source, target } = link;
        return <EventsPanel source={source} target={target as Node} links={allLinksBetweenSourceAndTarget} />;
    } else {
        return <div>No link selected</div>;
    }
};

interface EventsPanelProps {
    source: Node;
    target: Node;
    links: Link[];
}

export const EventsPanel = ({ source, target, links }: EventsPanelProps): JSX.Element => {
    const { enableAccessEvents } = useFlags();
    const { dispatch } = useContext(IdentityMapContext);
    let eventDetails = null;
    switch (source.label) {
        case 'target':
            eventDetails = enableAccessEvents ? (
                <DetailedAccessEvents source={source} target={target} links={links} />
            ) : (
                <AccessEvents source={source} target={target} links={links} />
            );
            break;
        case 'actor':
            eventDetails = <ActorEvents source={source} target={target} links={links} />;
            break;
        case 'device':
            eventDetails = <DeviceEvents source={source} target={target} links={links} />;
            break;
    }
    return (
        <div className="w-screen flex items-center justify-center">
            <div className="rounded-md border border-gray-700 m-5 w-5/7 mx-auto bg-gray-800 text-xs text-gray-200 hover:border-gray-400 pointer-events-auto relative">
                {eventDetails}
                {eventDetails && (
                    <button
                        onClick={() => dispatch({ type: 'toggle-actions' })}
                        className="text-white text-xs rounded-full p-0.5 bg-gray-800 border border-gray-500 hover:border-gray-200 absolute -top-2.5 -right-2.5 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                    >
                        <XMarkIcon className="h-3.5 w-3.5 text-gray-200" />
                    </button>
                )}
            </div>
        </div>
    );
};

type AccessEventData = {
    accessOutcome: string;
    accessOutcomeDetail: string;
    ipAddress: string;
    processorType: string;
    providerId: string;
    eventId: string;
    trustScore: number;
};

type RawEvent = {
    raw: Record<string, unknown>[];
    dfp?: Record<string, unknown>;
};

const DetailedAccessEvents = ({ source, target }: EventsPanelProps): JSX.Element => {
    const { policy, enableDetailedAccessEventPolicyLinks } = useFlags();
    const tenantId = useTenant();
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const [rawEvent, setRawEvent] = useState<RawEvent | undefined>();
    const [selectedEvent, setSelectedEvent] = useState<AccessEventData | undefined>();
    const [accessEvents, setAccessEvents] = useState<AccessEventData[]>([]);

    const filter = useMemo(() => {
        return {
            tenantId,
            groups: {
                operator: 'FILTER_OPERATOR_AND',
                elements: [
                    {
                        targetQuery: {
                            targetIds: [source.id],
                        },
                    },
                    {
                        identityQuery: {
                            identityIds: [target.id],
                        },
                    },
                ],
            },
        };
    }, [source.id, target.id, tenantId]);

    const { loading, error, data } = useQuery(GET_DETAILED_ACCESSES, {
        variables: {
            tenantId,
            startTime: mapState.selectedTime[0],
            endTime: mapState.selectedTime[1],
            filter: filter,
        },
        skip: !tenantId || mapState.timelineDragging,
    });

    const [getRawEvent, { loading: rawEventLoading, error: rawEventError }] = useLazyQuery(GET_RAW_EVENT);

    const getRawEventJson = useCallback(
        async function (eventId: string, providerType: string, providerId: string) {
            try {
                const result = await getRawEvent({
                    variables: {
                        tenantId,
                        eventId,
                        providerType: providerProviderTypeLookup(providerType),
                        providerId,
                    },
                });

                const rawEvent: { type: string; data: string }[] = result.data.getRawEvent;

                const raw = rawEvent.find((e) => e.type === 'raw');
                const dfp = rawEvent.find((e) => e.type === 'dfp');

                const parsedEvent = {
                    raw: JSON.parse(raw?.data || '{}'),
                    dfp: dfp ? JSON.parse(dfp?.data) : undefined,
                };

                setRawEvent(parsedEvent);
            } catch (error) {
                console.log(error);
            }
        },
        [getRawEvent, tenantId],
    );

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            fuzzyNodeDisplayName: fuzzyNodeDisplayNameFilterFn,
            greaterThan: filterGreaterThan,
        }),
        [],
    );

    const defaultColumn: Partial<Column<AccessEventData>> = useMemo(
        () => ({
            Filter: DefaultColumnFilter<AccessEventData>,
            width: 150,
        }),
        [],
    );

    const columns: Column<AccessEventData>[] = useMemo(
        () =>
            [
                {
                    Header: 'Time',
                    accessor: 'eventTime',
                    width: 160,
                    Cell: ({ value }: { value: string }) => format(new Date(parseInt(value)), 'EEE MMM do HH:mm:ss'),
                },
                {
                    Header: 'Outcome',
                    accessor: 'accessOutcome',
                    width: 100,
                    Cell: ({ value }: { value: string }) => outcomeLookup(value),
                },
                {
                    Header: 'Detail',
                    accessor: 'accessOutcomeDetail',
                    width: 200,
                    Cell: ({ value }: { value: string }) =>
                        value == '' || value == 'Other.' ? (
                            <span className="text-gray-600">N/A</span>
                        ) : (
                            <div className="flex items-center justify-start relative">
                                <div className="max-w-[200px] truncate pr-7">{value}</div>

                                <Tooltip
                                    className="w-auto max-w-[300px] px-2 py-1.5 text-left rounded-md text-xs bg-black/90"
                                    label={`${value} - Click to copy to clipboard`}
                                >
                                    <InformationCircleIcon
                                        className="text-gray-600 w-4 h-4 ml-3 absolute right-2 active:text-gray-400"
                                        onClick={() => {
                                            navigator.clipboard.writeText(value);
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        ),
                },
                {
                    Header: 'IP Address',
                    accessor: 'ipAddress',
                    width: 200,
                    Cell: ({ value }: { value: string }) => (
                        <div className="flex items-center justify-start relative">
                            <div className="max-w-[200px] truncate pr-7">{value}</div>

                            <Tooltip
                                className="w-auto max-w-[300px] px-2 py-1.5 text-left rounded-md text-xs bg-black/90"
                                label={`${value} - Click to copy to clipboard`}
                            >
                                <InformationCircleIcon
                                    className="text-gray-600 w-4 h-4 ml-3 absolute right-2 active:text-gray-400"
                                    onClick={() => {
                                        navigator.clipboard.writeText(value);
                                    }}
                                />
                            </Tooltip>
                        </div>
                    ),
                },
                {
                    Header: 'Provider',
                    accessor: 'processorType',
                    width: 120,
                    Cell: ({ value }: { value: string }) => providerNameLookup(value),
                },
                {
                    Header: 'Provider Policy',
                    id: 'policyName',
                    width: 250,
                    Cell: () => (
                        <div className="w-full flex items-center justify-between">
                            <div className="max-w-[250px] truncate pr-7">{getDisplayName(source)} sign-on Policy</div>
                            <button
                                className="btn p-1 rounded-md"
                                onClick={() => {
                                    const actor = target.neighbors.find((n) => n.label === 'actor');

                                    dispatch({ type: 'set-permissions-node', node: actor });
                                    if (!mapState.navigatorOpen) {
                                        dispatch({ type: 'toggle-navigator' });
                                    }
                                }}
                            >
                                <Tooltip label="View in Permissions Navigator" placement="top">
                                    <MagnifyingGlassIcon className="h-4 w-4 text-gray-200" />
                                </Tooltip>
                            </button>
                        </div>
                    ),
                },
                {
                    Header: 'Trust Profile',
                    accessor: 'trustProfile',
                    width: 150,
                    Cell: () => (
                        <div className="w-full flex items-center justify-between">
                            <span>Default 100</span>
                            <button
                                className="btn p-1 rounded-md"
                                onClick={() => {
                                    if (source) {
                                        dispatch({ type: 'set-selected-policy-target', target: source });
                                    }
                                }}
                            >
                                <Tooltip label="View Adaptive Trust Policy" placement="top">
                                    <MagnifyingGlassIcon className="h-4 w-4 text-gray-200" />
                                </Tooltip>
                            </button>
                        </div>
                    ),
                },
                {
                    Header: 'Trust Score',
                    accessor: 'trustScore',
                    width: 120,
                    Cell: ({ value }) => (
                        <div className="w-full flex items-center justify-between">
                            {value > -1 ? (
                                <>
                                    <span>{value}</span>
                                    <button
                                        className="btn p-1 rounded-md"
                                        onClick={() => {
                                            const actor = target.neighbors.find((n) => n.label === 'actor');
                                            if (actor) {
                                                dispatch({ type: 'set-profile-node', node: actor });
                                                dispatch({ type: 'set-profile-window', open: true });
                                            }
                                        }}
                                    >
                                        <Tooltip label="View Trust History" placement="top">
                                            <ChartBarIcon className="h-4 w-4 text-gray-200" />
                                        </Tooltip>
                                    </button>
                                </>
                            ) : (
                                <Tooltip
                                    label="No policies match this event, so a Trust Score has not been calculated."
                                    placement="top"
                                >
                                    <div className="w-full flex items-center justify-between">
                                        <span className="text-gray-600">N/A</span>
                                        <InformationCircleIcon className="h-4 w-4 text-gray-600 mx-1" />
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    ),
                },

                {
                    Header: 'Raw Event',
                    id: 'rawEvent',
                    width: 120,
                    Cell: ({ row }) => (
                        <div className="w-full flex items-center justify-between">
                            <span>JSON</span>
                            <button
                                className="btn p-1 rounded-md"
                                onClick={() => {
                                    setSelectedEvent(row.original);
                                    getRawEventJson(
                                        row.original.eventId,
                                        row.original.processorType,
                                        row.original.providerId,
                                    );
                                }}
                            >
                                <Tooltip label="View raw event data" placement="right">
                                    <DocumentTextIcon className="h-4 w-4 text-gray-200" />
                                </Tooltip>
                            </button>
                        </div>
                    ),
                },
            ] as Column<AccessEventData>[],
        [dispatch, getRawEventJson, mapState.navigatorOpen, source, target.neighbors],
    );

    const loadingData = useMemo(() => Array(5).fill({}), []);

    const defaultHiddenColumns = useMemo(() => {
        const columns = [];

        if (accessEvents) {
            const eventsWithDetails = accessEvents.filter((e) => e.accessOutcomeDetail !== '');
            console.log('events with details', eventsWithDetails);
            if (eventsWithDetails.length === 0) {
                columns.push('accessOutcomeDetail');
            }
        }

        if (!policy && !enableDetailedAccessEventPolicyLinks) {
            columns.concat(['policyName', 'trustProfile', 'trustScore']);
        }
        if (policy && !enableDetailedAccessEventPolicyLinks) {
            columns.push('policyName', 'trustProfile');
        }

        return columns;
    }, [accessEvents, enableDetailedAccessEventPolicyLinks, policy]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
    } = useTable<AccessEventData>(
        {
            columns: columns,
            data: loading ? loadingData : accessEvents,
            defaultColumn,
            filterTypes,
            disableMultiSort: true,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
                sortBy: [{ id: 'eventTime' }],
                hiddenColumns: defaultHiddenColumns,
            },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
        useBlockLayout,
        useResizeColumns,
    );

    useEffect(() => {
        if (data && data.getIdentityMap && data.getIdentityMap.edges) {
            const accessEvents = data.getIdentityMap.edges.map((e: any) => {
                return {
                    accessOutcome: e.props.accessOutcome,
                    accessOutcomeDetail: e.props.accessOutcomeDetail,
                    ipAddress: e.props.ipAddress,
                    processorType: e.props.processorType,
                    providerId: e.props.providerId,
                    eventId: e.props.eventId,
                    eventTime: e.props.eventTime,
                    trustScore: e.props.trustScore,
                };
            });
            setAccessEvents(accessEvents);
        }
    }, [data]);

    if (rawEvent) {
        return (
            <div className="w-[830px] max-w-[calc(100vw-80px)] h-[830px] max-h-[calc(100vh-230px)] relative">
                <div className="flex justify-between space-x-2 items-center absolute -top-3 -left-3">
                    <button
                        className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                        onClick={() => {
                            setRawEvent(undefined);
                        }}
                    >
                        <Tooltip label="Return" placement="left">
                            <ArrowUturnLeftIcon className="h-4 w-4 text-gray-200" />
                        </Tooltip>
                    </button>
                    <button
                        className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                        onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(rawEvent, null, 2));
                        }}
                    >
                        <Tooltip label="Copy to clipboard" placement="top">
                            <ClipboardDocumentIcon className="h-4 w-4 text-gray-200" />
                        </Tooltip>
                    </button>
                    <button
                        className="text-white text-xs rounded-full p-1 bg-gray-800 border border-gray-500 hover:border-gray-200 shadow-md focus:border focus:border-gray-300 focus:outline-none focus:ring-0"
                        onClick={() => {
                            const name = `double-zero-raw-event-${selectedEvent?.processorType}-${selectedEvent?.eventId}.json`;
                            const data = JSON.stringify(rawEvent, null, 2);
                            const blob = new Blob([data], { type: 'application/json' });
                            const url = window.URL.createObjectURL(blob);

                            const a = document.createElement('a');
                            a.href = url;
                            a.download = name;
                            a.click();

                            // Cleanup
                            window.URL.revokeObjectURL(url);
                        }}
                    >
                        <Tooltip label="Download JSON" placement="right">
                            <ArrowDownTrayIcon className="h-4 w-4 text-gray-200" />
                        </Tooltip>
                    </button>
                </div>
                <div className="h-full w-full overflow-scroll">
                    <pre className="text-xxs text-gray-200 mt-4">
                        {/* {JSON.stringify(rawEvent, null, 2)} */}
                        <DiffComponent raw={rawEvent.raw} dfp={rawEvent.dfp} />
                    </pre>
                </div>
            </div>
        );
    }

    return (
        <table className="w-[830px]" {...getTableProps()}>
            {rawEventError && (
                <tbody>
                    <tr>
                        <td colSpan={5} className="px-2 py-3">
                            Error loading raw event data: {rawEventError.message}
                        </td>
                    </tr>
                </tbody>
            )}
            {error && (
                <tbody>
                    <tr>
                        <td colSpan={5} className="px-2 py-3">
                            Error loading access events: {error.message}
                        </td>
                    </tr>
                </tbody>
            )}
            {!rawEventLoading && !rawEventError && !loading && !error && accessEvents.length > 0 && (
                <>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="text-left border-b border-gray-600">
                                {headerGroup.headers.map((column) => (
                                    <th className="px-2 py-2" {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="font-normal border-b border-gray-600 text-gray-300">
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="hover:bg-gray-900">
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()} className="px-2 py-1 !flex !items-center">
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </>
            )}
            <tfoot>
                <tr className="font-normal">
                    <th colSpan={5} className="px-1 py-3 text-left">
                        <div className="flex items-center space-x-4 justify-between">
                            <div className="flex items-center">
                                {source.icon && <img src={source.icon} className="h-5" />}{' '}
                                <p className="mx-2">{source.props.displayName} access events by</p>
                                {target.icon && <img src={target.icon} className="h-5" />}{' '}
                                <p className="ml-2">{target.props.displayName}</p>
                                {(loading || rawEventLoading) && <div className="ml-2 h-5 w-5 loader" />}
                            </div>
                            {pageCount > 1 && (
                                <div className="flex items-center space-x-0.5">
                                    <button
                                        className="btn bg-gray-800 text-xs p-1"
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        <ChevronDoubleLeftIcon className="h-4 w-4" />
                                    </button>
                                    <button
                                        className="btn bg-gray-800 text-xs p-1"
                                        onClick={() => previousPage()}
                                        disabled={!canPreviousPage}
                                    >
                                        <ChevronLeftIcon className="h-4 w-4" />
                                    </button>
                                    <button
                                        className="btn bg-gray-800 text-xs p-1"
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                        <ChevronRightIcon className="h-4 w-4" />
                                    </button>
                                    <button
                                        className="btn bg-gray-800 text-xs p-1"
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        <ChevronDoubleRightIcon className="h-4 w-4" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
    );
};

const DiffComponent: React.FC<RawEvent> = ({ raw, dfp }) => {
    const renderObject = (obj: any, dfpObj: any = {}, parent: string) => {
        if (obj === null || obj === undefined) {
            return 'null';
        }

        if (Array.isArray(obj)) {
            return (
                <ul className="ml-4 space-y-1">
                    {obj.map((item, index) => (
                        <li key={index}>{renderObject(item, dfpObj[index], parent)}</li>
                    ))}
                </ul>
            );
        }

        if (typeof obj !== 'object') {
            return obj.toString();
        }

        return (
            <div className="ml-4 space-y-1 text-gray-200">
                {Object.entries(obj).map(([localKey, value]) => {
                    let chosenKey = '';

                    const parentKey = parent + localKey;

                    let dfpValue = dfpObj[localKey];

                    if (dfpValue === undefined) {
                        dfpValue = dfpObj[parentKey];

                        if (dfpValue) {
                            console.log('Found dfp value in parent namespace', parentKey);
                            chosenKey = parentKey;
                        }
                    } else {
                        chosenKey = localKey;
                    }

                    const predKey = `${chosenKey}_pred`;
                    const lossKey = `${chosenKey}_loss`;
                    const zLossKey = `${chosenKey}_z_loss`;

                    const dfpPred = dfpObj[predKey];
                    const dfpLoss = dfpObj[lossKey];
                    const dfpZLoss = dfpObj[zLossKey];

                    const isDifferent = dfpValue && dfpPred && dfpPred !== value;

                    return (
                        <div key={localKey}>
                            <span className="font-medium text-gray-400">{localKey}:</span>{' '}
                            {isDifferent ? (
                                <Tooltip
                                    label={`Prediction: ${dfpPred}, Loss Score: ${dfpLoss}, zLoss Score: ${dfpZLoss}
                            `}
                                    placement="right"
                                >
                                    <span className={classNames(isDifferent ? 'bg-blue-700 p-1' : '')}>
                                        {`${value}`}
                                    </span>
                                </Tooltip>
                            ) : (
                                <span>{renderObject(value, dfpObj, parent + localKey)}</span>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    return <pre style={{ fontFamily: 'monospace' }}>{renderObject(raw, dfp, '')}</pre>;
};

const AccessEvents = ({ source, target, links }: EventsPanelProps): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);
    const { policy } = useFlags();

    const rows = links.map((l, index) => (
        <tr key={index} className="bg-gray-700 border-b border-gray-600 capitalize">
            <td className="px-4 py-3">0</td>
            <td className="px-4 py-3">
                {l.policyStatsAbsolute?.success} ({l.policyStats?.success}%)
            </td>
            <td className="px-4 py-3">
                {l.policyStatsAbsolute?.warning} ({l.policyStats?.warning}%)
            </td>
            <td className="px-4 py-3">
                {l.policyStatsAbsolute?.critical} ({l.policyStats?.critical}%)
            </td>
            <td className="px-4 py-3">
                {l.policyStatsAbsolute?.neutral} ({l.policyStats?.neutral})%
            </td>
        </tr>
    ));

    return (
        <>
            <table className="w-full">
                <thead>
                    <tr className="text-left border-b border-gray-600">
                        <th className="px-4 py-3" title="Accesses that did not fit another category">
                            <div className="flex items-center">
                                <span className="inline-block w-2 h-2 mr-2 bg-blue-500 rounded-full" />
                                <span className="inline-block align-middle">Trust Score</span>
                            </div>
                        </th>
                        <th className="px-4 py-3" title="Accesses allowed by policy">
                            <div className="flex items-center">
                                <span className="inline-block w-2 h-2 mr-2 bg-lime-600 rounded-full" />
                                <span className="inline-block align-middle">Success</span>
                            </div>
                        </th>
                        <th className="px-4 py-3" title="Accesses that may be cause for concern">
                            <div className="flex items-center">
                                <span className="inline-block w-2 h-2 mr-2 bg-yellow-500 rounded-full" />
                                <span className="inline-block align-middle">Challenge</span>
                            </div>
                        </th>
                        <th className="px-4 py-3" title="Accesses that should be immediately inspected">
                            <div className="flex items-center">
                                <span className="inline-block w-2 h-2 mr-2 bg-red-500 rounded-full" />
                                <span className="inline-block align-middle">Failure</span>
                            </div>
                        </th>
                        <th className="px-4 py-3" title="Accesses that did not fit another category">
                            <div className="flex items-center">
                                <span className="inline-block w-2 h-2 mr-2 bg-gray-500 rounded-full" />
                                <span className="inline-block align-middle">Other</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
                <tfoot>
                    <tr className="font-normal">
                        <th colSpan={4} className="px-4 py-3 text-left">
                            <div className="flex items-center font-normal">
                                {source.icon && <img src={source.icon} className="h-5" />}{' '}
                                <p className="mx-2">{source.props.displayName} access events by</p>
                                {target.icon && <img src={target.icon} className="h-5" />}{' '}
                                <p className="ml-2">{target.props.displayName}</p>
                            </div>
                        </th>
                    </tr>
                    {policy && (
                        <tr className="">
                            <th colSpan={4} className="px-4 py-3 text-left">
                                <div className="flex items-center">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-500 mr-2" />
                                    <button
                                        className="font-normal hover:text-blue-500"
                                        onClick={() => {
                                            dispatch({ type: 'set-profile-window', open: true });
                                            dispatch({ type: 'toggle-actions' });
                                        }}
                                    >
                                        Warning! Events were observed where the trust score is below the configured
                                        threshold
                                    </button>
                                </div>
                            </th>
                        </tr>
                    )}
                </tfoot>
            </table>
        </>
    );
};

const DeviceEvents = ({ source, target, links }: EventsPanelProps): JSX.Element => {
    const rows = links.map((l, index) => (
        <tr key={index} className="bg-gray-700 border-b border-gray-600 capitalize">
            <td className="px-4 py-3">{getOperatingSystemDisplayNameFromNode(source) || NOT_SPECIFIED}</td>
        </tr>
    ));

    return (
        <table className="w-full">
            <thead>
                <tr className="text-left border-b border-gray-600">
                    <th className="px-4 py-3">Operating System</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
            <tfoot>
                <tr className="">
                    <th colSpan={2} className="px-4 py-3 text-left">
                        <div className="flex items-center">
                            {source.icon && <img src={source.icon} className="h-5" />}{' '}
                            <p className="mx-2">{source.props.displayName} used by</p>
                            {target.icon && <img src={target.icon} className="h-5" />}{' '}
                            <p className="ml-2">{target.props.displayName}</p>
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
    );
};

const ActorEvents = ({ target }: EventsPanelProps): JSX.Element => {
    const resources = target.neighbors.filter((n) => n.label === 'target');

    const rows = resources.map((n, index) => (
        <tr key={index} className="bg-gray-700 border-b border-gray-600 capitalize">
            <td className="px-4 py-3">
                <div className="flex items-center">
                    {n.icon && <img src={n.icon} className="h-5" />} <p className="ml-2">{n.props.displayName}</p>
                </div>
            </td>
        </tr>
    ));

    return (
        <table className="w-full">
            <thead>
                <tr className="text-left border-b border-gray-600">
                    <th className="px-4 py-3">Targets Accessed</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
            <tfoot>
                <tr className="">
                    <th colSpan={0} className="px-4 py-3 text-left">
                        <div className="flex items-center">
                            <p className="mr-2">By Identity </p>
                            {target.icon && <img src={target.icon} className="h-5" />}{' '}
                            <p className="ml-2">{target.props.displayName}</p>
                        </div>
                    </th>
                </tr>
            </tfoot>
        </table>
    );
};

const NOT_SPECIFIED = <span className="text-gray-500">Not specified</span>;
