import { useQuery } from '@apollo/client';
import { NoSymbolIcon } from '@heroicons/react/24/solid';
import { GET_LOCATION_GRID } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { useEffect, useState } from 'react';
import { AttributeProps } from '../ProfileTypes';
import { ComposableMap, Geographies, Geography, ProjectionConfig, ZoomableGroup } from 'react-simple-maps';
import WorldCountriesGeos from 'Dashboard/Charts/WorldCountriesGeos.json';
import { Tooltip } from 'react-tooltip';
import { createGeoJSONFromGrid, GeoJSON } from 'Dashboard/Charts/WorldMapChart';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MapIcon } from '@heroicons/react/24/outline';

export type JoinedObjectProps = {
    modifiedKeys: Record<string, string | number>;
    unmodifiedKeys: Record<string, string | number>;
};

export const NodeLocationDisplay = ({
    node,
    startDate,
    endDate,
    projectionConfig,
}: AttributeProps & { startDate: number; endDate: number; projectionConfig?: ProjectionConfig }): JSX.Element => {
    const { enableWorldMap } = useFlags();
    const tenantId = useTenant();
    const [geojson, setGeojson] = useState<GeoJSON | undefined>();

    const query =
        node.label === 'actor'
            ? {
                  actorQuery: {
                      actorIds: [node.id],
                  },
              }
            : {
                  targetQuery: {
                      targetIds: [node.id],
                  },
              };

    const { loading, error, data } = useQuery(GET_LOCATION_GRID, {
        variables: {
            tenantId,
            startTimeInMs: startDate,
            endTimeInMs: endDate,
            filter: {
                tenantId,
                groups: {
                    operator: 'FILTER_OPERATOR_OR',
                    elements: [query],
                },
            },
        },
        skip: !enableWorldMap,
    });

    useEffect(() => {
        if (data) {
            const grid = data.getLocationGrid.grid;
            if (grid) {
                const geoJson = createGeoJSONFromGrid(grid, -90, 90, -180, 180);
                console.log(geoJson);
                setGeojson(geoJson);
            } else {
                setGeojson(undefined);
            }
        } else {
            setGeojson(undefined);
        }
    }, [data]);

    return (
        <div className="w-full relative">
            {!geojson && !loading && !error && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <MapIcon
                        className="h-6 w-6 text-grey-500"
                        data-tooltip-id="profile-heatmap"
                        data-tooltip-content="No location information available"
                    />
                </div>
            )}
            {loading && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <div className="loader h-4 w-4"></div>
                </div>
            )}
            {error && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-70">
                    <NoSymbolIcon
                        className="h-6 w-6 text-red-400"
                        data-tooltip-id="profile-heatmap"
                        data-tooltip-content="There was an error retrieving location information"
                    />
                </div>
            )}
            <ComposableMap
                id="map"
                projection="geoMercator"
                projectionConfig={
                    projectionConfig || {
                        scale: 180,
                        rotate: [-10, 0, 0],
                        center: [0, 18],
                    }
                }
                style={{ width: '100%', height: '350px' }}
            >
                <ZoomableGroup>
                    <Geographies geography={WorldCountriesGeos}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const fill = '#6B7280';
                                const strokeWidth = 0.5;

                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        fill={fill}
                                        fillOpacity={0.05}
                                        stroke="#9DA3AD"
                                        strokeWidth={strokeWidth}
                                        style={{
                                            default: { outline: 'none' },
                                            hover: { outline: 'none' },
                                            pressed: { outline: 'none' },
                                        }}
                                    />
                                );
                            })
                        }
                    </Geographies>
                    {geojson && (
                        <Geographies geography={geojson}>
                            {({ geographies }) =>
                                geographies.map((geo) => {
                                    const { value, row, col, opacity } = geo.properties;
                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            data-tooltip-id={'profile-heatmap'}
                                            data-tooltip-content={`${value} Events`}
                                            geography={geo}
                                            fill={'#36a8fa'}
                                            fillOpacity={opacity}
                                            stroke="#36a8fa"
                                            strokeOpacity={opacity + 0.1}
                                            style={{
                                                default: { outline: 'none' },
                                                hover: { outline: 'none' },
                                                pressed: { outline: 'none' },
                                            }}
                                            onMouseEnter={() => {
                                                console.log(
                                                    `Row: ${row}, Column: ${col}, Value: ${value}, Opacity: ${opacity}`,
                                                );
                                            }}
                                        />
                                    );
                                })
                            }
                        </Geographies>
                    )}
                </ZoomableGroup>
            </ComposableMap>
            <Tooltip id="profile-heatmap" />
        </div>
    );
};
