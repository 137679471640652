import { ToastContext } from 'Map/Components/ToastContext';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { useCallback, useContext } from 'react';
import { Datas } from 'react-csv-downloader/dist/esm/lib/csv';
import { ForceGraphMethods } from 'react-force-graph-2d';
import { GraphFilter, GraphFilterLabels } from 'Types/types';
import { getGroupTrail, getParentGroupTrail, isAGroupLeafNode, isATargetGroup } from 'Utilities/NodeUtilities';
import { getDisplayName, groupingForceApplied, isNodeHidden, resetLinkStrengths } from 'Utilities/utils';

type ControlOptions = {
    reheat?: boolean;
    delay?: number;
};

export const useGraphControls = () => {
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { dispatch: toastDispatch } = useContext(ToastContext);
    const width = 1920;
    const height = 1080;

    const includeNodeInMap = useCallback(
        (node: Node): boolean => {
            return !isNodeHidden(node, mapState.visible);
        },
        [mapState.visible],
    );

    const getVisibleNodes = useCallback(() => {
        const nodes = mapState.graphData.nodes.filter(includeNodeInMap);
        return nodes;
    }, [includeNodeInMap, mapState.graphData.nodes]);

    const getGraphRef = useCallback(() => {
        const graphRef = mapState.graphRef;
        if (graphRef) {
            return graphRef.current;
        }
    }, [mapState.graphRef]);

    const centerGraph = useCallback((): void => {
        const graph = getGraphRef();
        const nodes = mapState.graphData.nodes.filter(includeNodeInMap);
        if (graph) {
            const positions = nodes.map((node) => ({ x: node.x, y: node.y }));
            const centroid = calculateCentroid(positions);
            graph.centerAt(centroid.x, centroid.y, 1000);
        }
    }, [getGraphRef, includeNodeInMap, mapState.graphData.nodes]);

    const zoomGraph = useCallback(
        (options?: ControlOptions): void => {
            const graph = getGraphRef();
            if (!graph) return;

            const padding = 150;

            let delay = 0;
            if (options?.delay) {
                delay = options.delay;
            }

            if (options?.reheat) {
                graph.d3ReheatSimulation();
            }

            setTimeout(() => {
                const bbox = graph?.getGraphBbox(includeNodeInMap);
                if (!bbox) return;

                const zoomK = Math.max(
                    1e-12,
                    Math.min(
                        8,
                        (width - padding * 2) / (bbox.x[1] - bbox.x[0]),
                        (height - padding * 2) / (bbox.y[1] - bbox.y[0]),
                    ),
                );
                centerGraph();
                graph.zoom(zoomK, 1000);
            }, delay);
        },
        [centerGraph, getGraphRef, includeNodeInMap],
    );

    const zoomToNode = (node: Node, graphRef: React.MutableRefObject<ForceGraphMethods | undefined> | undefined) => {
        if (node && graphRef) {
            if (graphRef.current != null) {
                graphRef.current.centerAt(node.x, node.y, 500);
                graphRef.current.zoom(5, 500);
            }
        }
    };

    const getNodeFromGraph = useCallback(
        (node: Node): Node | undefined => {
            const graphNode = mapState.graphData.nodes.find((n) => n.id === node.id);
            return graphNode;
        },
        [mapState.graphData.nodes],
    );

    const isNodeInExplorer = useCallback(
        (node: Node) => {
            if (!node) {
                return false;
            }
            let isInExplorer = false;
            mapState.selectedNodes.forEach((selectedNode) => {
                if (selectedNode.id === node.id) {
                    isInExplorer = true;
                }
            });
            return isInExplorer;
        },
        [mapState.selectedNodes],
    );

    const isNodeInQuery = useCallback(
        (node: Node) => {
            if (!node) {
                return false;
            }
            let isInQuery = false;
            mapState.queriedNodes.forEach((queriedNode) => {
                if (queriedNode.id === node.id) {
                    isInQuery = true;
                }
            });
            return isInQuery;
        },
        [mapState.queriedNodes],
    );

    const addNodeToQuery = useCallback(
        (node: Node) => {
            const shouldAdd = !isNodeInQuery(node);

            if (shouldAdd) {
                const tmpNodes = new Set<Node>(mapState.queriedNodes.values());
                const graphNode = getNodeFromGraph(node);

                if (graphNode) {
                    console.debug(`Adding node ${graphNode.id} to query via mapState collection`);
                    tmpNodes.add(graphNode);
                    dispatch({ type: 'set-queried-nodes', nodes: tmpNodes });
                } else {
                    tmpNodes.add(node);
                    dispatch({ type: 'set-queried-nodes', nodes: tmpNodes });
                }
            }
        },
        [dispatch, getNodeFromGraph, isNodeInQuery, mapState.queriedNodes],
    );

    const addNodeToExplorer = useCallback(
        (node: Node, query?: boolean, closeDashboard?: boolean) => {
            const shouldAdd = !isNodeInExplorer(node);

            if (shouldAdd) {
                const tmpNodes = new Set<Node>(mapState.selectedNodes.values());
                const graphNode = getNodeFromGraph(node);

                if (graphNode) {
                    console.debug(`Adding node ${graphNode.id} to explorer via mapState collection`);
                    tmpNodes.add(graphNode);
                    dispatch({ type: 'set-selected-nodes', nodes: tmpNodes });
                    dispatch({ type: 'set-scroll-to-node', node: graphNode });
                } else {
                    tmpNodes.add(node);
                    dispatch({ type: 'set-selected-nodes', nodes: tmpNodes });
                    dispatch({ type: 'set-scroll-to-node', node: node });
                }

                toastDispatch({
                    type: 'add-toast',
                    message: `${getDisplayName(node)} added to Explorer list`,
                    status: 'information',
                    autoTimeout: true,
                    timeoutTimer: 1,
                });

                const explorerIsEmpty = mapState.selectedNodes.size === 0;
                if (query || explorerIsEmpty) {
                    addNodeToQuery(node);
                }

                if (closeDashboard) {
                    dispatch({ type: 'toggle-dashboard' });
                }

                if (node.label && ['actor', 'target', 'device'].includes(node.label)) {
                    dispatch({ type: 'set-profile-node', node: node });
                    dispatch({ type: 'set-profile-window', open: true });
                }
            } else {
                const tmpNodes = new Set<Node>(mapState.selectedNodes.values());
                const graphNode = mapState.graphData.nodes.find((n) => n.id === node.id);

                if (graphNode) {
                    mapState.selectedNodes.delete(graphNode);
                    dispatch({ type: 'set-selected-nodes', nodes: tmpNodes });
                }
            }
        },
        [
            addNodeToQuery,
            dispatch,
            getNodeFromGraph,
            isNodeInExplorer,
            mapState.graphData.nodes,
            mapState.selectedNodes,
            toastDispatch,
        ],
    );

    const isNodeOnMap = useCallback(
        (node: Node): boolean => {
            if (node) {
                if (mapState.graphData.nodes.find((n) => n.id === node.id)) {
                    return true;
                } else {
                    return false;
                }
            }
            return false;
        },
        [mapState.graphData.nodes],
    );

    const removeNodeFromExplorer = useCallback(
        (n: Node) => {
            const node = getNodeFromGraph(n) || n;
            if (isNodeInExplorer(node)) {
                dispatch({ type: 'remove-selected-node', node: node });
                if (isNodeInQuery(node)) {
                    dispatch({ type: 'remove-queried-node', node: node });
                }
                toastDispatch({
                    type: 'add-toast',
                    message: `${getDisplayName(node)} removed from Explorer list`,
                    status: 'unknown',
                    autoTimeout: true,
                    timeoutTimer: 1,
                });
            } else {
                return null;
            }
        },
        [dispatch, getNodeFromGraph, isNodeInExplorer, isNodeInQuery, toastDispatch],
    );

    const removeNodeFromQuery = useCallback(
        (n: Node) => {
            const node = getNodeFromGraph(n) || n;
            if (isNodeInQuery(node)) {
                dispatch({ type: 'remove-queried-node', node: node });
            } else {
                return null;
            }
        },
        [dispatch, getNodeFromGraph, isNodeInQuery],
    );

    const unlockAllNodes = useCallback(() => {
        dispatch({ type: 'release-all-nodes' });
    }, [dispatch]);

    // Show all nodes available on the map in given timewindow
    const unFilterAllNodes = useCallback(() => {
        const totalNodeCount = mapState.graphData.nodes.length;
        const showLoading = totalNodeCount < 0.2 && totalNodeCount > 1000;
        if (showLoading) {
            dispatch({ type: 'set-large-dataset-loading', loading: true });
        }

        const tempFilters: GraphFilter[] = [];
        dispatch({ type: 'apply-graph-filter', filters: tempFilters });
    }, [dispatch, mapState.graphData.nodes]);

    const unGroupAllNodes = useCallback(() => {
        const totalNodeCount = mapState.graphData.nodes.length;
        const showLoading = totalNodeCount < 0.2 && totalNodeCount > 1000;
        if (showLoading) {
            dispatch({ type: 'set-large-dataset-loading', loading: true });
        }

        dispatch({
            type: 'set-groups',
            group: {
                actor: undefined,
                device: undefined,
                identity: undefined,
                target: undefined,
            },
        });

        const g = mapState.graphRef?.current;
        const groups = mapState.group;
        if (!groupingForceApplied(groups)) {
            const strengths = mapState.strengths;
            resetLinkStrengths(g, strengths);
        }
        zoomGraph({ delay: 500, reheat: true });
    }, [dispatch, mapState.graphData.nodes, mapState.graphRef, mapState.group, mapState.strengths, zoomGraph]);

    // Expand and collapse group nodes (Exchange => Mailboxes, SharePoint => Files, etc.)
    const expandGroupNode = useCallback(
        (node: Node) => {
            if (isATargetGroup(node)) {
                const trail = getGroupTrail(node);
                if (trail) {
                    dispatch({ type: 'set-block-zoom', blocked: true });
                    dispatch({ type: 'add-level-trail', trail: trail });
                }
            }
        },
        [dispatch],
    );

    const collapseGroupNode = useCallback(
        (node: Node) => {
            if (isAGroupLeafNode(node)) {
                const trail = getParentGroupTrail(node);
                if (trail) {
                    dispatch({ type: 'set-block-zoom', blocked: true });
                    dispatch({ type: 'remove-level-trail', trail });
                }
            }
        },
        [dispatch],
    );

    // Apply Graph Filter for a specific Node
    const filterByNode = useCallback(
        (node: Node) => {
            if (node && node.label && node.props.displayName && node.label != 'device') {
                const filter: GraphFilter = {
                    label: node.label as GraphFilterLabels,
                    property: 'displayName',
                    operation: 'equals',
                    value: getDisplayName(node),
                };
                //Adds to the existing graphFilters
                dispatch({ type: 'apply-graph-filter', filters: [...(mapState.graphFilters || []), filter] });
            } else if (node && node.label && node.label == 'device') {
                const filter: GraphFilter = {
                    label: node.label as GraphFilterLabels,
                    property: 'id',
                    operation: 'equals',
                    value: node.id,
                };

                dispatch({ type: 'apply-graph-filter', filters: [...(mapState.graphFilters || []), filter] });
            }
            zoomGraph({ delay: 500, reheat: true });
        },
        [dispatch, mapState.graphFilters, zoomGraph],
    );

    const toggleNodeLock = useCallback(
        (n: Node) => {
            const node = getNodeFromGraph(n);
            if (node) {
                if (mapState.lockedNodes.has(node)) {
                    dispatch({ type: 'remove-locked-node', node });
                } else {
                    dispatch({ type: 'add-locked-node', node });
                }
            }
        },
        [getNodeFromGraph, mapState.lockedNodes, dispatch],
    );

    const lockSelectedNodes = useCallback(() => {
        dispatch({ type: 'lock-all-selected-nodes' });
    }, [dispatch]);

    const selectAllNodes = useCallback(() => {
        dispatch({ type: 'set-selected-nodes', nodes: new Set(mapState.graphData.nodes.filter((n) => !n.hidden)) });
    }, [dispatch, mapState.graphData.nodes]);

    const undoLastAction = useCallback(() => {
        dispatch({ type: 'undo' });
    }, [dispatch]);

    const gridTargets = useCallback(() => {
        const d = mapState.graphData;

        const count = d.nodes.filter((n) => n.label === 'target').length;

        const sizeAndSpace = 10;
        const distance = Math.ceil(Math.sqrt(count));

        const width = 5;
        let row = 0;
        let column = 0;
        d.nodes.map((n: Node) => {
            if (n.label === 'target') {
                n.fx = column * distance * sizeAndSpace;
                n.fy = row * sizeAndSpace * 2;
                column++;
                if (column >= width) {
                    column = 0;
                    row++;
                }
            }
        });

        const g = mapState.graphRef?.current;
        g?.d3ReheatSimulation();
    }, [mapState.graphData, mapState.graphRef]);

    const clearSelectedAndQueriedNodes = useCallback(() => {
        console.log('Clearing selected and queried nodes');
        dispatch({ type: 'set-selected-nodes', nodes: new Set() });
        dispatch({ type: 'set-queried-nodes', nodes: new Set() });
    }, [dispatch]);

    const clearSelectedAndKeepQueriedNodes = useCallback(() => {
        console.log('Clearing selected nodes and keeping queried nodes');
        dispatch({ type: 'set-selected-nodes', nodes: mapState.queriedNodes });
    }, [dispatch, mapState.queriedNodes]);

    const generateMapEventsCSV = useCallback(() => {
        const csvString = (string: string) => `"${string}"`;
        const data: Datas = [];

        const visibleNodes = getVisibleNodes();
        const targets = visibleNodes.filter((n) => n.label === 'target');

        targets.map((target) => {
            // If this target is hidden then don't include it in the CSV
            if (isNodeHidden(target, mapState.visible)) return;

            target.neighbors.map((identity) => {
                if (isNodeHidden(identity, mapState.visible)) return;

                const targetId = String(target.id);
                const targetName = target.props.displayName;
                const targetDomain = target.props.serviceDomain;

                const identityId = String(identity.id);
                const identityName = identity.props.displayName;

                const actor = identity.neighbors.find((n) => n.label === 'actor');
                let actorId;
                let actorName;
                if (actor && !isNodeHidden(actor, mapState.visible)) {
                    actorId = String(actor?.id);
                    actorName = actor?.props.displayName;
                }

                const device = identity.neighbors.find((n) => n.label === 'device');
                let deviceId;
                let deviceName;
                if (device && !isNodeHidden(device, mapState.visible)) {
                    deviceId = String(device?.id);
                    deviceName = device?.props.displayName;
                }

                const application = identity.neighbors.find((n) => n.label === 'application');
                let applicationId;
                let applicationName;
                if (application && !isNodeHidden(application, mapState.visible)) {
                    applicationId = String(application?.id);
                    applicationName = application?.props.displayName;
                }

                const link = identity.links.find((l) => l.source === target);

                //
                // TODO: Removing sessions from the CSV as it doesn't appear in Active Directory data right now
                //
                // const sessionStartTime =
                //     identity.props.sessionStart && new Date(identity.props.sessionStart / 1000000).toISOString();
                // const sessionEndTime =
                //     identity.props.sessionActualEnd &&
                //     new Date(identity.props.sessionActualEnd / 1000000).toISOString();

                if (actor && device && application && link) {
                    const row = {
                        '"Target ID"': targetId || '',
                        '"Target Name"': targetName || '',
                        '"Target Domain"': targetDomain || '',
                        '"Identity ID"': identityId,
                        '"Identity Name"': identityName || '',
                        '"Actor ID"': actorId || '',
                        '"Actor Name"': actorName || '',
                        '"Device ID"': deviceId || '',
                        '"Device Name"': deviceName || '',
                        '"Application ID"': applicationId || '',
                        '"Application Name"': applicationName || '',
                        '"Success Events"': String(link.policyStatsAbsolute?.success),
                        '"Challenge Events"': String(link.policyStatsAbsolute?.warning),
                        '"Failure Events"': String(link.policyStatsAbsolute?.critical),
                        // '"Session Start Time"': sessionStartTime || '',
                        // '"Session End Time"': sessionEndTime || '',
                    };

                    // Add double quotes to all values to deal with commas in the values
                    Object.entries(row).forEach(([key, value]) => {
                        row[key as keyof typeof row] = csvString(value);
                    });

                    data.push(row);
                }
            });
        });

        return data;
    }, [getVisibleNodes, mapState.visible]);

    const generateMapObjectsCSV = useCallback(() => {
        const csvString = (string: string) => `"${string}"`;
        const data: Datas = [];
        const nodes = getVisibleNodes();
        // sort nodes based on label, then by displayName
        const sortedNodes = nodes.sort((a, b) => {
            if (a.label === b.label) {
                const aName = getDisplayName(a);
                const bName = getDisplayName(b);
                return aName.localeCompare(bName);
            }
            const aLabel = String(a.label);
            const bLabel = String(b.label);

            return aLabel.localeCompare(bLabel);
        });

        sortedNodes.map((node) => {
            const row = {
                '"Object Type"': node.label || 'Unknown',
                '"Object ID"': String(node.id),
                '"Object Name"': getDisplayName(node),
            };

            // Add double quotes to all values to deal with commas in the values
            Object.entries(row).forEach(([key, value]) => {
                row[key as keyof typeof row] = csvString(value);
            });

            data.push(row);
        });

        return data;
    }, [getVisibleNodes]);

    return {
        zoomGraph,
        zoomToNode,
        centerGraph,
        getVisibleNodes,
        addNodeToExplorer,
        isNodeInExplorer,
        isNodeOnMap,
        removeNodeFromExplorer,
        addNodeToQuery,
        removeNodeFromQuery,
        isNodeInQuery,
        unlockAllNodes,
        unFilterAllNodes,
        unGroupAllNodes,
        expandGroupNode,
        collapseGroupNode,
        filterByNode,
        toggleNodeLock,
        lockSelectedNodes,
        selectAllNodes,
        undoLastAction,
        gridTargets,
        generateMapEventsCSV,
        generateMapObjectsCSV,
        clearSelectedAndQueriedNodes,
        clearSelectedAndKeepQueriedNodes,
    };
};

const calculateCentroid = (positions: { x: number; y: number }[]) => {
    const x = positions.reduce((acc, cur) => acc + cur.x, 0) / positions.length;
    const y = positions.reduce((acc, cur) => acc + cur.y, 0) / positions.length;
    return { x, y };
};
