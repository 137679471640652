import { CheckCircleIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { useContext } from 'react';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';

interface SideNavProps {
    selectedTarget: Node | undefined;
    setTarget: React.Dispatch<React.SetStateAction<Node>>;
}

const indentStyle =
    'border-transparent  hover:bg-gray-700 text-gray-400 hover:text-gray-200 cursor-pointer group border-l-4 pl-10 pr-6 py-2 flex items-center justify-between text-sm font-medium';

const headerStyle = classNames(
    'border-transparent hover:bg-gray-700 text-gray-400 hover:text-gray-200',
    'cursor-pointer group border-l-4 px-6 py-2 flex items-center justify-between text-sm font-medium',
);

export const SideNavMock = ({ selectedTarget, setTarget }: SideNavProps): JSX.Element => {
    const { mapState } = useContext(IdentityMapContext);
    const d = mapState.graphData;
    const targets = d.nodes.filter((n) => n.label === 'target');
    const combinedTargets = staticTargets.concat(targets);

    return (
        <div className="flex flex-col flex-grow pt-4 pb-4  relative">
            <div className="flex-grow flex flex-col">
                <nav className="flex-1 space-y-1 bg-gray-800" aria-label="Sidebar">
                    <p className="uppercase tracking-wider px-4 font-bold text-xs text-gray-400 mb-4 h-8 flex items-center">
                        Configuration
                    </p>

                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">Overview</span>
                        </span>
                    </div>
                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">Adaptive Trust Policies</span>
                            <ChevronDownIcon width="18" height="18" className="mr-2 float-right" />
                        </span>

                        {combinedTargets.map(
                            (t) =>
                                t && (
                                    <span
                                        key={t.id}
                                        onClick={() => setTarget(t)}
                                        className={classNames(
                                            t.id == selectedTarget?.id
                                                ? 'bg-blue-800 border-blue-700 text-gray-200'
                                                : 'border-transparent  hover:bg-gray-700 text-gray-400 hover:text-gray-200',
                                            'cursor-pointer group border-l-4 pl-10 pr-6 py-2 flex items-center justify-between text-sm font-medium',
                                        )}
                                    >
                                        <span className="flex align-center">
                                            <img
                                                src={getIconSourceURL(getNodeIconElement(t))}
                                                className="h-5 w-5 mr-2"
                                            />
                                            {t.props.displayName?.substring(0, 24)}
                                        </span>
                                        <CheckCircleIcon
                                            width="18"
                                            height="18"
                                            className="text-green-300 mr-2 float-right"
                                        />
                                    </span>
                                ),
                        )}

                        <span className={classNames(indentStyle, 'text-gray-500 pl-11')}>
                            <span className="flex align-center">Add Another +</span>
                        </span>
                    </div>

                    <div
                        onClick={() => {
                            setTarget({
                                label: 'mapping',
                                id: 'mapping',
                                name: 'Trust Profile Mappings',
                                props: { displayName: 'Trust Profile Mappings' },
                                x: 0,
                                y: 0,
                                links: [],
                                neighbors: [],
                                tags: [],
                                attributes: [],
                            });
                        }}
                    >
                        <span className={headerStyle}>
                            <span className="flex align-center">Trust Profile Mappings</span>
                            <ChevronRightIcon width="18" height="18" className="mr-2 float-right" />
                        </span>
                        {/* <span className={indentStyle}>
                            <span className="flex align-center">All Employees</span>
                        </span>
                        <span className={indentStyle}>
                            <span className="flex align-center">Contract Employees</span>
                        </span>
                        <span className={indentStyle}>
                            <span className="flex align-center">Executive Employees</span>
                        </span>
                        <span className={classNames(indentStyle, 'text-gray-500')}>
                            <span className="flex align-center">Add Another +</span>
                        </span> */}
                    </div>

                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">Trust Profiles</span>
                            <ChevronDownIcon width="18" height="18" className="mr-2 float-right" />
                        </span>

                        {staticProfiles.map(
                            (t) =>
                                t && (
                                    <span
                                        key={t.id}
                                        onClick={() => setTarget(t)}
                                        className={classNames(
                                            t.id == selectedTarget?.id
                                                ? 'bg-blue-800 border-blue-700 text-gray-200'
                                                : 'border-transparent  hover:bg-gray-700 text-gray-400 hover:text-gray-200',
                                            'cursor-pointer group border-l-4 pl-10 pr-6 py-2 flex items-center justify-between text-sm font-medium',
                                        )}
                                    >
                                        <span className="flex align-center">
                                            {t.props.displayName?.substring(0, 24)}
                                        </span>
                                    </span>
                                ),
                        )}

                        <span className={classNames(indentStyle, 'text-gray-500')}>
                            <span className="flex align-center">Add Another +</span>
                        </span>
                    </div>

                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">Trust Factors</span>
                            <ChevronRightIcon width="18" height="18" className="mr-2 float-right" />
                        </span>
                        {/* <span className={indentStyle}>
                            <span className="flex align-center">Identity Trust</span>
                        </span>
                        <span className={indentStyle}>
                            <span className="flex align-center">Behavior Trust</span>
                        </span>
                        <span className={indentStyle}>
                            <span className="flex align-center">Access Trust</span>
                        </span>
                        <span className={indentStyle}>
                            <span className="flex align-center">Device Trust</span>
                        </span>
                        <span className={classNames(indentStyle, 'text-gray-500')}>
                            <span className="flex align-center">Add Another +</span>
                        </span> */}
                    </div>
                </nav>
            </div>
        </div>
    );
};

const staticProfiles = [
    {
        id: 'baseline',
        name: 'Baseline',
        label: 'profile',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Baseline',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'administrators',
        name: 'Administrators',
        label: 'profile',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Administrators',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'us',
        name: 'US Employees',
        label: 'profile',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'US Employees',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'contractors',
        name: 'Contractors',
        label: 'profile',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Contractors',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'executives',
        name: 'Executives',
        label: 'profile',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Executives',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
];

const staticTargets = [
    {
        id: 'dseg:/target/63fcf577da1881ef4d2e5b18/291008e3-7ad3-4e97-b300-401beee61716',
        name: 'Default Thresholds',
        label: 'target',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Default Thresholds',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },

    {
        id: 'dseg:/target/63fcf577da1881ef4d2e5b18/291008e3-7ad3-4e97-b300-401beee61713',
        name: 'Azure Active Directory',
        label: 'target',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Azure Active Directory',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'dseg:/target/63fcf577da1881ef4d2e5b18/234f98f2-e9dc-4f67-b637-054a8a561e1a',
        name: 'Microsoft Graph',
        label: 'target',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Microsoft Graph',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'dseg:/target/gitlab',
        name: 'GitLab',
        label: 'target',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'GitLab',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'dseg:/target/63fcf577da1881ef4d2e5b18/37cfdd11-bee9-4389-a18c-66eec7adf2e3',
        name: 'Office 365',
        label: 'target',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Office 365',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
    {
        id: 'dseg:/target/63fcf577da1881ef4d2e5b18/234f98f2-e9dc-4f67-b637-054a8a561e1b',
        name: 'OneDrive',
        label: 'target',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'OneDrive',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },

    {
        id: 'dseg:/target/63fcf577da1881ef4d2e5b18/291008e3-7ad3-4e97-b300-401beee61714',
        name: 'Microsoft Teams',
        label: 'target',
        x: 0,
        y: 0,
        links: [],
        neighbors: [],
        props: {
            displayName: 'Microsoft Teams',
            serviceDomain: '',
            serviceId: '',
        },
        tags: [],
        attributes: [],
    },
] as Node[];
