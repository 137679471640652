import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import {
    ChartBarIcon,
    ChatBubbleLeftRightIcon,
    CogIcon,
    ExclamationTriangleIcon,
    Bars3Icon,
    ShareIcon,
    SignalIcon,
    Square3Stack3DIcon,
    AdjustmentsVerticalIcon,
    Squares2X2Icon,
    ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/24/solid';
import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { useFlags } from 'launchdarkly-react-client-sdk';

import production from 'assets/logo.svg';
import staging from 'assets/logo-staging.svg';
import development from 'assets/logo-dev.svg';
import { useEnvironment, useProductTutorial, useTenant } from 'Hooks/Hooks';

import { Views } from './Views';
import { Notifications } from './Notifications';
import { useQuery } from '@apollo/client';
import { Provider } from 'Types/types';
import { GET_TENANT, LIST_PROVIDERS } from 'Graph/queries';
import { CommandPalette } from './CommandPalette';
import { Menu, Transition } from '@headlessui/react';
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from 'Library/Tooltip';
import { useCurrentUser } from 'Hooks/User';
import { classNames } from 'Utilities/utils';
import { GET_TOTAL_ALERT_COUNT } from 'Graph/typedQueries';

export const Header = (): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);
    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();
    const tenantId = useTenant();
    const { name, email } = useCurrentUser();
    const [helpScoutInitialized, setHelpScoutInitialized] = useState(false);
    const { data } = useQuery(GET_TENANT, { variables: { tenantId }, skip: !tenantId });

    const isDesktop = useMediaQuery({ query: '(min-width: 1700px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 980px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isPortrait = useMediaQuery({ query: '(max-width: 480px)' });

    const { policy, agentsPanel, enableNavigator, enableAlerts, enableNotifications } = useFlags();

    const togglePolicy = () => {
        dispatch({ type: 'toggle-policy' });
    };

    const toggleNavigator = () => {
        dispatch({ type: 'toggle-navigator' });
    };

    const toggleDashboard = () => {
        dispatch({ type: 'toggle-dashboard' });
    };

    const resetMap = () => {
        dispatch({ type: 'reset-map' });
    };

    const toggleAgents = () => {
        dispatch({ type: 'toggle-agents' });
    };

    const openBeacon = () => {
        window.Beacon('open');
    };

    useEffect(() => {
        if (data && tenantId && name && email && !helpScoutInitialized) {
            if (window.Beacon) {
                const existingBeacon = window.Beacon('info');
                if (!existingBeacon) {
                    console.log('Initializing Help Scout Beacon');
                    window.Beacon('config', {
                        display: {
                            style: 'manual',
                        },
                    });
                    window.Beacon('init', window.__env__.HELP_SCOUT_BEACON_ID);
                    window.Beacon('identify', {
                        name,
                        email,
                        company: data.getTenant.name,
                        tenantId: tenantId,
                    });

                    window.Beacon('close');
                } else {
                    console.log('Help Scout Beacon already initialized');
                }
                setHelpScoutInitialized(true);
            }
        }
    }, [data, email, helpScoutInitialized, name, tenantId]);

    return (
        <header className="text-gray-400 bg-gray-900 body-font pb-12 md:pb-0" id="Header">
            <div className="mx-auto flex flex-row items-center justify-between p-3 text-sm sm:w-full">
                <div className="flex items-center justify-start">
                    {!isDesktop && <Dropdown />}
                    <div className="flex items-center text-white mb-0" id="header">
                        <Logo />
                        {(!isTablet || isMobile) && !isPortrait && (
                            <div className="ml-3 flex flex-col justify-center leading-tight">
                                <span className="text-xl leading-tight">Double Zero</span>
                                <Environment />
                            </div>
                        )}
                    </div>
                    {isDesktop && (
                        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700 flex flex-wrap items-center">
                            <button
                                type="button"
                                className="mr-5 hover:text-white focus:outline-none"
                                onClick={resetMap}
                            >
                                Identity Map
                            </button>

                            <button
                                data-test="insights"
                                className="mr-5 hover:text-white focus:outline-none"
                                onClick={toggleDashboard}
                            >
                                Insights
                            </button>

                            {enableNavigator && (
                                <button className="mr-5 hover:text-white focus:outline-none" onClick={toggleNavigator}>
                                    Permissions
                                </button>
                            )}

                            {policy && (
                                <button
                                    className="mr-5 hover:text-white focus:outline-none"
                                    id="PolicyWindow"
                                    onClick={togglePolicy}
                                >
                                    Trust
                                </button>
                            )}
                        </nav>
                    )}
                </div>
                <div className="fixed translate-x-[-50%] px-4 md:p-0 w-screen left-[50%] top-16 xs:left-[50%] xs:top-16 sm:left-[50%] sm:top-16 md:top-3 md:left-[calc(50%-72px)] md:w-[400px] lg:w-[480px] lg:left-[50%] xl:w-[580px] 2xl:w-[630px] ">
                    <CommandPalette />
                </div>
                <div className="flex items-center">
                    <a
                        data-test="views"
                        id="ViewsHeader"
                        type="button"
                        onClick={() => {
                            runOnTutorialStep('ViewsButton', () => {
                                moveToNextTutorialStep();
                            });
                        }}
                    >
                        <Views />
                    </a>
                    {enableAlerts && <AlertsButton />}
                    {agentsPanel && (
                        <Tooltip label="Virtual Agents" placement="bottom">
                            <button
                                className="inline-flex rounded-full items-center justify-center btn-gray p-2 bg-gray-900 hover:bg-gray-800 relative"
                                onClick={toggleAgents}
                            >
                                <Squares2X2Icon className="h-5 w-5" />
                            </button>
                        </Tooltip>
                    )}
                    <Tooltip label="Get Support" placement="bottom">
                        <button
                            disabled={!helpScoutInitialized}
                            data-test="contact-support"
                            className="inline-flex rounded-full items-center justify-center btn-gray p-2 bg-gray-900 hover:bg-gray-800 relative"
                        >
                            <ChatBubbleLeftRightIcon className="h-5 w-5" onClick={openBeacon} />
                        </button>
                    </Tooltip>
                    {enableNotifications && <Notifications />}
                    <ConfigButton />
                </div>
            </div>
        </header>
    );
};

const Logo = (): JSX.Element => {
    const env = useEnvironment();

    return (
        <>
            {env == 'dev' && <img src={development} alt="double zero logo" className="h-10 w-10" />}
            {env == 'staging' && <img src={staging} alt="double zero logo" className="h-10 w-10" />}
            {env == 'prod' && <img src={production} alt="double zero logo" className="h-10 w-10" />}
        </>
    );
};

const Environment = (): JSX.Element => {
    const env = useEnvironment();

    return (
        <>
            {env == 'dev' && (
                <p className="text-[10px] leading-normal uppercase font-semibold tracking-widest text-gray-400">
                    Development
                </p>
            )}
            {env == 'staging' && (
                <p className="text-[10px] leading-normal uppercase font-semibold tracking-widest text-gray-400">
                    Staging
                </p>
            )}
        </>
    );
};

export const Dropdown = (): JSX.Element => {
    const { dispatch } = useContext(IdentityMapContext);
    const { policy, enableNavigator } = useFlags();

    const togglePolicy = () => {
        dispatch({ type: 'toggle-policy' });
    };

    const toggleNavigator = () => {
        dispatch({ type: 'toggle-navigator' });
    };

    const toggleDashboard = () => {
        dispatch({ type: 'toggle-dashboard' });
    };

    const resetMap = () => {
        dispatch({ type: 'reset-map' });
    };

    return (
        <Menu as="div" className="relative mr-3">
            {() => (
                <>
                    <Menu.Button className="text-white inline-flex justify-center w-full rounded-full shadow-sm p-2 bg-gray-900 text-sm hover:bg-gray-800 focus:outline-none">
                        <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                    </Menu.Button>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Menu.Items className="absolute z-10 mt-3 w-screen max-w-[180px]">
                            <div className="rounded-lg px-3 py-2 bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <Menu.Item>
                                    {() => (
                                        <button
                                            type="button"
                                            className="flex mr-5 py-2 hover:text-white focus:outline-none"
                                            onClick={resetMap}
                                        >
                                            <ShareIcon className="h-4 w-4 mr-2" />
                                            Identity Map
                                        </button>
                                    )}
                                </Menu.Item>

                                {enableNavigator && (
                                    <Menu.Item>
                                        {() => (
                                            <button
                                                className="flex mr-5 py-2 hover:text-white focus:outline-none"
                                                onClick={toggleNavigator}
                                            >
                                                <Square3Stack3DIcon className="h-4 w-4 mr-2" />
                                                Permissions
                                            </button>
                                        )}
                                    </Menu.Item>
                                )}
                                {policy && (
                                    <Menu.Item>
                                        {() => (
                                            <button
                                                className="flex mr-5 py-2 hover:text-white focus:outline-none"
                                                id="PolicyWindow"
                                                onClick={togglePolicy}
                                            >
                                                <AdjustmentsVerticalIcon className="h-4 w-4 mr-2" />
                                                Trust
                                            </button>
                                        )}
                                    </Menu.Item>
                                )}

                                <Menu.Item>
                                    {() => (
                                        <button
                                            className="flex mr-5 py-2 hover:text-white focus:outline-none"
                                            onClick={toggleDashboard}
                                        >
                                            <ChartBarIcon className="h-4 w-4 mr-2" />
                                            Insights
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

const ConfigButton = (): JSX.Element => {
    const { settingsHighlightDegradedProviders } = useFlags();
    const tenantId = useTenant();
    const { error, data, refetch } = useQuery(LIST_PROVIDERS, {
        variables: { tenantId },
        pollInterval: 60000 * 5,
        skip: !tenantId,
    });
    const { mapState, dispatch } = useContext(IdentityMapContext);
    const { runOnTutorialStep, moveToNextTutorialStep } = useProductTutorial();

    const toggleConfig = () => {
        runOnTutorialStep('Config Open', () => {
            if (!mapState.configOpen) {
                moveToNextTutorialStep();
            }
        });

        dispatch({ type: 'toggle-config' });
    };

    useEffect(() => {
        if (data) {
            if (data.listProviders) {
                const providers: Provider[] = data.listProviders;

                if (providers.length === 0) {
                    dispatch({ type: 'set-provider-error', hasProviderError: false });
                    dispatch({ type: 'set-provider-info', hasProviderInfo: true });
                    return;
                } else {
                    dispatch({ type: 'set-provider-info', hasProviderInfo: false });
                }

                const hasError = providers.some((provider) => {
                    if (
                        provider.status === 'CONFIG_ERROR' ||
                        (provider.status === 'DEGRADED' && settingsHighlightDegradedProviders)
                    ) {
                        return true;
                    }
                });

                if (hasError) {
                    dispatch({ type: 'set-provider-error', hasProviderError: true });
                } else {
                    dispatch({ type: 'set-provider-error', hasProviderError: false });
                }
            }
        }
    }, [dispatch, data, error, tenantId, refetch, settingsHighlightDegradedProviders]);

    let tooltip = 'Settings';
    if (mapState.hasProviderError) {
        tooltip = 'Warning! One or more providers have reported a connection issue';
    }
    if (mapState.hasProviderInfo) {
        tooltip = 'Hey there 👋 you need to configure your first provider';
    }

    return (
        <Tooltip label={tooltip} placement="bottom">
            <button
                className="inline-flex rounded-full items-center justify-center btn-gray p-2 bg-gray-900 hover:bg-gray-800 relative"
                id="ConfigButton"
                onClick={toggleConfig}
            >
                <CogIcon className="h-5 w-5" />
                {mapState.hasProviderError && (
                    <ExclamationTriangleIcon className="absolute h-4 w-4 -top-0.5 -right-0.5 rounded-full text-yellow-500" />
                )}
                {mapState.hasProviderInfo && (
                    <div
                        className={classNames(
                            'absolute w-6 h-6 -top-1.5 -right-1 rounded-full bg-blue-700 text-white text-xs font-semibold flex place-items-center align-middle justify-center shadow-md',
                        )}
                    >
                        <ChatBubbleLeftEllipsisIcon className="h-4 w-4 text-white-200" />
                    </div>
                )}
            </button>
        </Tooltip>
    );
};

const AlertsButton = (): JSX.Element => {
    const tenantId = useTenant();

    const { dispatch } = useContext(IdentityMapContext);

    const toggleAlerts = () => {
        dispatch({ type: 'toggle-alerts' });
    };

    const { data } = useQuery(GET_TOTAL_ALERT_COUNT, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId,
        pollInterval: 1000 * 60 * 5,
    });

    const alertCount = useMemo(() => {
        if (data) {
            return data.getTotalAlertCount.count;
        }
        return 0;
    }, [data]);

    const bubbleWidth = useMemo(() => {
        if (alertCount > 99) {
            return 'w-8';
        } else if (alertCount > 9) {
            return 'w-6';
        } else {
            return 'w-4';
        }
    }, [alertCount]);

    return (
        <Tooltip label="View and Configure Alerts" placement="bottom">
            <button
                className="inline-flex rounded-full items-center justify-center btn-gray p-2 bg-gray-900 hover:bg-gray-800 relative"
                onClick={toggleAlerts}
            >
                <SignalIcon className="h-5 w-5" />
                {alertCount > 0 && (
                    <div
                        className={classNames(
                            'absolute h-4 -top-0.5 -right-0.5 rounded-full bg-red-500 text-white text-xs font-semibold flex place-content-center',
                            bubbleWidth,
                        )}
                    >
                        {alertCount > 99 ? '99+' : alertCount}
                    </div>
                )}
            </button>
        </Tooltip>
    );
};
