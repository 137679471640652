import logo from 'assets/logo-192x192.png';

export interface WelcomeComponent {
    prevStep?: () => void;
    nextStep?: () => void;
}

interface ComingSoonProviderProps {
    name: string;
}
export const ComingSoonProvider = ({ name }: ComingSoonProviderProps): JSX.Element => (
    <li className="list-disc ml-8 text-gray-500">
        {name}
        <span className="float-right inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-600 text-gray-400">
            Coming Soon
        </span>
    </li>
);

interface HeaderProps {
    title: string;
    previous?: () => void;
}

export const Header = ({ title, previous }: HeaderProps): JSX.Element => (
    <div className="pb-10">
        <img className="h-12 w-auto" src={logo} alt="Double Zero Icon" />
        <h2 className="text-3xl font-extrabold text-gray-200">{title}</h2>
        <p className="mt-2 text-xs text-blue-600">
            <a
                onClick={() => alert('TODO: Not available yet, sorry.')}
                className="font-medium hover:text-blue-500 cursor-pointer"
            >
                Read the detailed documentation here
            </a>
            {previous && (
                <span onClick={previous} className="float-right text-gray-400 cursor-pointer hover:text-gray-600">
                    Previous
                </span>
            )}
        </p>
        <hr className="mt-4 border-gray-400" />
    </div>
);

interface NextButtonProps {
    title: string;
    next?: () => void;
}

export const NextButton = ({ title, next }: NextButtonProps): JSX.Element => (
    <button
        onClick={next}
        className="btn btn-primary mt-8 font-medium text-white text-sm active:bg-gray-900 w-11/12 mx-auto mb-2"
    >
        {title}
    </button>
);
