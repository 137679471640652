import { IdentityMapContext } from 'Map/State/IdentityMapContext';
import { Node } from 'Types/types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { EventHistoryChart } from './Library/EventHistoryChart';
import { ProfilePanel, ProfileHeader } from './Library/ProfileComponents';
import { getDisplayName, classNames, getEntityType, getUsersTimezone } from 'Utilities/utils';
import { useMediaQuery } from 'react-responsive';
import { EventsTable } from './EventsTables';
import { useQuery } from '@apollo/client';
import { GET_ENTITY_ACCESSORS } from 'Graph/queries';
import { useTenant } from 'Hooks/Hooks';
import { format } from 'date-fns';
import { SkipToTime } from 'Library/SkipToTime';
import { Helmet } from 'react-helmet-async';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useChartTimeRange } from 'Dashboard/Charts/ChartHooks';
import { NodeAttributeDisplay } from './Library/NodeAttributeDisplay';
import { NodeTheWall } from './Library/NodeTheWall';
import { AccessorList } from './Library/AccessorList';
import { ProfilePanelTabHeader } from './Library/ProfilePanelTabHeader';
import { LightWeightProfile } from './LightWeightProfile';
import { NodeTrustDisplay } from './Library/NodeTrustDisplay';
import { NodeLocationDisplay } from './Library/NodeLocationDisplay';
import { ContextMenu } from 'Map/Components/ContextMenu';
import { TriggerEvent, useContextMenu } from 'react-contexify';
import { QueryProfile } from './QueryProfile';

export const Profile = (): JSX.Element => {
    const { mapState } = useContext(IdentityMapContext);
    const { enableActivityLog, policy, enableWorldMap, profileDefaultTab } = useFlags();

    const { selectedProfileNode: node } = mapState;
    const [lastNode, setLastNode] = useState<Node>();

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const [view, setView] = useState<'summary' | 'activity' | 'attributes' | 'events' | 'trust' | 'location'>(
        profileDefaultTab ?? 'summary',
    );
    const [tab, setTab] = useState<'actor' | 'target' | 'device' | 'identity' | 'application'>('target');
    const [expanded, setExpanded] = useState(false);

    const { show } = useContextMenu({
        id: 'profile',
    });

    const {
        startDate,
        endDate,
        selectedStartDate,
        selectedEndDate,
        interval,
        setNarrowedEpoch,
        UnitSelect,
        TimeWindowSelect,
    } = useChartTimeRange();

    const [selectedBar, setSelectedBar] = useState(-1);

    useEffect(() => {
        setSelectedBar(-1);
    }, [startDate, endDate, interval]);

    const onBarClick = (epoch: number | undefined, index: number | undefined) => {
        setNarrowedEpoch(epoch);
        setSelectedBar(index ?? -1);
    };

    const tenantId = useTenant();

    useEffect(() => {
        if (node) {
            if (node.id !== lastNode?.id) {
                setLastNode(node);
                setNarrowedEpoch(undefined);
                setSelectedBar(-1);
            }
        }
    }, [lastNode?.id, node, setNarrowedEpoch]);

    useEffect(() => {
        const node = mapState.selectedProfileNode;
        if (node) {
            if (node.label === 'actor' && tab == 'actor') {
                setTab('target');
                return;
            }
            if (node.label === 'target' && tab == 'target') {
                setTab('actor');
                return;
            }
        }
    }, [mapState.selectedProfileNode, tab]);

    const { data: entityAccessorsData, loading } = useQuery(GET_ENTITY_ACCESSORS, {
        variables: {
            tenantId,
            entityId: node?.id,
            entityType: getEntityType(node),
            startDate: selectedStartDate,
            endDate: selectedEndDate,
        },
        skip: !node,
    });

    const { actors, targets, devices, identities, applications, stats } = useMemo(() => {
        if (entityAccessorsData) {
            const results = entityAccessorsData.getEntityAccessors;
            return {
                actors: results.actors,
                targets: results.targets,
                devices: results.devices,
                identities: results.identities,
                applications: results.applications,
                stats: {
                    actorCount: results.actorsCount,
                    targetCount: results.targetsCount,
                    deviceCount: results.devicesCount,
                    applicationCount: results.applicationsCount,
                    identityCount: results.identitiesCount,
                    highRiskActorCount: results.highRiskActorsCount,
                    highRiskTargetCount: results.highRiskTargetsCount,
                    highRiskDeviceCount: results.highRiskDevicesCount,
                    highRiskIdentityCount: results.highRiskIdentitiesCount,
                    highRiskApplicationCount: results.highRiskApplicationsCount,
                },
            };
        }

        return {
            actors: undefined,
            targets: undefined,
            devices: undefined,
            identities: undefined,
            stats: undefined,
        };
    }, [entityAccessorsData]);

    const displayContextMenu = (e: TriggerEvent & { clientX: number; clientY: number }) => {
        // prevent default List click action
        e.stopPropagation();
        e.preventDefault();

        // show context menu
        show(e, {
            position: {
                x: e.clientX,
                y: e.clientY,
            },
        });
    };

    if (!node) {
        return <></>;
    }

    if (node.label === 'device' || node.label === 'application') {
        return <LightWeightProfile />;
    }

    if (node.label === 'query') {
        return <QueryProfile />;
    }

    return (
        <ProfilePanel>
            <Helmet>
                <title>Profile | {getDisplayName(node)}</title>
            </Helmet>
            <div
                data-test="profile-window"
                className={classNames('p-3', isMobile ? 'overflow-y-auto h-[calc(100vh-350px)]' : '')}
                onContextMenu={displayContextMenu}
            >
                <ContextMenu id={'profile'} node={node} />
                <ProfileHeader
                    node={node}
                    title={`${node.label} profile`}
                    displayContextMenu={displayContextMenu}
                    startDate={startDate}
                    endDate={endDate}
                />

                {!expanded && (
                    <>
                        <div className="bg-gray-700 p-3 pt-3 mt-4 rounded-lg">
                            <div className="flex justify-between align-middle items-center">
                                <span className="text-xs text-gray5300 flex mb-3">
                                    <UnitSelect />
                                </span>
                                <span className="text-xs text-gray-500 flex mb-3">
                                    <TimeWindowSelect />
                                </span>
                            </div>

                            <EventHistoryChart
                                node={node}
                                startDate={startDate}
                                endDate={endDate}
                                interval={interval}
                                onBarClick={onBarClick}
                                selectedBar={selectedBar}
                                plotTrust={policy}
                                trustData={node.trustData}
                            />
                        </div>
                    </>
                )}
                <div className="flex items-center justify-between">
                    <div className="flex rounded-lg items-stretch my-3 bg-gray-700 relative w-full">
                        <p
                            onClick={() => {
                                setView('summary');
                                setExpanded(false);
                            }}
                            className={classNames(
                                'text-xs flex grow justify-center p-1 px-2 cursor-pointer z-10',
                                view == 'summary'
                                    ? 'text-white font-medium hover:text-white bg-blue-700 rounded-lg'
                                    : 'text-gray-300 hover:text-blue-600',
                            )}
                        >
                            Summary
                        </p>

                        <p
                            onClick={() => {
                                setView('attributes');
                                setExpanded(false);
                            }}
                            className={classNames(
                                'text-xs flex grow justify-center p-1 px-2 cursor-pointer z-10',
                                view == 'attributes'
                                    ? 'text-white font-medium hover:text-white  bg-blue-700 rounded-lg'
                                    : 'text-gray-300 hover:text-blue-600',
                            )}
                        >
                            Attributes
                        </p>

                        <p
                            onClick={() => {
                                setView('events');
                            }}
                            className={classNames(
                                'text-xs flex grow justify-center p-1 px-2 cursor-pointer z-10',
                                view == 'events'
                                    ? 'text-white font-medium hover:text-white  bg-blue-700 rounded-lg'
                                    : 'text-gray-300 hover:text-blue-600',
                            )}
                        >
                            Accesses
                        </p>

                        {enableActivityLog && (
                            <p
                                onClick={() => {
                                    setView('activity');
                                }}
                                className={classNames(
                                    'text-xs flex grow justify-center p-1 px-2 cursor-pointer z-10',
                                    view == 'activity'
                                        ? 'text-white font-medium hover:text-white  bg-blue-700 rounded-lg'
                                        : 'text-gray-300 hover:text-blue-600',
                                )}
                            >
                                Activity Wall
                            </p>
                        )}

                        {policy && (
                            <p
                                onClick={() => {
                                    setView('trust');
                                }}
                                className={classNames(
                                    'text-xs flex grow justify-center p-1 px-2 cursor-pointer z-10',
                                    view == 'trust'
                                        ? 'text-white font-medium hover:text-white  bg-blue-700 rounded-lg'
                                        : 'text-gray-300 hover:text-blue-600',
                                )}
                            >
                                Trust
                            </p>
                        )}

                        {enableWorldMap && (
                            <p
                                onClick={() => {
                                    setView('location');
                                }}
                                className={classNames(
                                    'text-xs flex grow justify-center p-1 px-2 cursor-pointer z-10',
                                    view == 'location'
                                        ? 'text-white font-medium hover:text-white  bg-blue-700 rounded-lg'
                                        : 'text-gray-300 hover:text-blue-600',
                                )}
                            >
                                Location
                            </p>
                        )}
                    </div>
                </div>

                <div className="bg-gray-800/50 max-h-full">
                    {view == 'summary' &&
                        (loading ? (
                            <div className="flex justify-center items-center text-xs">
                                <div className="h-4 w-4 loader" />
                            </div>
                        ) : (
                            <>
                                <div className="flex justify-around items-center bg-gray-700 rounded-lg mb-3">
                                    {actors && (
                                        <ProfilePanelTabHeader
                                            title={'Active Actors'}
                                            onClick={() => setTab('actor')}
                                            active={tab == 'actor'}
                                            count={stats?.actorCount}
                                            riskCount={stats?.highRiskActorCount}
                                        />
                                    )}
                                    {targets && (
                                        <ProfilePanelTabHeader
                                            title={'Active Targets'}
                                            onClick={() => setTab('target')}
                                            active={tab == 'target'}
                                            count={stats?.targetCount}
                                            riskCount={stats?.highRiskTargetCount}
                                        />
                                    )}
                                    {devices && (
                                        <ProfilePanelTabHeader
                                            title={'Active Devices'}
                                            onClick={() => setTab('device')}
                                            active={tab == 'device'}
                                            count={stats?.deviceCount}
                                            riskCount={stats?.highRiskActorCount}
                                        />
                                    )}
                                    {applications && (
                                        <ProfilePanelTabHeader
                                            title={'Active Applications'}
                                            onClick={() => setTab('application')}
                                            active={tab == 'application'}
                                            count={stats?.applicationCount}
                                            riskCount={stats?.highRiskApplicationCount}
                                        />
                                    )}
                                </div>
                                {tab == 'actor' && actors && (
                                    <AccessorList items={actors} entityType="STATS_ENTITY_TYPE_ACTOR" />
                                )}
                                {tab == 'target' && targets && (
                                    <AccessorList items={targets} entityType="STATS_ENTITY_TYPE_TARGET" />
                                )}
                                {tab == 'device' && devices && (
                                    <AccessorList items={devices} entityType="STATS_ENTITY_TYPE_DEVICE" />
                                )}
                                {tab == 'application' && applications && (
                                    <AccessorList items={applications} entityType="STATS_ENTITY_TYPE_APPLICATION" />
                                )}
                                {tab == 'identity' && identities && (
                                    <AccessorList items={identities} entityType="STATS_ENTITY_TYPE_ENTITY" />
                                )}
                            </>
                        ))}
                    {view == 'events' && (
                        <EventsTable
                            expanded={expanded}
                            onClickExpandIcon={() => setExpanded(!expanded)}
                            node={node}
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                        />
                    )}
                    {view == 'attributes' && (
                        <div className="bg-gray-700 rounded-lg p-4">
                            <NodeAttributeDisplay node={node} startDate={startDate} endDate={endDate} />
                        </div>
                    )}
                    {view == 'activity' && <NodeTheWall node={node} startDate={startDate} endDate={endDate} />}
                    {view == 'trust' && <NodeTrustDisplay node={node} startDate={startDate} endDate={endDate} />}
                    {view == 'location' && (
                        <div className="bg-gray-700 rounded-lg p-4">
                            <NodeLocationDisplay node={node} startDate={startDate} endDate={endDate} />
                        </div>
                    )}
                </div>
                <div className="flex justify-end">
                    <span className="uppercase tracking-wider text-xxs place-items-center flex mt-2 text-gray-400">
                        {format(selectedStartDate, 'EEE do, HH:mm')} - {format(selectedEndDate, 'EEE do, HH:mm')}{' '}
                        <span className="text-gray-500">({getUsersTimezone()})</span>
                        <SkipToTime startDate={selectedStartDate} endDate={selectedEndDate} />
                    </span>
                </div>
            </div>
        </ProfilePanel>
    );
};

export default Profile;
