import { Node } from 'Types/types';
import { getIconSourceURL, getNodeIconElement } from 'Map/Graph/Icons';
import { PieChart, Pie, Cell, Label } from 'recharts';
import { useMutation, useQuery } from '@apollo/client';
import {
    ADD_TARGETS_TO_POLICY_PROFILE,
    LIST_POLICY_PROFILES,
    REMOVE_TARGETS_FROM_POLICY_PROFILE,
} from 'Graph/typedQueries';
import { useNodes, useTenant } from 'Hooks/Hooks';
import { useMemo, useState } from 'react';
import { ListPolicyProfilesQuery, PolicyAction } from 'GeneratedGQL/graphql';
import { GET_ENTITIES_BY_TYPE_AS_NODES } from 'Graph/queries';
import { BackendNode } from 'Map/Graph/Data';
import { useForm } from 'react-hook-form';
import { SelectedNavItem } from '../PolicyModalTypes';
import { classNames } from 'Utilities/utils';

type AdaptiveTrustPolicyProps = {
    selectedTarget: Node | undefined;
    selectedTargetId: string | undefined;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<SelectedNavItem | undefined>>;
};

type TrustProfile = ListPolicyProfilesQuery['listPolicyProfiles'][number];

const now = new Date().getTime();

type AdaptiveTrustPolicyFormData = {
    policyProfileId: string;
    targetId: string;
    threshold: number;
    action: PolicyAction;
};

type BoundAdaptiveTrustPolicy = {
    targetId: string;
    trustProfileId: string;
    trustProfile: TrustProfile;
    threshold?: number;
    action?: PolicyAction;
};

export const AdaptiveTrustPolicy = ({
    selectedTargetId,
    setSelectedNavItem,
}: AdaptiveTrustPolicyProps): JSX.Element => {
    const tenantId = useTenant();

    const nodeIds = useMemo(() => (selectedTargetId ? [selectedTargetId] : []), [selectedTargetId]);

    const { nodes, loading: loadingNode } = useNodes(nodeIds, 'STATS_ENTITY_TYPE_TARGET');
    const node = nodes[selectedTargetId || ''];

    const [addTargetsToPolicyProfile, { loading: loadingAddTargetsToPolicyProfile }] =
        useMutation(ADD_TARGETS_TO_POLICY_PROFILE);

    const { data: dataTrustProfiles, loading: loadingTrustProfiles } = useQuery(LIST_POLICY_PROFILES, {
        variables: { tenantId: tenantId || '' },
    });

    const boundTrustPolicies = useMemo(() => {
        if (dataTrustProfiles) {
            const profiles: BoundAdaptiveTrustPolicy[] = [];

            dataTrustProfiles.listPolicyProfiles.map((profile) => {
                profile.targets.map((target) => {
                    if (target?.targetId === selectedTargetId) {
                        profiles.push({
                            targetId: selectedTargetId || '',
                            trustProfileId: profile.profileId,
                            trustProfile: profile,
                            threshold: target?.threshold,
                            action: target?.action,
                        });
                    }
                });
            });

            console.log('Bound trust policies:', profiles);

            return profiles;
        }
    }, [dataTrustProfiles, selectedTargetId]);

    const { data: dataTargets, loading: loadingTargets } = useQuery(GET_ENTITIES_BY_TYPE_AS_NODES, {
        variables: {
            tenantId,
            entityType: 'STATS_ENTITY_TYPE_TARGET',
            permissionsOnly: false,
            dateInMs: now,
        },
    });

    const targets = dataTargets?.getEntitiesByTypeAsNodes.nodes;

    const onSubmit = (data: AdaptiveTrustPolicyFormData) => {
        console.log('Adding trust policy:', data);

        addTargetsToPolicyProfile({
            variables: {
                tenantId: tenantId || '',
                policyProfileId: data.policyProfileId,
                targets: [{ targetId: data.targetId, options: [], threshold: data.threshold, action: data.action }],
            },
            refetchQueries: ['listPolicyProfiles'],
            awaitRefetchQueries: true,
        });

        setUpdating(false);
        setAddingAnotherPolicyTargetId(undefined);
        setSelectedNavItem({ type: 'target', id: data.targetId });
    };

    const {
        register,
        reset,
        handleSubmit,
        setValue,
        getValues,
        formState: { isDirty },
    } = useForm<AdaptiveTrustPolicyFormData>({
        defaultValues: { threshold: 75, action: PolicyAction.PolicyActionMonitor },
    });

    const loading = loadingNode || loadingTrustProfiles || loadingTargets || loadingAddTargetsToPolicyProfile;

    const edit = (boundTrustPolicy: BoundAdaptiveTrustPolicy) => {
        setUpdating(true);
        setSelectedNavItem({ type: 'target' });
        reset({
            policyProfileId: boundTrustPolicy.trustProfileId,
            targetId: boundTrustPolicy.targetId,
            threshold: boundTrustPolicy.threshold || 75,
            action: boundTrustPolicy.action || PolicyAction.PolicyActionMonitor,
        });
    };

    const [updating, setUpdating] = useState(false);
    const [addingAnotherPolicyTargetId, setAddingAnotherPolicyTargetId] = useState<string | undefined>();

    return (
        <div className="p-4 ">
            <div className="flex justify-between items-center mb-4 h-8 ">
                <div className="flex items-center space-x-2">
                    <p className="uppercase tracking-wider font-bold text-xs text-gray-400">Adaptive Trust Policy</p>
                    {loading && <span className="h-4 w-4 loader" />}
                </div>
            </div>

            {selectedTargetId ? (
                node && (
                    <div>
                        <div className="pt-0 pb-4">
                            <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap text-yellow-400">
                                <div className="ml-4 mt-4">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0">
                                            <img
                                                className="h-12 w-12"
                                                src={node && getIconSourceURL(getNodeIconElement(node))}
                                                alt=""
                                            />
                                        </div>
                                        <div className="ml-2">
                                            <h3 className="text-lg leading-6 font-medium text-gray-200">
                                                {node?.props.displayName}
                                            </h3>
                                            <p className="text-xs text-gray-400">
                                                Manage the {boundTrustPolicies?.length} policies for{' '}
                                                {node?.props.displayName}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {boundTrustPolicies?.map((boundTrustPolicy) => {
                            return (
                                <TrustPolicyCard
                                    {...boundTrustPolicy}
                                    onEdit={edit}
                                    key={`${boundTrustPolicy.trustProfileId}${boundTrustPolicy.threshold}`}
                                />
                            );
                        })}

                        <div
                            className="p-4 rounded-md bg-gray-700 mt-4 text-gray-500 hover:text-gray-200 text-xs cursor-pointer font-semibold"
                            onClick={() => {
                                setUpdating(false);
                                setAddingAnotherPolicyTargetId(selectedTargetId);
                                setValue('targetId', selectedTargetId);
                                setSelectedNavItem({ type: 'target' });
                            }}
                        >
                            <p className="">Add Another Policy +</p>
                        </div>
                    </div>
                )
            ) : (
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="space-y-4">
                            <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Choose Target</h4>
                                <select
                                    className="w-full input-gray text-xs rounded-none"
                                    required
                                    disabled={updating || Boolean(addingAnotherPolicyTargetId)}
                                    {...register('targetId')}
                                >
                                    <option value="">Select a Target</option>
                                    <hr />
                                    {targets &&
                                        targets.map((target: BackendNode) => {
                                            return (
                                                <option key={target.nodeId} value={target.nodeId}>
                                                    {target.props.displayName}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Choose Profile</h4>
                                <select
                                    className="w-full input-gray text-xs rounded-none"
                                    required
                                    disabled={updating}
                                    {...register('policyProfileId')}
                                >
                                    <option value="">Select a Profile</option>
                                    <hr />
                                    {dataTrustProfiles &&
                                        dataTrustProfiles.listPolicyProfiles.map((profile) => {
                                            return (
                                                <option key={profile.profileId} value={profile.profileId}>
                                                    {profile.displayName}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>

                            <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Threshold</h4>
                                <input
                                    type="number"
                                    min={0}
                                    max={100}
                                    className="w-full input-gray text-xs rounded-none"
                                    {...register('threshold')}
                                    required
                                />
                            </div>

                            <div>
                                <h4 className="text-xs leading-6 font-medium text-gray-300 mb-1">Action</h4>
                                <select
                                    className="w-full input-gray text-xs rounded-none"
                                    {...register('action')}
                                    required
                                >
                                    <option value={PolicyAction.PolicyActionDisable}>Disable</option>
                                    <hr />
                                    <option value={PolicyAction.PolicyActionMonitor}>Monitor</option>
                                    <option value={PolicyAction.PolicyActionAlert}>Alert</option>
                                    <option value={PolicyAction.PolicyActionEnforce}>Enforce</option>
                                </select>
                            </div>

                            <div className="flex space-x-2 mt-4">
                                <button
                                    className={classNames(
                                        'btn text-xs rounded-md',
                                        isDirty ? 'btn-primary' : 'btn-disabled',
                                        loading ? 'btn-disabled' : '',
                                    )}
                                    disabled={!isDirty || loading}
                                    type="submit"
                                >
                                    {updating ? 'Update Policy' : 'Add Policy'}
                                </button>

                                <button
                                    className={classNames(
                                        'btn text-xs rounded-md',
                                        loadingAddTargetsToPolicyProfile ? 'btn-disabled' : '',
                                    )}
                                    disabled={loadingAddTargetsToPolicyProfile}
                                    onClick={() => {
                                        if (updating) {
                                            setUpdating(false);
                                            const targetId = getValues('targetId');
                                            targetId && setSelectedNavItem({ type: 'target', id: targetId });
                                        } else if (addingAnotherPolicyTargetId) {
                                            setSelectedNavItem({ type: 'target', id: addingAnotherPolicyTargetId });
                                            setAddingAnotherPolicyTargetId(undefined);
                                        } else {
                                            setSelectedNavItem(undefined);
                                        }
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export const policyActionLookup: Record<PolicyAction, string> = {
    [PolicyAction.PolicyActionMonitor]: 'Monitor',
    [PolicyAction.PolicyActionAlert]: 'Alert',
    [PolicyAction.PolicyActionEnforce]: 'Enforce',
    [PolicyAction.PolicyActionDisable]: 'Disable',
    [PolicyAction.PolicyActionUnknown]: 'Unknown',
};

const TrustPolicyCard = ({
    targetId,
    trustProfile,
    threshold,
    action,
    onEdit,
}: BoundAdaptiveTrustPolicy & { onEdit: (boundTrustPolicy: BoundAdaptiveTrustPolicy) => void }) => {
    const pieColor = 'rgb(12 92 151)';
    const pieSlices = [
        { value: threshold, color: pieColor },
        { value: 100 - (threshold || 0), color: 'rgb(31 41 55)' },
    ];

    const tenantId = useTenant();

    const [removeTargetsFromPolicyProfile, { loading }] = useMutation(REMOVE_TARGETS_FROM_POLICY_PROFILE);

    const removeTargetFromPolicyProfile = async () => {
        console.log(`Deleting trust policy where targetId=${targetId} and profileId=${trustProfile.profileId}`);
        await removeTargetsFromPolicyProfile({
            variables: {
                tenantId: tenantId || '',
                policyProfileId: trustProfile.profileId,
                targetIds: [targetId],
            },
            refetchQueries: ['listPolicyProfiles', 'getPolicyProfile'],
        });
    };

    return (
        <div className="w-full px-4 py-2 rounded-md bg-gray-700 my-4 flex">
            <div className="flex flex-col mr-4">
                <div className="flex -ml-1">
                    <PieChart width={100} height={100}>
                        <Pie
                            data={pieSlices}
                            dataKey="value"
                            innerRadius={'75%'}
                            outerRadius={'100%'}
                            paddingAngle={0}
                            stroke="none"
                            isAnimationActive={false}
                        >
                            {pieSlices.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                            <Label
                                value={pieSlices[0].value}
                                position="center"
                                fontSize={24}
                                fontWeight="bold"
                                fill="white"
                            />
                        </Pie>
                    </PieChart>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 w-full">
                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Trust Profile</p>
                        <div className="text-md font-semibold text-gray-200 tracking-wide">
                            {trustProfile.displayName}
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Action</p>
                        <div className="text-md font-semibold text-gray-200 tracking-wide">
                            {action && policyActionLookup[action]}
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-full">
                        <p className="text-xs uppercase text-gray-400 font-semibold tracking-widest">Controls</p>
                        <div className="flex space-x-2 mt-1">
                            <button
                                className="btn text-xs bg-gray-600 rounded-md px-4 py-1"
                                type="submit"
                                onClick={() =>
                                    onEdit({
                                        targetId,
                                        trustProfileId: trustProfile.profileId,
                                        trustProfile,
                                        threshold,
                                        action,
                                    })
                                }
                            >
                                Edit
                            </button>
                            {loading ? (
                                <div className="h-4 w-4 loader" />
                            ) : (
                                <button
                                    className="btn text-xs bg-gray-600 rounded-md px-4 py-1"
                                    type="submit"
                                    onClick={removeTargetFromPolicyProfile}
                                >
                                    Delete
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
