import {
    PauseIcon,
    QuestionMarkCircleIcon,
    ShieldCheckIcon,
    ShieldExclamationIcon,
    StopIcon,
    UserCircleIcon,
    UserIcon,
    UserPlusIcon,
    XCircleIcon,
} from '@heroicons/react/20/solid';
import { PlayIcon } from '@heroicons/react/20/solid';

export type WallEvent = {
    key: string;
    nodeId: string;
    externalId: string;
    alternateId: string;
    displayName: string;
    type: string;
    publishedAt: number;
    sourceProcessorType: string;
    object: string;
};

const wallTypeIcons: Record<string, JSX.Element> = {
    WallEventType_OTHER: (
        <div className="bg-gray-500 rounded-full p-1.5">
            <QuestionMarkCircleIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_SESSION_START: (
        <div className="bg-gray-500 rounded-full p-1.5">
            <PlayIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_SESSION_END: (
        <div className="bg-gray-500 rounded-full p-1.5">
            <StopIcon className="h-3 text-gray-800" />
        </div>
    ),
    WallEventType_LOGIN_FAILED: (
        <div className="bg-red-500 rounded-full p-1.5">
            <XCircleIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_ACTOR_ACTIVITY_START: (
        <div className="bg-green-500 rounded-full p-1.5">
            <UserCircleIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_ACTOR_ACTIVITY_PAUSED: (
        <div className="bg-gray-500 rounded-full p-1.5">
            <PauseIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_ACTOR_CREATED: (
        <div className="bg-green-500 rounded-full p-1.5">
            <UserPlusIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_ACTOR_UPDATED: (
        <div className="bg-blue-500 rounded-full p-1.5">
            <UserIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_ACTOR_ENABLED: (
        <div className="bg-blue-500 rounded-full p-1.5">
            <ShieldCheckIcon className="h-3 text-gray-200" />
        </div>
    ),
    WallEventType_ACTOR_DISABLED: (
        <div className="bg-yellow-500 rounded-full p-1.5">
            <ShieldExclamationIcon className="h-3 text-gray-200" />
        </div>
    ),
};

export const getWallTypeIcon = (type: string) => {
    return wallTypeIcons[type] || <QuestionMarkCircleIcon />;
};

import ProviderAzureAD from 'assets/icons/Providers/AzureAD.png';
import Provider from 'assets/icons/Providers/Provider.png';
import ProviderOkta from 'assets/icons/Providers/Okta.png';
import ProviderOffice365 from 'assets/icons/Providers/Office365.png';

type ImageLocations = Record<string, string>;

const providerIconLocations: ImageLocations = {
    Default: Provider,
    okta: ProviderOkta,
    azuread: ProviderAzureAD,
    office365: ProviderOffice365,
};

export const getWallProviderIcon = (provider: string) => {
    return providerIconLocations[provider] || Provider;
};
