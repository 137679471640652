import { classNames } from 'Utilities/utils';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';

export interface RuleCardProps {
    name: string;
    riskLevel: number;
    icon: JSX.Element;
    description: string;
    author: string;
}

type RiskLevel = {
    id: number;
    name: string;
    color: string;
    textColor: string;
};

const riskLevels: RiskLevel[] = [
    { id: 0, name: 'Disabled', color: 'bg-gray-400', textColor: 'text-gray-00' },
    { id: 1, name: 'Low', color: 'bg-green-400', textColor: 'text-green-400' },
    { id: 2, name: 'Medium', color: 'bg-yellow-500', textColor: 'text-yellow-400' },
    { id: 3, name: 'High', color: 'bg-red-500', textColor: 'text-red-400' },
    { id: 4, name: 'Critical', color: 'bg-black', textColor: 'text-red-400' },
];

export const RuleCardMock = ({ name, riskLevel, icon, description, author }: RuleCardProps): JSX.Element => {
    const [selected, setSelected] = useState(riskLevels[riskLevel]);
    return (
        <div key={name} className="py-1 relative">
            <div className="ml-6 pl-6 bg-gray-700 rounded-md">
                <div
                    className={classNames(
                        selected.id === 0 ? 'bg-gray-600' : 'bg-gray-600 border-blue-700 border-2',
                        'transition rounded-full shadow-lg absolute left-0 top-7 h-12 w-12 text-white flex items-center justify-center',
                    )}
                >
                    <div className="h-6 w-6">{icon}</div>
                </div>
                <div className="p-4 relative flex justify-between items-center">
                    <div>
                        <h4 className="text-md font-semibold mb-1 text-white mr-6">{name}</h4>
                        <p className="text-white mb-1 text-xs">{description}</p>
                        <p className="text-gray-400 mb-1 text-xs">Created by {author}</p>
                    </div>
                    <div className="">
                        <RiskSelector selected={selected} setSelected={setSelected} />
                    </div>
                </div>
            </div>
        </div>
    );
};

interface RiskSelectorProps {
    selected: RiskLevel;
    setSelected: React.Dispatch<React.SetStateAction<{}>>;
}

const RiskSelector = ({ selected, setSelected }: RiskSelectorProps): JSX.Element => {
    return (
        <Listbox
            value={selected}
            onChange={(s: RiskLevel) => {
                setSelected(s);
            }}
        >
            {({ open }) => (
                <>
                    <div className="mt-1 relative">
                        <Listbox.Button className="relative w-52 bg-gray-600 text-gray-200 border border-gray-700 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                            <div className="flex items-center">
                                <span
                                    className={classNames(
                                        selected.color,
                                        'flex-shrink-0 inline-block h-2 w-2 rounded-full',
                                    )}
                                />
                                <span className="ml-3 block truncate text-xs">{selected.name}</span>
                            </div>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 bg-gray-600 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-gray-200 ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-52">
                                {riskLevels.map((level) => (
                                    <Listbox.Option
                                        key={level.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'text-white bg-blue-600' : 'text-gray-200',
                                                'cursor-default select-none relative py-2 pl-3 pr-9',
                                            )
                                        }
                                        value={level}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center text-xs">
                                                    <span
                                                        className={classNames(
                                                            level.color,
                                                            'flex-shrink-0 inline-block h-2 w-2 rounded-full',
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    <span
                                                        className={classNames(
                                                            selected ? 'font-semibold' : 'font-normal',
                                                            'ml-3 block truncate text-xs',
                                                        )}
                                                    >
                                                        {level.name}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-blue-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4',
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
};
