import React, { useEffect } from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/link-context';
import { useAuth } from 'Hooks/Auth';
import { useFlags } from 'launchdarkly-react-client-sdk';

const AuthorizedApolloProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
    const client = React.useRef<ApolloClient<NormalizedCacheObject> | undefined>();
    const { getToken } = useAuth();
    const { graphqlUri } = useFlags();
    const [currentGraphQLURI, setCurrentGraphQLURI] = React.useState<string | undefined>(undefined);

    // Derive the GraphQL URI from either the Launch Darkly flag or the config file
    useEffect(() => {
        let resolvedGraphQLUri = null;
        let graphQLUriSource = undefined;

        if (graphqlUri && graphqlUri !== 'USE_CONFIG_FILE') {
            resolvedGraphQLUri = graphqlUri;
            graphQLUriSource = 'Launch Darkly';
        }

        if (graphqlUri === 'USE_CONFIG_FILE') {
            resolvedGraphQLUri = window.__env__.GRAPHQL_URI;

            if (import.meta.env.VITE_GRAPHQL_URI) {
                resolvedGraphQLUri = import.meta.env.VITE_GRAPHQL_URI;
                graphQLUriSource = 'Environment Variable';
                console.log(`Using GRAPHQL_URI from ENV variable: ${resolvedGraphQLUri}`);
            } else {
                graphQLUriSource = 'Config File';
            }

            if (!resolvedGraphQLUri) {
                throw new Error('GRAPHQL_URI runtime variable is not set');
            }
        }

        if (resolvedGraphQLUri && resolvedGraphQLUri !== currentGraphQLURI) {
            const httpLink = new HttpLink({
                uri: resolvedGraphQLUri,
            });

            const authLink = setContext(async (_, { headers, ...rest }) => {
                const token = await getToken();

                if (!token) return { headers, ...rest };

                return {
                    ...rest,
                    headers: {
                        ...headers,
                        authorization: `Bearer ${token}`,
                    },
                };
            });

            console.log(`Using GraphQL URI from: ${graphQLUriSource}`);
            console.log('Creating Apollo Client with resolved GraphQL URI:', resolvedGraphQLUri);
            client.current = new ApolloClient({
                connectToDevTools: true,
                link: authLink.concat(httpLink),
                cache: new InMemoryCache({
                    typePolicies: {
                        User: {
                            keyFields: ['userId'],
                        },
                        Provider: {
                            keyFields: ['providerId'],
                        },
                        GetEntityListItem: {
                            keyFields: false,
                        },
                        GetEntityByNeighborCountItem: {
                            keyFields: false,
                        },
                        GetEntityByStatusItem: {
                            keyFields: false,
                        },
                        GetEntityDormantItem: {
                            keyFields: false,
                        },
                        GetEntityAccessorsEntityItem: {
                            keyFields: false,
                        },
                    },
                }),
            });
            setCurrentGraphQLURI(resolvedGraphQLUri);
        }
    }, [currentGraphQLURI, getToken, graphqlUri]);

    // Until we receive details from launch darkly on which graphql endpoint to use,
    // we will display a waiting message
    if (!graphqlUri || !client.current) {
        return <NoGraphQLURI />;
    }

    return <ApolloProvider client={client.current}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;

export const NoGraphQLURI = () => {
    return (
        <div className="flex h-screen">
            <div className="m-auto text-center">
                <p className="text-gray-500 text-xs">Resolving your Double Zero instance...</p>
            </div>
        </div>
    );
};
