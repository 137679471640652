import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
    NoSymbolIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    PauseIcon,
    DocumentDuplicateIcon,
    TrashIcon,
} from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';

interface ToolbarProps {
    setEditorWindow: React.Dispatch<React.SetStateAction<boolean>>;
    editorWindow: boolean;
}

export const ToolbarMock = ({ setEditorWindow, editorWindow }: ToolbarProps): JSX.Element => {
    return (
        <div className="flex items-center justify-between p-4 h-16">
            <p className="uppercase tracking-wider font-bold text-xs text-gray-400 pointer-events-none">Editor</p>
            <div className="flex items-center">
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-gray-700 text-xs font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mr-2 sm:w-auto sm:text-xs"
                    onClick={() => setEditorWindow(!editorWindow)}
                >
                    {editorWindow ? `Switch to Options` : `Switch to Editor`}
                </button>
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-gray-700 text-xs font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-1 sm:w-auto sm:text-xs"
                >
                    Validate
                </button>
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-gray-700 text-xs font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-1 sm:w-auto sm:text-xs"
                >
                    Save
                </button>
                <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 bg-gray-700 text-xs font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:ml-1 sm:w-auto sm:text-xs"
                >
                    Reset
                </button>
                {/* Dropdown */}
                <Menu as="div" className="relative inline-block text-left ml-2">
                    <div>
                        <Menu.Button className="inline-flex justify-center w-full rounded-md px-3 py-2 bg-blue-700 text-xs font-medium text-white hover:bg-blue-800 focus:outline-none">
                            Actions
                            <ChevronDownIcon className="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-gray-700 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                            <div className="py-1">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                'group flex items-center px-4 py-2 text-xs',
                                            )}
                                        >
                                            <CheckCircleIcon
                                                className="mr-3 h-5 w-5 text-green-400"
                                                aria-hidden="true"
                                            />
                                            Publish
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                'group flex items-center px-4 py-2 text-xs',
                                            )}
                                        >
                                            <PauseIcon className="mr-3 h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            Pause
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                'group flex items-center px-4 py-2 text-xs',
                                            )}
                                        >
                                            <NoSymbolIcon className="mr-3 h-5 w-5 text-red-400" aria-hidden="true" />
                                            Disable
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                'group flex items-center px-4 py-2 text-xs',
                                            )}
                                        >
                                            <DocumentDuplicateIcon
                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                            Duplicate
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href="#"
                                            className={classNames(
                                                active ? 'bg-gray-600 text-white' : 'text-gray-300',
                                                'group flex items-center px-4 py-2 text-xs',
                                            )}
                                        >
                                            <TrashIcon
                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                aria-hidden="true"
                                            />
                                            Delete
                                        </a>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
};
