import {
    NoSymbolIcon,
    CheckIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronUpIcon,
    DocumentMagnifyingGlassIcon,
    ExclamationTriangleIcon,
    ArrowTopRightOnSquareIcon,
    FunnelIcon,
    QuestionMarkCircleIcon,
    ChevronUpDownIcon,
} from '@heroicons/react/20/solid';

import ProviderAzureAD from 'assets/icons/Providers/AzureAD.png';
import Provider from 'assets/icons/Providers/Provider.png';
import ProviderOffice365 from 'assets/icons/Providers/Office365.png';
import ProviderOkta from 'assets/icons/Providers/Okta.png';

import { useMemo, useState } from 'react';
import { Column, Row, useFilters, usePagination, useSortBy, useTable } from 'react-table';
import { format } from 'date-fns';
import { Tooltip } from 'Library/Tooltip';
import { classNames, getDisplayName, outcomeLookup, providerNameLookup } from 'Utilities/utils';
import {
    DefaultColumnFilter,
    filterGreaterThan,
    fuzzyDateFilterFn,
    fuzzyNodeDisplayNameFilterFn,
    fuzzyTextFilterFn,
    providerDisplayNameFilterFn,
    sortGreaterThan,
    sortPolicyStats,
    sortTableNodes,
} from 'Library/TableComponents';
import { MfaCell, NodeCell } from 'Map/DataBrowser/DataBrowserCells';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import CsvDownloader from 'react-csv-downloader';
import { Node } from 'Types/types';

interface Table<T extends object> {
    columns: Array<Column<T>>;
    data: T[];
    rowClickHandler?: (row: Row<T>) => void;
    loading: boolean;
    error: boolean;
}

export const EventsTableBase = ({
    columns,
    // Since we have a number of data types that use the same table,
    // we need data to be "any" type to allow for different data types
    // we'll ask the compiler to ignore this error for now :)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    data,
    rowClickHandler,
    loading,
    error,
}: Table<typeof data>) => {
    const [toggleFilters, setToggleFilters] = useState(false);

    const filterTypes = useMemo(
        () => ({
            fuzzyText: fuzzyTextFilterFn,
            fuzzyNodeDisplayName: fuzzyNodeDisplayNameFilterFn,
            fuzzyDate: fuzzyDateFilterFn,
            greaterThan: filterGreaterThan,
            providerDisplayName: providerDisplayNameFilterFn,
        }),
        [],
    );

    const sortTypes = useMemo(
        () => ({
            nodeDisplayName: sortTableNodes,
            policyStats: sortPolicyStats,
            greaterThan: sortGreaterThan,
        }),
        [],
    );
    const defaultColumn = useMemo(
        () => ({
            Filter: DefaultColumnFilter,
        }),
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
        rows,
    } = useTable<typeof data>(
        {
            columns,
            data,
            defaultColumn,
            filterTypes,
            sortTypes,
            disableMultiSort: true,
            initialState: { pageIndex: 0, pageSize: 20 },
        },
        useFilters,
        useSortBy,
        usePagination,
        useFilters,
    );

    const exportToCsv = () => {
        const columnHeadersByAccessor: Record<string, string> = {};
        columns.map((column) => {
            if (column.accessor) {
                const accessor = column.accessor as string;
                columnHeadersByAccessor[accessor] = column.Header as string;
            }
        });

        const csvData = rows.map((row) => {
            const data = row.original;
            const csvRow: Record<string, string> = {};

            Object.entries(columnHeadersByAccessor).map(([accessor, header]) => {
                if (data[accessor]) {
                    const value = data[accessor];
                    let field = '';
                    switch (accessor) {
                        case 'node':
                        case 'actor':
                        case 'identity':
                        case 'target':
                            field = getDisplayName(value as Node);
                            break;
                        case 'date':
                            field = format(new Date(value), 'EEE MMM do HH:mm');
                            break;
                        case 'outcome':
                            field = outcomeLookup(value);
                            break;
                        case 'provider':
                            field = providerNameLookup(value);
                            break;
                        default:
                            field = String(value);
                    }
                    csvRow[`"${header}"`] = `"${field}"`;
                }
            });

            return csvRow;
        });

        return csvData;
    };

    const renderBody = () => {
        if (loading) {
            return (
                <tbody className="text-center text-gray-300">
                    <tr>
                        <td colSpan={columns.length}>
                            <div className="flex flex-col items-center justify-center pt-4">
                                <div className="h-8 w-8 loader mb-3" />
                                Loading...
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
        }
        if (error) {
            return (
                <tbody className="text-center text-red-400">
                    <tr>
                        <td colSpan={columns.length}>
                            <div className="flex flex-col items-center justify-center pt-4">
                                <NoSymbolIcon className="h-8 w-8 text-red-400 mb-3" />
                                Error loading results
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
        }
        if (data.length === 0) {
            return (
                <tbody className="text-center text-gray-300">
                    <tr>
                        <td colSpan={columns.length}>
                            <div className="flex flex-col items-center justify-center pt-4">
                                <DocumentMagnifyingGlassIcon className="h-8 w-8 text-gray-400 mb-3" />
                                No matching events in this time period
                            </div>
                        </td>
                    </tr>
                </tbody>
            );
        }

        return (
            <tbody {...getTableBodyProps()}>
                {data.length > 0 &&
                    page.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                className="text-gray-300 hover:bg-gray-900 cursor-pointer"
                                onClick={() => rowClickHandler && rowClickHandler(row)}
                            >
                                {row.cells.map((cell) => {
                                    switch (cell.column.id) {
                                        case 'outcome':
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="py-1.5 px-2 table-fixed max-w-[100px] whitespace-nowrap"
                                                >
                                                    {cell.value == 'OUTCOME_SUCCESS' ? (
                                                        <div className="flex text-xs items-center">
                                                            <CheckIcon className="text-[rgb(101,163,13)] h-4 w-4 mr-1.5" />{' '}
                                                            Success
                                                        </div>
                                                    ) : cell.value == 'OUTCOME_CHALLENGE' ? (
                                                        <div className="flex text-xs items-center">
                                                            <ExclamationTriangleIcon className="text-[rgb(245,158,11)] h-4 w-4 mr-1.5" />{' '}
                                                            Challenge
                                                        </div>
                                                    ) : cell.value == 'OUTCOME_FAILURE' ? (
                                                        <div className="flex text-xs items-center">
                                                            <NoSymbolIcon className="text-[rgb(225,29,72)] first-letter:h-4 w-4 mr-1.5" />{' '}
                                                            Failure
                                                        </div>
                                                    ) : (
                                                        <div className="flex text-xs items-center">
                                                            <QuestionMarkCircleIcon className="text-gray-400 h-4 w-4 mr-1.5" />{' '}
                                                            Other
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                        case 'mfa':
                                            return <MfaCell cell={cell} />;
                                        case 'provider':
                                            return (
                                                <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                    {cell.value == 'okta' ? (
                                                        <div className="flex text-xs items-center">
                                                            <img src={ProviderOkta} className="h-4 w-4 mr-1.5" /> Okta
                                                        </div>
                                                    ) : cell.value == 'azuread' ? (
                                                        <div className="flex text-xs items-center">
                                                            <img src={ProviderAzureAD} className="h-4 w-4 mr-1.5" />{' '}
                                                            Azure AD
                                                        </div>
                                                    ) : cell.value == 'office365' ? (
                                                        <div className="flex text-xs items-center">
                                                            <img src={ProviderOffice365} className="h-4 w-4 mr-1.5" />{' '}
                                                            Office 365
                                                        </div>
                                                    ) : (
                                                        <div className="flex text-xs items-center">
                                                            <img src={Provider} className="h-4 w-4 mr-1.5" />{' '}
                                                            {cell.value}
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                        case 'count':
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="py-1.5 px-2 whitespace-nowrap table-fixed w-[72px]
                                            "
                                                >
                                                    {cell.value}
                                                </td>
                                            );
                                        case 'raw':
                                            return (
                                                <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                    <a
                                                        href="#"
                                                        className="flex text-xs items-center text-blue-400"
                                                        target="_blank"
                                                    >
                                                        <DocumentMagnifyingGlassIcon className="h-4 w-4 mr-1.5 text-white" />{' '}
                                                        View
                                                    </a>
                                                </td>
                                            );
                                        case 'node':
                                            return <NodeCell cell={cell} width={'max-w-[220px]'} key={row.id} />;
                                        case 'reason':
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="py-1.5 px-2 whitespace-nowrap max-w-[320px]"
                                                >
                                                    <div className="flex items-center justify-start relative">
                                                        <div className="truncate pr-8">{cell.value}</div>

                                                        <Tooltip
                                                            className="w-auto max-w-[300px] px-2 py-1.5 text-left rounded-md text-xs bg-black/90"
                                                            label={cell.value}
                                                        >
                                                            <QuestionMarkCircleIcon className="text-blue-400 w-4 h-4 ml-3 absolute right-2" />
                                                        </Tooltip>
                                                    </div>
                                                </td>
                                            );
                                        case 'risk':
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className="py-1.5 px-2 table-fixed w-[72px] whitespace-nowrap"
                                                >
                                                    {cell.value}
                                                </td>
                                            );
                                        case 'location.latlong':
                                            return (
                                                <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                    gi{' '}
                                                    <a
                                                        target="_blank"
                                                        href={`http://maps.google.com/maps?q=loc:${cell.value[0]}`}
                                                        className="font-medium text-blue-400 hover:text-blue-500 inline-flex items-center flex-shrink-1 justify-start leading-3"
                                                    >
                                                        {cell.value[1]}
                                                        <ArrowTopRightOnSquareIcon className="h-3 w-3 relative ml-2" />
                                                    </a>
                                                </td>
                                            );
                                        case 'date':
                                            return (
                                                <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                    {format(new Date(cell.value), 'EEE MMM do HH:mm')}
                                                </td>
                                            );
                                        default:
                                            return (
                                                <td {...cell.getCellProps()} className="py-1.5 px-2 whitespace-nowrap">
                                                    {cell.value}
                                                </td>
                                            );
                                    }
                                })}
                            </tr>
                        );
                    })}
            </tbody>
        );
    };

    return (
        <div>
            <table {...getTableProps()} className="w-full text-left text-xs mb-0 rounded-tr-lg">
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} className="pt-2 px-2 align-middle bg-gray-700">
                                    <div
                                        className={classNames(
                                            'flex items-center mb-2',
                                            column.id === 'node' ? 'pl-3' : '',
                                        )}
                                    >
                                        <span>{column.render('Header')}</span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <ChevronDownIcon
                                                    className="h-5 w-5"
                                                    {...column.getSortByToggleProps()}
                                                />
                                            ) : (
                                                <ChevronUpIcon className="h-5 w-5" {...column.getSortByToggleProps()} />
                                            )
                                        ) : (
                                            <ChevronUpDownIcon className="h-5 w-5" {...column.getSortByToggleProps()} />
                                        )}
                                    </div>
                                    {toggleFilters && (
                                        <div className={classNames(column.id === 'node' ? 'pl-4' : 'pl-1')}>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                {renderBody()}
            </table>
            <div className="absolute bottom-3 left-0 w-full px-3 border border-transparent">
                <div className="pagination bg-gray-700 text-xs flex items-center p-2 justify-between rounded-b-md">
                    <div className="flex items-center space-x-1">
                        <div className="">
                            <button
                                type="button"
                                className="btn bg-gray-800 text-xs p-1"
                                onClick={() => {
                                    setToggleFilters(!toggleFilters);
                                }}
                            >
                                <FunnelIcon className="h-4 w-4 mr-1" />{' '}
                                {toggleFilters ? 'Hide Filters' : 'Show Filters'}
                            </button>
                        </div>
                        <Tooltip label="Export to CSV">
                            <div>
                                <CsvDownloader
                                    datas={exportToCsv}
                                    className={'btn bg-gray-800 text-xs p-1 flex items-center justify-center'}
                                    filename="double-zero-access-events-export.csv"
                                >
                                    <DocumentArrowDownIcon className="h-4 w-4" />
                                </CsvDownloader>
                            </div>
                        </Tooltip>
                    </div>

                    <div>
                        Page
                        <strong className="ml-1">
                            {pageOptions.length === 0 ? 0 : pageIndex + 1} of {pageOptions.length}
                        </strong>
                    </div>

                    <div className="flex items-center space-x-0.5">
                        <button
                            className="btn bg-gray-800 text-xs p-1"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            <ChevronDoubleLeftIcon className="h-4 w-4" />
                        </button>
                        <button
                            className="btn bg-gray-800 text-xs p-1"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            <ChevronLeftIcon className="h-4 w-4" />
                        </button>
                        <button
                            className="btn bg-gray-800 text-xs p-1"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            <ChevronRightIcon className="h-4 w-4" />
                        </button>
                        <button
                            className="btn bg-gray-800 text-xs p-1"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            <ChevronDoubleRightIcon className="h-4 w-4" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
