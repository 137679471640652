import { CheckCircleIcon, ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { classNames } from 'Utilities/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTenant } from 'Hooks/Hooks';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_ENTITIES_AS_NODES, LIST_POLICY_DOCUMENTS, LIST_POLICY_PROFILES } from 'Graph/typedQueries';
import { ListPolicyProfilesQuery, PolicyAction, PolicyStatus, StatsEntityType } from 'GeneratedGQL/graphql';
import { BackendNode } from 'Map/Graph/Data';
import { SelectedNavItem } from '../PolicyModalTypes';
interface SideNavProps {
    selectedNavItem: SelectedNavItem | undefined;
    setSelectedNavItem: React.Dispatch<React.SetStateAction<SelectedNavItem | undefined>>;
}
const headerStyle = classNames(
    'border-transparent hover:bg-gray-700 text-gray-300 hover:text-gray-200',
    'cursor-default group border-l-4 px-6 py-2 flex items-center justify-between text-sm font-medium',
);

const getItemStyle = (active: boolean) =>
    classNames(
        active
            ? 'bg-blue-800 border-blue-700 text-gray-200'
            : 'border-transparent hover:bg-gray-700 text-gray-400 hover:text-gray-200',
        'cursor-pointer group border-l-4 pl-10 pr-6 py-2 flex items-center justify-between text-xs font-medium',
    );

const getActionStyle = (active: boolean) =>
    classNames(
        active
            ? 'bg-blue-800 border-blue-700 text-gray-200'
            : 'border-transparent hover:bg-gray-700 text-gray-500 hover:text-gray-200',
        'cursor-pointer group border-l-4 pl-10 pr-6 py-2 flex items-center justify-between text-xs font-medium',
    );

type PolicyDocument = {
    policyDocumentId: string;
    displayName: string;
    description: string;
    policyDocument: string;
    status: PolicyStatus;
    requestedState: PolicyAction;
    version: any;
    originVersion: any;
    signature: string;
    createdAt: any;
};

export const SideNav = ({ selectedNavItem, setSelectedNavItem }: SideNavProps): JSX.Element => {
    const tenantId = useTenant();

    const [policyTargets, setPolicyTargets] = useState<BackendNode[]>([]);

    const [getEntitiesAsNodes] = useLazyQuery(GET_ENTITIES_AS_NODES);

    const [loadingAdaptiveTrustPolicy, setLoadingAdaptiveTrustPolicy] = useState<boolean>(false);
    const [targetsOpen, setTargetsOpen] = useState<boolean>(true);
    const [profilesOpen, setProfilesOpen] = useState<boolean>(true);
    const [documentsOpen, setDocumentsOpen] = useState<boolean>(true);

    // List Policy Profiles
    const { data: policyProfilesData, loading: loadingPolicyProfiles } = useQuery(LIST_POLICY_PROFILES, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId,
    });
    const policyProfiles = useMemo(() => {
        const policyProfiles: ListPolicyProfilesQuery['listPolicyProfiles'] = [];

        policyProfilesData?.listPolicyProfiles.map((policyProfile) => {
            if (policyProfile !== null) {
                policyProfiles.push(policyProfile);
            }
        });

        return policyProfiles.sort((a, b) => a.displayName.localeCompare(b.displayName)) || [];
    }, [policyProfilesData]);

    // List Policy Documents
    const { data: policyDocumentsData, loading: loadingPolicyDocuments } = useQuery(LIST_POLICY_DOCUMENTS, {
        variables: {
            tenantId: tenantId || '',
        },
        skip: !tenantId,
    });
    const policyDocuments = useMemo(() => {
        const policyDocuments: PolicyDocument[] = [];

        policyDocumentsData?.listPolicyDocuments.map((policyDocument) => {
            if (policyDocument !== null) {
                policyDocuments.push(policyDocument);
            }
        });

        return policyDocuments.sort((a, b) => a.displayName.localeCompare(b.displayName)) || [];
    }, [policyDocumentsData]);

    useEffect(() => {
        async function fetchTargets() {
            if (policyProfiles.length === 0) {
                setPolicyTargets([]);
                return;
            }

            setLoadingAdaptiveTrustPolicy(true);

            const targetIds = new Set<string>();

            policyProfiles.forEach((profile) => {
                profile.targets.forEach((target) => {
                    if (target?.targetId) {
                        targetIds.add(target.targetId);
                    }
                });
            });

            const targetResult = await getEntitiesAsNodes({
                variables: {
                    tenantId: tenantId || '',
                    entityIds: Array.from(targetIds),
                    entityType: StatsEntityType.StatsEntityTypeTarget,
                },
            });

            const nodes = [...(targetResult?.data?.getEntitiesAsNodes || [])] || [];
            const sortedNodes = nodes.sort(
                (a, b) => a.props.displayName?.localeCompare(b.props.displayName || '') || 0,
            );
            setPolicyTargets(sortedNodes as BackendNode[]);

            setLoadingAdaptiveTrustPolicy(false);
        }

        fetchTargets();
    }, [getEntitiesAsNodes, policyProfiles, tenantId]);

    return (
        <div className="flex flex-col flex-grow pt-4 pb-4  relative">
            <div className="flex-grow flex flex-col">
                <nav className="flex-1 space-y-4 bg-gray-800" aria-label="Sidebar">
                    <p className="uppercase tracking-wider px-4 font-bold text-xs text-gray-400 mb-4 h-8 flex items-center">
                        Configuration
                    </p>

                    <div>
                        <span className={headerStyle} onClick={() => setTargetsOpen(!targetsOpen)}>
                            <span className="flex align-center">Adaptive Trust Policies</span>
                            <div className="flex align-center space-x-2">
                                {loadingAdaptiveTrustPolicy && <span className="h-4 w-4 loader mr-2" />}
                                {policyTargets.length > 0 && (
                                    <>
                                        <span className="py-0 px-3 text-gray-300 bg-gray-700 text-xxs rounded-lg">
                                            {policyTargets.length}
                                        </span>
                                        {targetsOpen ? (
                                            <ChevronDownIcon width="18" height="18" className="mr-2 float-right" />
                                        ) : (
                                            <ChevronRightIcon width="18" height="18" className="mr-2 float-right" />
                                        )}
                                    </>
                                )}
                            </div>
                        </span>

                        {targetsOpen &&
                            policyTargets.map(
                                (t) =>
                                    t && (
                                        <span
                                            key={t.nodeId}
                                            onClick={() => setSelectedNavItem({ type: 'target', id: t.nodeId })}
                                            className={getItemStyle(t.nodeId == selectedNavItem?.id)}
                                            title={t.props.displayName}
                                        >
                                            <span className="inline-block truncate pr-4">{t.props.displayName}</span>
                                            <CheckCircleIcon
                                                width="18"
                                                height="18"
                                                className="text-green-300 mr-2 float-right"
                                            />
                                        </span>
                                    ),
                            )}

                        <span
                            className={getActionStyle(
                                selectedNavItem?.type == 'target' && selectedNavItem?.id == undefined,
                            )}
                            onClick={() => {
                                setSelectedNavItem({ type: 'target', id: undefined });
                            }}
                        >
                            <span className="flex align-center">
                                {policyTargets && policyTargets.length > 0
                                    ? 'Add Another +'
                                    : 'Add an Adaptive Trust Policy +'}
                            </span>
                        </span>
                    </div>

                    <div>
                        <span className={headerStyle}>
                            <span className="flex align-center">Trust Profile Mappings</span>
                        </span>
                        <span
                            onClick={() => setSelectedNavItem({ type: 'mapping' })}
                            className={getActionStyle(selectedNavItem?.type == 'mapping')}
                        >
                            <span className="flex align-center">Configure Actor Mapping</span>
                        </span>
                    </div>

                    <div>
                        <span className={headerStyle} onClick={() => setProfilesOpen(!profilesOpen)}>
                            <span className="flex align-center">Trust Profiles</span>
                            <div className="flex align-center space-x-2">
                                {loadingPolicyProfiles && <span className="h-4 w-4 loader mr-2" />}
                                {policyProfiles.length > 0 && (
                                    <>
                                        <span className="py-0 px-3 text-gray-300 bg-gray-700 text-xxs rounded-lg">
                                            {policyProfiles.length}
                                        </span>
                                        {profilesOpen ? (
                                            <ChevronDownIcon width="18" height="18" className="mr-2 float-right" />
                                        ) : (
                                            <ChevronRightIcon width="18" height="18" className="mr-2 float-right" />
                                        )}
                                    </>
                                )}
                            </div>
                        </span>

                        {profilesOpen &&
                            policyProfiles.map((profile) => (
                                <span
                                    key={profile.profileId}
                                    onClick={() => setSelectedNavItem({ type: 'profile', id: profile.profileId })}
                                    className={getItemStyle(profile.profileId == selectedNavItem?.id)}
                                    title={profile.displayName}
                                >
                                    <span className="inline-block truncate pr-4">{profile.displayName}</span>
                                </span>
                            ))}

                        <span
                            className={getActionStyle(
                                selectedNavItem?.type == 'profile' && selectedNavItem?.id == undefined,
                            )}
                            onClick={() => {
                                setSelectedNavItem({ type: 'profile', id: undefined });
                            }}
                        >
                            <span className="flex align-center">
                                {policyProfiles && policyDocuments.length > 0
                                    ? 'Add Another +'
                                    : 'Add a Policy Profile +'}
                            </span>
                        </span>
                    </div>

                    <div>
                        <span className={headerStyle} onClick={() => setDocumentsOpen(!documentsOpen)}>
                            <span className="flex align-center">Trust Factors</span>
                            <div className="flex align-center space-x-2">
                                {loadingPolicyDocuments && <span className="h-4 w-4 loader mr-2" />}
                                {policyDocuments.length > 0 && (
                                    <>
                                        <span className="py-0 px-3 text-gray-300 bg-gray-700 text-xxs rounded-lg">
                                            {policyDocuments.length}
                                        </span>
                                        {documentsOpen ? (
                                            <ChevronDownIcon width="18" height="18" className="mr-2 float-right" />
                                        ) : (
                                            <ChevronRightIcon width="18" height="18" className="mr-2 float-right" />
                                        )}
                                    </>
                                )}
                            </div>
                        </span>

                        {documentsOpen &&
                            policyDocuments.map((document) => (
                                <span
                                    key={document?.policyDocumentId}
                                    onClick={() =>
                                        setSelectedNavItem({ type: 'document', id: document?.policyDocumentId })
                                    }
                                    className={getItemStyle(document?.policyDocumentId == selectedNavItem?.id)}
                                    title={document?.displayName}
                                >
                                    <span className="inline-block truncate pr-4">{document?.displayName}</span>
                                </span>
                            ))}

                        <span
                            className={getActionStyle(
                                selectedNavItem?.type == 'document' && selectedNavItem?.id == undefined,
                            )}
                            onClick={() => {
                                setSelectedNavItem({ type: 'document', id: undefined });
                            }}
                        >
                            <span className="flex align-center">
                                {policyDocuments && policyDocuments.length > 0
                                    ? 'Add Another +'
                                    : 'Add a Trust Factor +'}
                            </span>
                        </span>
                    </div>
                </nav>
            </div>
        </div>
    );
};
